import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
import { assert } from '@/core'

export class BlockService extends BaseService {
  static get entity () {
    return ''
  }

  static async createBlockHour (data) {
    assert.object(data, { required: true })
    try {
      const response = await this.request({ auth: true }).post('block-hour/create', data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async getInfoBlockByDate (date, store_id) {
    try {
      const response = await this.request({ auth: true }).get('block-hour/show?date=' + date + '&store_id=' + store_id)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }
}
