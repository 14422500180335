import { isValidLimitQuery, isValidPageQuery } from '@/router/util'
import * as constants from '../config/constant'
/**
 * main fetch params validation
 */
export default {
  data: function () {
    return {
      loading: false,
      querySearch: {}
    }
  },
  computed: {
    queryParam () {
      return this.$route.query;
    }
  },
  watch: {
    queryParam () {
      if (this.queryParam && Object.keys(this.queryParam).length === 0) {
        this.getDataSearch(this.form);
      } else {
        this.getDataFromQuery();
      }
      this.fetchData();
    }
  },
  methods: {
    handleCurrentChange (val) {
      this.getDataSearch({ ...this.queryParam, ...{ page: val } });
      this.$router.push({
        name: this.$route.name,
        query: { ...this.queryParam, ...{ page: val } }
      });
    },
    prepareFetchParamsMixin (data) {
      const obj = {}
      Object.keys(data).forEach(key => {
        if (Array.isArray((data[key])) && data[key].length) {
          obj[key] = data[key]
        }
        if (typeof data[key] === 'boolean' && data[key]) {
          obj[key] = data[key]
        }
        if (typeof data[key] === 'string' && data[key].length) {
          obj[key] = data[key]
        }
        if ((typeof data[key] === 'number') && data[key]) {
          obj[key] = data[key]
        }

        if ((key === 'size') && !isValidLimitQuery(+data[key])) {
          delete obj[key]
        }
        if ((key === 'page') && !isValidPageQuery(+data[key])) {
          delete obj[key]
        }
      })

      return obj
    },
    getDataFromQuery () {
      Object.keys(this.queryParam).map((value) => {
        if (value === 'status' && !(this.queryParam[value] instanceof Array)) {
          if (this.queryParam[value].length > 0) {
            const data = this.queryParam[value]
              .split(',')
              .map((val) => parseInt(val));
            this.form[value] = data;
          }
        } else {
          this.form[value] = this.queryParam[value];
        }
      });
      this.getDataSearch(this.queryParam);
    },
    getDataSearch (form) {
      const querySearch = this.querySearch;
      Object.keys(form).map(function (value) {
        if (typeof form[value] === 'object' && form[value].join()) {
          querySearch[value] = form[value].join();
        } else { querySearch[value] = form[value] }
      })
      this.querySearch.size = constants.LIMIT_PAGINATE;
      this.querySearch.page = this.queryParam.page ? this.queryParam.page : 1;
    },
    onReset (form) {
      // Reset our form values
      Object.keys(form).map(function (value) {
        form[value] = value === 'status' ? [] : '';
      })
    },
    sortColumn ({ prop, order }) {
      let order_by = '';
      switch (order) {
        case 'ascending':
          order_by = 'asc'
          break;
        case 'descending':
          order_by = 'desc'
          break;
        default:
          order_by = '';
          break;
      }
      this.queryParam = { ...this.form, ...{ [prop]: order_by, page: 1 } };
    },
    search (routerName) {
      this.$router.push({
        name: routerName,
        query: { ...this.querySearch, page: 1 }
      });
    },
    handleSizeChange (val) {
      this.$router.push({
        name: this.$route.name,
        query: { ...this.queryParam, ...{ size: val, page: 1 } }
      });
    }
  }
}
