<template>
  <div v-loading.fullscreen.lock="loading">
    <el-dialog
      title=" "
      :visible.sync="onDialog"
      width="40%"
      :showCancelButton="false"
      :closeOnClickModal="false"
      :before-close="closeDialog"
    >
      <el-form
        label-width="140px"
        class="demo-ruleForm"
        :model="ruleForm"
        ref="ruleForm"
        label-position="left"
      >
        <el-form-item
          label="開始期間"
          prop="start_day"
          class="el-label-customer"
        >
          <el-date-picker
            style="width: 100%"
            disabled
            type="date"
            format="yyyy/MM/dd"
            value-format="yyyy/MM/dd"
            v-model="date"
            class="date"
          >
          </el-date-picker>
        </el-form-item>
        <el-row>
          <el-form-item
          label="予約時間"
          prop="start_day"
          class="el-label-customer"
        >
          <el-col :span="10">
            <el-form-item>
              <el-date-picker
                v-model="startTime"
                format="HH:mm"
                type="time"
                value-format="HH:mm"
                style="width: 100%"
                disabled
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4" style="text-align: center;">
            ~
          </el-col>
          <el-col :span="10">
            <el-form-item label-width="0px">
              <el-date-picker
                v-model="endTime"
                format="HH:mm"
                value-format="HH:mm"
                type="time"
                style="width: 100%"
                disabled
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          </el-form-item>
        </el-row>
        <el-form-item
          label="繰り返し"
        >
          <el-input
            disabled type="text" :value="loopType" ></el-input>
        </el-form-item>

        <el-form-item
          label="終了期間"
          prop="start_day"
          class="el-label-customer"
        >
          <el-date-picker
            v-model="endDate"
            style="width: 100%"
            disabled
            type="date"
            format="yyyy/MM/dd"
            value-format="yyyy/MM/dd"
            class="date"
          >
          </el-date-picker>
        </el-form-item>
        <span class="block-hour-label"
          >何の予約対象をキャンセルしたいですか</span
        >
        <el-form-item>
          <el-radio-group v-model="ruleForm.type_delete_loop">
            <el-radio v-for="item in constants.SET_LOOP" :key="item.value"
                      :label="item.value" >{{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <span class="block-hour-label">予約キャンセル通知送信</span>
        <el-form-item>
          <el-radio-group v-model="ruleForm.is_send_mail">
            <el-radio :label="1">送信する</el-radio>
            <el-radio :label="0">送信しない</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label-width="0px">
          <el-col :span="24" style="padding:0px 100px">
            <el-form-item class="el-ticket-label" >
              <el-button @click="closeDialog" class="ticket-canlce button-close" type="info"
                >閉じる</el-button
              >
              <el-button
                class="ticket-button"
                type="danger"
                @click="cancelBooking(idBooking)"
                >予約キャンセル</el-button
              >
            </el-form-item>
          </el-col>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { BookingService } from '@/services/booking.service';
import * as constants from '@/config/constant';

export default {
  name: 'CancelBookingLoop',
  props: {
    onDialog: {
      type: Boolean,
      default: false
    },
    date: {
      type: String,
      default: ''
    },
    startTime: {
      type: String,
      default: ''
    },
    endTime: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    },
    loopType: {
      type: String,
      default: ''
    },
    idBooking: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      constants: constants,
      ruleForm: {
        type_delete_loop: 1,
        is_send_mail: 0
      },
      loading: false
    };
  },
  methods: {
    closeDialog () {
      this.$emit('close-dialog')
    },
    async cancelBooking (id) {
      try {
        this.loading = true
        this.ruleForm.date = this.date
        await BookingService.cancelBooking(id, this.ruleForm)
        this.loading = false
        this.closeDialog();
        this.$emit('get-list')
        this.$notify.success({
          title: '成功',
          message: '削除しました。',
          showClose: false
        });
      } catch (e) {
        this.loading = false
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      }
    }
  }
};
</script>

<style scoped>
.block-hour-label {
  font-weight: bold;
}
.button-close{
    width:170px;
}
</style>
