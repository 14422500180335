<template>
  <div>
    <el-dialog class="booking-dialog"
               :visible.sync="dialog"
               title="決済情報付き"
               :before-close="handleCloseDialog"
               :close-on-click-modal="false"
    >
      <el-form ref="ruleForm" :model="ruleForm" label-width="170px" label-position="left" class="form-history-booking">
        <el-form-item label="担当者名" prop="admin_name">
          <el-input v-model="ruleForm.admin_name" placeholder="入力してください" maxlength="100"></el-input>
        </el-form-item>

        <el-form-item label="利用店舗" prop="store_id">
          <el-select v-model="ruleForm.store_id" placeholder="選択してください" style="width: 100%;">
            <el-option
              v-for="(value, index) in listStore" :key="index"
              :label="value.name"
              :value="value.id "
            ></el-option>
          </el-select>
        </el-form-item>

<!--        <el-form-item label="日付" prop="date">-->
<!--          <el-date-picker class="w-100 el-customer-date-picker"-->
<!--                          v-model="ruleForm.date"-->
<!--                          type="datetime"-->
<!--                          placeholder="選択してください"-->
<!--                          style="width: 100%;"-->
<!--                          prefix-icon="el-icon-date"-->
<!--                          format="yyyy/MM/dd HH:mm"-->
<!--                          clear-icon=" "-->
<!--                          :editable="false"-->
<!--                          value-format="yyyy-MM-dd HH:mm">-->
<!--            >-->
<!--          </el-date-picker>-->
<!--        </el-form-item>-->

        <el-form-item label="支払方法" prop="payment_method">
          <el-select @change="ruleForm.payment_other = ''" v-model="ruleForm.payment_method" placeholder="選択してください" style="width: 100%;">
            <el-option
              v-for="item in constants.PAYMENT_METHOD_SHOPPING"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="ruleForm.payment_method == constants.PAYMENT_METHOD_SHOPPING[4].value" label="その他" prop="payment_other">
          <el-input v-model="ruleForm.payment_other" placeholder="入力してください"
                    class="history-booking-input-text">
          </el-input>
        </el-form-item>

        <el-form-item label="物販情報" prop="parent" class="mb-3">
          <div class="table-responsive mb-0">
            <table class="table">
              <thead>
              <tr class="">
                <th class="text-combo text-center service-combo-name">物販名</th>
                <th class="text-combo text-center service-combo-name">料金</th>
                <th class="text-combo text-center service-combo-name">個数</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(value, index) in ruleForm.shopping_list" :key="index" class="pb-4">
                <td>
                  <div class="input-group justify-content-center">
                    <el-form-item :prop="'shopping_list.'+index+'.product_name'">
                      <el-input class="history-booking-input-text payment-info-price"
                                v-model="ruleForm.shopping_list[index].product_name" placeholder="入力してください">
                        <!--                      <i class="" slot="suffix">円</i>-->
                      </el-input>
                    </el-form-item>
                  </div>
                </td>
                <td>
                  <div class="input-group justify-content-center">
                    <el-form-item :prop="'shopping_list.'+index+'.product_price'">
                    <el-input class="history-booking-input-text payment-info-price" maxLength="10" @input.native="(e)=>{lengthInput(e, 10)}"
                              v-model="ruleForm.shopping_list[index].product_price" placeholder="入力してください" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                      <i class="" slot="suffix">円</i>
                    </el-input>
                    </el-form-item>
                  </div>
                </td>
                <td>
                  <div class="input-group justify-content-center">
                    <el-form-item :prop="'shopping_list.'+index+'.quantity'">
                    <el-input class="history-booking-input-text payment-info-price" maxLength="5" @input.native="(e)=>{lengthInput(e, 10)}"
                              v-model="ruleForm.shopping_list[index].quantity" placeholder="入力してください" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                    </el-input>
                    </el-form-item>
                  </div>
                </td>
                <td class="btn-delete"><label @click="deleteGroup(index)" class=" btn btn-danger btn-delete-combo">
                  X </label></td>
              </tr>
              </tbody>
            </table>
            <div>
              <a v-on:click="addGroup" class="float-right btn-add-combo">＋ 物販を追加する</a>
            </div>
          </div>
          <div class="text-danger" v-if="error.shopping_list">必須項目に入力してください。</div>
        </el-form-item>
        <el-form-item label="小計" prop="total_money">
          <el-input disabled oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    class="history-booking-input-text"
                    v-model="ruleForm.total_money" placeholder="入力してください">
            <i class="i-text-black" slot="suffix">円</i>
          </el-input>
        </el-form-item>

        <el-form-item label="備考" prop="comment">
          <el-input v-model="ruleForm.comment" class="history-booking-input-text">
          </el-input>
        </el-form-item>

        <el-form-item label="割引価" prop="discount">
          <el-input  v-model="ruleForm.discount" class="history-booking-input-text" maxLength="10" @input.native="(e)=>{lengthInput(e, 10)}"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');if(this.value>=100) { this.value = 100 }">
            <i class="i-text-black" slot="suffix">%</i>
          </el-input>
        </el-form-item>

        <el-form-item label="合計（税込）" prop="total_money_subtract_discount">
          <el-input v-model="ruleForm.total_money_subtract_discount" placeholder="入力してください" disabled class="history-booking-input-text">
            <i class="i-text-black" slot="suffix">円</i>
          </el-input>
        </el-form-item>

        <el-form-item label="税金" prop="ubtract_tax">
          <el-input class="history-booking-input-text" v-model="ruleForm.ubtract_tax" placeholder="入力してください"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); if(this.value>=100) { this.value = 100 }">
            <i class="i-text-black" slot="suffix">%</i>
          </el-input>
        </el-form-item>

        <el-form-item label="合計（税抜）" prop="total_money_after">
          <el-input v-model="ruleForm.total_money_after" placeholder="入力してください" disabled
                    class="history-booking-input-text">
            <i class="i-text-black" slot="suffix">円</i>
          </el-input>
        </el-form-item>

<!--        <hr>-->
<!--        <el-form-item label="おまけ（％）" prop="ratio">-->
<!--          <el-input placeholder="入力してください" class="history-booking-input-text" v-model="ruleForm.ratio"-->
<!--                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); if(this.value>=100) { this.value = 100 }">-->
<!--            <i class="i-text-black" slot="suffix">%</i>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="ポイント（自動計算）" class="plus_point m-0">-->
<!--          <el-input v-model="ruleForm.plus_point" disabled>-->
<!--            <i class="i-text-black" slot="suffix">pt</i>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label=" （ポイントプラス）" class="">-->
<!--        </el-form-item>-->

        <button-common v-on:cancleCreateOrCancelEdit="handleCloseDialog" v-on:submitCreateOrCancleEdit="confirmSubmit=true" type="create-edit" btnOk="決済" btnCancel="キャンセル">
        </button-common>

      </el-form>
    </el-dialog>
    <my-dialog class="customer-confirm-delete create_paymnet_poopup" v-on:closeDialog="confirmSubmit=false"
               v-on:submit="createShopping" v-bind:dialog="confirmSubmit"
               :closeOnClickModal="false" width="28%" btnOk="確認" :showCancelButton="true" btnCancel="キャンセル">
      <template slot="content">
        <h5 class="textwidget text-center title-header-customer">
          本当に決済よろしいでしょうか？
        </h5>
      </template>
    </my-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import * as constants from '@/config/constant';
import { StoreService } from '@/services/store.service';
import { ShoppingService } from '@/services/shopping.service';
export default {
  name: 'Create',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    idedit: {
      type: Number,
      default: 0
    }

  },
  data () {
    return {
      moment: moment,
      constants: constants,
      listStore: [],
      ruleForm: {
        user_id: this.$route.params.customerId,
        admin_name: '',
        payment_method: '',
        payment_other: '',
        total_money: 0,
        store_id: 1,
        ubtract_tax: 10,
        total_money_after: 0,
        comment: '',
        discount: 10,
        total_money_subtract_discount: 0,
        shopping_list: []
      },
      error: [],
      confirmSubmit: false
    }
  },
  created () {
    this.loading = true;
    this.getListStore()
    this.loading = false;
    if (this.idedit) {
      this.showDetailShopping(this.idedit)
    }
  },
  watch: {
    ruleForm: {
      handler: function (after, before) {
        let totalPrice = 0;
        if (this.ruleForm.shopping_list && this.ruleForm.shopping_list.length) {
          totalPrice += this.ruleForm.shopping_list.reduce((n, { product_price, quantity }) => n + (product_price ? parseInt(product_price * quantity) : 0), 0)
        }
        this.ruleForm.total_money = this.changeNumber(totalPrice)
        this.ruleForm.discount = this.changeNumber(this.ruleForm.discount)
        const total_money_subtract_discount = this.converPricetoInt(this.ruleForm.total_money) - this.converPricetoInt(this.ruleForm.discount ? (this.ruleForm.discount * totalPrice) / 100 : 0)
        if (total_money_subtract_discount < 0) {
          this.ruleForm.total_money_subtract_discount = 0
        } else {
          this.ruleForm.total_money_subtract_discount = this.changeNumber(total_money_subtract_discount)
        }
        const total_price = this.converPricetoInt(this.ruleForm.total_money_subtract_discount)
        let total_money_after = 0
        if (this.ruleForm.ubtract_tax) {
          total_money_after = this.changeNumber(Math.round(total_price - ((total_price * this.converPricetoInt(this.ruleForm.ubtract_tax)) / 100)))
        } else {
          total_money_after = total_price
        }
        this.ruleForm.total_money_after = this.changeNumber(total_money_after)
      },
      deep: true
    },
    'idedit' (newValue) {
      if (newValue) {
        this.showDetailShopping(this.idedit)
      }
    }

  },
  methods: {
    addGroup () {
      this.ruleForm.shopping_list.push({
        product_name: '',
        product_price: '',
        quantity: 1
      });
    },
    deleteGroup (index) {
      this.ruleForm.shopping_list.splice(index, 1)
    },
    async getListStore () {
      try {
        const { data } = await StoreService.getListStore()
        this.listStore = data.data;
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      }
    },
    handleCloseDialog () {
      this.error = []
      this.resetFormData()
      this.$refs.ruleForm.clearValidate(this.ruleForm)
      this.$emit('closeDialog')
    },
    async showDetailShopping (id) {
      try {
        this.loading = true;
        const { data } = await ShoppingService.showDetailShopping(id)
        if (data) {
          this.ruleForm = data
        }
      } catch (error) {
        this.$notify.error({
          title: 'Errors',
          message: error.message,
          showClose: false
        });
      } finally {
        this.loading = false;
      }
    },
    async createShopping () {
      try {
        this.loading = true;
        this.confirmSubmit = false
        this.$refs.ruleForm.clearValidate(this.ruleForm)
        const { data } = await ShoppingService.createorEditShopping(this.ruleForm, this.idedit)
        if (data) {
          this.handleCloseDialog();
          this.$emit('RegisterOrEditSucess')
          this.$notify.success({
            title: '成功',
            message: '更新しました。',
            showClose: false
          });
        }
      } catch (error) {
        if (error.code === 422) {
          this.showErrorMessage(error.data, 'ruleForm');
          this.error = error.data
        } else {
          this.$notify.error({
            title: 'Errors',
            message: error.message,
            showClose: false
          });
        }
      } finally {
        this.loading = false;
      }
    },
    resetFormData () {
      this.ruleForm.admin_name = ''
      this.ruleForm.payment_method = ''
      this.ruleForm.total_money = 0
      this.ruleForm.store_id = 1
      this.ruleForm.ubtract_tax = 10
      this.ruleForm.total_money_after = 0
      this.ruleForm.discount = 10
      this.ruleForm.shopping_list = []
      this.ruleForm.comment = ''
      this.ruleForm.total_money_subtract_discount = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.table > :not(caption) > * > * {
  padding: 0px 13px 25px 12px;
}
hr {
  border: none;
  border-top: 3px dashed grey;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 100%;
}

.text-primary-i {
  color: blue;
}

.text-combo {
  color: #00b0ff;
}

.btn-add-combo {
  font-weight: bold;
  color: blue !important;
  cursor: pointer;
}

.table-combo > :not(:first-child) {
  border-top: unset !important;
}

.table-combo > thead, tbody, tfoot, tr, td, th {
  border-style: unset;
}

.formControlSelect {
  line-height: 2;
}

.input-group-text {
  /*  background-color: white;
    border: none;
    border-left: 1px solid #ced4da ;*/
  padding: 3px;
  font-size: 14px;
}

.input-group > input {
  width: 87%;
}

.table-responsive {
  margin-top: -11px;
}

.service-combo-name {
  width: 30%;
}

.input-group > input {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.table-responsive > table > tbody {
  border: none;
}

.btn-delete {
  text-align: center !important;
}

.w-90 {
  width: 90%;
}

.status-payment {
  color: blue;
}

.status-booking-pending {
  color: rgb(245, 154, 36)
}

.status-booking-approvel {
  color: rgb(123, 188, 23)
}

.status-booking-reject {
  color: rgb(227, 71, 91)
}

.active {
  color: black !important;
  font-weight: 400 !important;
}

.block, .delete {
  color: red !important;
  font-weight: 400 !important;
}

/*.combo-service-price{
left: 52%;
}*/
</style>
