import Vue from 'vue';

import App from './layout/index.vue';
import router from './router';
import store from './store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './mixins';
import './plugins';
import './thirdParty';
import './plugins/element-ui';
import './plugins/commonLayout';
import './plugins/vue-cookies';
import './css/style.css';
import i18n from '@/plugins/vue-i18n';

import GlobalComponents from './globalComponents';
import GlobalDirectives from './globalDirectives';

// MaterialDashboard plugin
import MaterialDashboard from './material-dashboard';
import axios from 'axios';
import { API_URL, PREFIX_URL } from './.env';
import * as CONSTANT from './config/constant';
import CKEditor from 'ckeditor4-vue';

Vue.use(CKEditor);

Vue.config.productionTip = false;

Vue.use(GlobalComponents);
Vue.use(MaterialDashboard);
Vue.use(GlobalDirectives);

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  if (to.name === 'reset-password') {
    axios
      .post(
        API_URL + '/check-token',
        { token: to.query.token },
        { withCredentials: false }
      )
      .then((response) => {
        if (response.data.data.code === CONSTANT.STATUS_CODE.EXPIRED) {
          next(PREFIX_URL + '/not-found');
        } else if (response.data.data.code === CONSTANT.STATUS_CODE.NOT_FOUND) {
          next(PREFIX_URL + '/not-found');
        } else {
          next();
        }
      })
      .catch(() => {
      });
  } else {
    next();
  }
});

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

new Vue({
  name: 'Root',
  router,
  i18n,
  store,
  mounted () {
    store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth);
    window.addEventListener('resize', () =>
      store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth)
    );
  },

  beforeDestroy () {
    window.removeEventListener('resize', () =>
      store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth)
    );
  },
  render: (h) => h(App)
}).$mount('#app');
