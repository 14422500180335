import Vue from 'vue'
import Pagination from '@/components/pagination/Pagination.vue';
import ButtonAdd from '@/components/ButtonAdd.vue';
import ButtonSubmit from '@/components/ButtonSubmit.vue';
import Dialog from '@/components/Dialog/Dialog.vue';
import CreateCustomer from '@/pages/customer/Create.vue';
import CreateStaff from '@/pages/staff/Create.vue';
import ButtonCommon from '@/components/Button/ButtonCommon';

Vue.component('my-pagination', Pagination);
Vue.component('btn-add', ButtonAdd);
Vue.component('btn-submit', ButtonSubmit);
Vue.component('my-dialog', Dialog);
Vue.component('create-customer', CreateCustomer);
Vue.component('create-staff', CreateStaff);
Vue.component('button-common', ButtonCommon);
