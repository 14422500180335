import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class ShiftService extends BaseService {
  static get entity () {
    return ''
  }

  static async getSchedules (id) {
    try {
      const response = await this.request({ auth: true }).get('staff/schedule/list?user_id=' + id)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async createOneDaySchedule (data) {
    try {
      const response = await this.request({ auth: true }).post('staff/schedule/create-one-day-schedule', data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async editSchedule (data) {
    try {
      const response = await this.request({ auth: true }).post('staff/schedule/edit', data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async createSchedule (data) {
    try {
      const response = await this.request({ auth: true }).post('staff/schedule/edit', data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async deleteSchedule (id) {
    try {
      const response = await this.request({ auth: true }).delete('staff/schedule/delete/' + id)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getStoreSchedules (id) {
    try {
      const response = await this.request({ auth: true }).get('work-time/get-by-store?store_id=' + id)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
