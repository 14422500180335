/**
 * HTTP request layer
 * if auth is required return patched axios instance(with access token in headers)
 * else return clear axios instance
 */

import axios from 'axios'

import { AuthService } from '@/services/auth.service'
import { API_URL } from '../.env'
import { STATUS_CODE } from '@/config/constant'
import router from '@/router/index';
import store from '@/store/index';

export class Http {
  constructor (status) {
    this.isAuth = status && status.auth ? status.auth : false
    this.instance = axios.create({
      baseURL: API_URL
    })

    return this.init()
  }

  init () {
    if (this.isAuth) {
      this.instance.interceptors.request.use(request => {
        const bearToken = AuthService.getBearer()
        if (bearToken) {
          request.headers.authorization = bearToken;
        }
        return request
      }, error => {
        return Promise.reject(error)
      })
    }
    this.instance.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      if (response.data.code === STATUS_CODE.AUTHENTICATE) {
        localStorage.removeItem('accessToken')
        router.push({ name: 'login' })
      }

      return response;
    }, function (error) {
      if (error.response) {
        if (error.response.data.code === STATUS_CODE.AUTHENTICATE) {
          localStorage.removeItem('accessToken')
          store.commit('user/SET_CURRENT_USER', null)
          router.push({ name: 'login' })
        }
      } else {
        location.reload();
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    });
    return this.instance
  }
}
