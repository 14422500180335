<template>
  <div>
    <div class="card-toolbar py-3">
      <button-common  v-on:cancleConfirm="openDeleteDialog" v-on:submitConfirm="showPopupCreate" type="confirm" btnOk="新規チケットを作成" btnCancel="チケット削除">
      </button-common>
    </div>
    <div v-if="showErrDelete" class="mandatory">削除するアカウントを選択してください。</div>
    <div class="col-sm-12 mb-5">
      <el-table
        ref="multipleTable"
        :data="listMyTicket.data"
        :fit="true"
        :header-row-class-name="'background-table'"
        border
        width="100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column align="center" :resizable="false" label="チェックボックス" type="selection"  width="100px">
        </el-table-column>
        <el-table-column align="center" :resizable="false" prop="name" label="チケット名" width="150px">
        </el-table-column>
        <el-table-column align="center" :resizable="false" prop="" label="ステータス" >
          <template slot-scope="scope">
            <span
              v-if="scope.row.expire == constants.TICKIT_STATUS[0].value"
              style="color: blue"
              class="tickit-text-weight-bold"
            >{{  constants.TICKIT_STATUS[0].label }}</span
            >
            <span style="color: #909399"
              v-else-if="scope.row.expire == constants.TICKIT_STATUS[1].value"
              class="tickit-text-weight-bold"
            >{{ constants.TICKIT_STATUS[1].label }}</span
            >
            <span
              v-else
              class="text-danger tickit-text-weight-bold"
            >{{ constants.TICKIT_STATUS[2].label }}</span
            >
          </template>
        </el-table-column>
        <el-table-column align="center" :resizable="false" prop="" label="購入回数">
          <template slot-scope="scope">
             <span
               :class="scope.row.expire == constants.TICKIT_STATUS[0].value ? 'use-total-active' : ''"
             >{{  scope.row.use_total }}</span
             >
          </template>
        </el-table-column>
        <el-table-column align="center" :resizable="false" prop="use_count" label="利用回数"></el-table-column>
        <el-table-column align="center" :resizable="false"
          prop="remain_number"
          label="残り回数"
        ></el-table-column>
        <el-table-column align="center" :resizable="false" label="有効期限">
          <template slot-scope="scope">
            <span>{{ moment(scope.row.expire_date).format('YYYY/MM/DD') }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" :resizable="false" prop="" width="150px" >
          <template slot-scope="scope">
            <div style="display: flex;justify-content: center;">
            <el-button @click="showInfo(scope.row.id)" type="primary"
            >編集</el-button
            ></div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <my-dialog
      class="customer-confirm-delete"
      v-on:closeDialog="confirmDelete = false"
      v-on:submit="deleteTicket"
      v-bind:dialog="confirmDelete"
      :closeOnClickModal="false"
      width="28%"
      btnOk="確認"
      :showCancelButton="true"
      btnCancel="キャンセル"
    >
      <template slot="content">
        <h5 class="textwidget text-center title-header-customer">
          本当に削除しますか？
        </h5>
      </template>
    </my-dialog>
    <my-dialog
      class="customer-confirm-delete"
      v-on:closeDialog="closeDialog"
      v-on:submit="createTicket"
      v-bind:dialog="popupCreate"
      :closeOnClickModal="false"
      width="35%"
      btnOk="確認"
      :showCancelButton="false"
      :showOkButton="false"
      btnCancel="キャンセル"
    >
      <template slot="header">
        <div>
          <h3
            v-if="checkEdit"
            class="m-0 text-center tickit-text-weight-bold"
            style="color: #409eff"
          >
            チケット編集
          </h3>
          <h3
            v-else
            class="m-0 text-center tickit-text-weight-bold"
            style="color: #409eff"
          >
            新規チケットを作成
          </h3>
        </div>
      </template>
      <template slot="content">
        <h5 class="textwidget text-center title-header-customer">
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            label-width="150px"
            class="demo-form mb-5 tickit-text-name"
            label-position="left"
          >
            <el-form-item prop="parent" label-width="0px" class="">
              <el-col :md="20">
                <el-form-item
                  class="el-label-customer el-ticket-label start-required"
                  label="チケット名"
                  prop="name"
                >
                  <el-input maxlength="200"
                    class=""
                    v-model="ruleForm.name"
                    placeholder="入力してください"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item prop="parent" label-width="0px" class="">
              <el-col :md="20">
                <el-form-item
                  class="el-label-customer el-ticket-label el-ticket-numberstart-required"
                  label="購入回数"
                  prop="use_total">
                  <el-input
                    :maxlength="20"
                    v-model="ruleForm.use_total"
                    controls-position="right"
                    placeholder="入力してください"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item prop="parent" label-width="0px" class="">
              <el-col :md="20">
                <el-form-item
                  class="
                    el-label-customer el-ticket-label el-ticket-number
                    start-required
                  "
                  label="利用回数"
                  prop="use_count"
                >
                  <el-input
                    :maxlength="20"
                    v-model="ruleForm.use_count"
                    controls-position="right"
                    placeholder="入力してください"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item prop="parent" label-width="0px" class="">
              <el-col :md="20">
                <el-form-item
                  class="el-label-customer el-ticket-label start-required"
                  label="有効期限"
                  prop="expire_date"
                >
                  <el-date-picker class="el-customer-date-picker"
                    style="width: 100%"
                    v-model="ruleForm.expire_date"
                    type="date"
                    format="yyyy-MM-dd"
                    clear-icon=" "
                    placeholder="入力してください"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-form-item>

            <button-common v-if="checkEdit" :checkMultiClick="disabledButtom" v-on:cancleCreateOrCancelEdit="closeDialog" v-on:submitCreateOrCancleEdit="updateTicket" type="create-edit" btnOk="保存" btnCancel="キャンセル">
            </button-common>

            <button-common v-else :checkMultiClick="disabledButtom" v-on:cancleCreateOrCancelEdit="closeDialog" v-on:submitCreateOrCancleEdit="createTicket" type="create-edit" btnOk="作成" btnCancel="キャンセル">
            </button-common>

          </el-form>
        </h5>
      </template>
    </my-dialog>
    <div v-if="listMyTicket.count">
      <my-pagination
        :total-record="parseInt(listMyTicket.count)"
        :current-page="parseInt(querySearch.page)"
        @handleCurrentChange="handleCurrentChange"
        align="right"
      />
    </div>
  </div>
</template>

<script>
import { TicketService } from '@/services/ticket.service'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'
import turnOffErrorMessage from '@/mixins/jumpTo'
import * as constants from '@/config/constant'
import moment from 'moment';
export default {
  name: 'List',
  props: {
    activeFourth: {
      type: Boolean,
      default: false
    }
  },
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin, turnOffErrorMessage],
  data () {
    return {
      ruleForm: {
        use_total: '',
        use_count: '',
        name: '',
        expire_date: '',
        user_id: ''
      },
      form: {
        size: constants.LIMIT_PAGINATE,
        page: 1
      },
      idUpdate: '',
      checkEdit: false,
      listMyTicket: [],
      checkedDelete: [],
      constants: constants,
      confirmDelete: false,
      showPopup: false,
      popupCreate: false,
      disabledButtom: false,
      moment: moment,
      showErrDelete: false
    }
  },
  watch: {
  },
  created () {
    this.getDataFromQuery();
    this.fetchData();
  },
  methods: {
    handleSelectionChange (val) {
      this.showErrDelete = false
      this.checkedDelete = val
    },
    async fetchData () {
      this.loading = true
      try {
        const { data } = await TicketService.getListMyTicket(
          this.prepareQueryParamsMixin(this.querySearch), this.$route.params.customerId
        );
        this.listMyTicket = data;
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        })
      } finally {
        this.loading = false
      }
    },
    async deleteTicket () {
      try {
        this.loading = true
        this.confirmDelete = false
        if (this.checkedDelete.length) {
          const vm = this
          const listId = []
          Object.keys(this.checkedDelete).forEach(function (key) {
            listId.push(vm.checkedDelete[key].id)
          })
          await TicketService.removeTicket(listId)
          this.$router.push({ name: 'customer-detail', params: { customerId: this.$route.params.customerId }, query: { tab: 'ticket', page: 1 } })
          this.$notify.success({
            title: '成功',
            message: '削除しました。',
            showClose: false
          })
        }
        this.loading = false
      } catch (e) {
        this.fetchData()
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        })
      } finally {
        this.loading = false
      }
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    async createTicket () {
      try {
        this.disabledButtom = true
        this.turnOffErrorMessage('ruleForm');
        this.loading = true
        this.ruleForm.user_id = this.$route.params.customerId
        if (this.ruleForm.expire_date) {
          this.ruleForm.expire_date = moment(this.ruleForm.expire_date).format('YYYY-MM-DD')
        }
        const { data } = await TicketService.createTicket({ ...this.ruleForm })
        if (data) {
          this.loading = false
          this.fetchData()
          this.resetForm('ruleForm')
          this.popupCreate = false
          this.$notify.success({
            title: '成功',
            message: '更新しました。',
            showClose: false
          })
        }
        this.loading = false
      } catch (error) {
        this.loading = false;
        if (error.code === 422) {
          if (error.data.user_id) {
            alert(error.data.user_id[0])
          }
          this.showErrorMessage(error.data, 'ruleForm')
        } else {
          this.$notify.error({
            title: 'Error',
            message: error.message,
            showClose: false
          })
        }
      } finally {
        this.disabledButtom = false
      }
    },
    async showInfo (id) {
      try {
        this.checkEdit = true
        this.idUpdate = id
        const { data } = await TicketService.getTicketById(id)
        this.ruleForm = {
          use_total: data.use_total,
          use_count: data.use_count,
          name: data.name,
          expire_date: data.expire_date,
          user_id: data.user_id
        }
        this.popupCreate = true
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        })
      } finally {
      }
    },
    closeDialog () {
      this.popupCreate = false
      this.idUpdate = ''
      this.ruleForm = {
        use_total: '',
        use_count: '',
        name: '',
        expire_date: '',
        user_id: ''
      }
    },
    async updateTicket () {
      try {
        this.disabledButtom = true
        this.turnOffErrorMessage('ruleForm');
        this.loading = true
        if (this.ruleForm.expire_date) {
          this.ruleForm.expire_date = moment(this.ruleForm.expire_date).format('YYYY-MM-DD')
        }
        const { data } = await TicketService.updateTicket(this.idUpdate, {
          ...this.ruleForm
        })
        if (data) {
          this.checkEdit = false
          this.loading = false
          this.idUpdate = ''
          this.ruleForm = {
            use_total: '',
            use_count: '',
            name: '',
            expire_date: '',
            user_id: ''
          }
          this.fetchData()
          this.popupCreate = false
          this.$notify.success({
            title: '成功',
            message: '更新しました。',
            showClose: false
          })
        }
        this.loading = false
      } catch (error) {
        this.loading = false;
        if (error.code === 422) {
          this.showErrorMessage(error.data, 'ruleForm')
        } else {
          this.$notify.error({
            title: 'Error',
            message: error.message,
            showClose: false
          })
        }
      } finally {
        this.disabledButtom = false
      }
    },
    showPopupCreate () {
      this.checkEdit = false
      this.popupCreate = true
      this.$refs.ruleForm.clearValidate(this.ruleForm)
    },
    openDeleteDialog () {
      if (this.checkedDelete.length === 0) {
        this.showErrDelete = true
        var self = this;
        setTimeout(function () {
          self.showErrDelete = false;
        }, 2000);
        return true
      } else {
        this.confirmDelete = true
      }
    }
  }
}
</script>

<style scoped>
.tickit-text-weight-bold {
  margin-top: -100px;
  font-weight: bold;
  color: white;
}
.tickit-text-name {
  margin-left: 30px;
}
::v-deep .el-dialog__header {
  padding: 6px;
}
.use-total-active{
  color: #00FF00;
}
</style>
