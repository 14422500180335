<template>
  <div>
    <el-card class="box-card ticket">
      <div slot="header" class="clearfix">
        <h3 class="title-header-customer">顧客の詳細情報</h3>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="プロフィール" name="first">プロフィール</el-tab-pane>
        <el-tab-pane label="マイチケット" name="second">マイチケット</el-tab-pane>
        <el-tab-pane label="クーポン" name="third">クーポン</el-tab-pane>
        <el-tab-pane label="予約履歴" name="fourth">
          <List :activeFourth="activeFourth"></List>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
import List from './List';
export default {
  components: {
    List
  },
  data () {
    return {
      activeName: 'first',
      activeFourth: false
    };
  },
  created () {
    if (this.$route.query.active) {
      this.activeName = this.$route.query.active
    }
  },
  methods: {
    handleClick (tab, event) {
      if (tab.name === 'fourth') {
        this.activeFourth = true
      } else {
        this.activeFourth = false
      }
    }
  }
};
</script>
