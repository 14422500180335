/**
 * loop over all component props that uses in url
 * return only valid props, then you get only valid params in fetchParams(component computed prop)
 */
export default {
  methods: {
    prepareQueryParamsMixin (queryPropList) {
      const str = [];
      for (const p in queryPropList) {
        // eslint-disable-next-line no-prototype-builtins
        if (queryPropList.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(queryPropList[p]));
        }
      }
      return str.join('&');
    }
  }
}
