<template>
  <div  v-loading="loading">
    <el-table class="booking-history-table"
      ref="table"
      :data="list_historyBookings.data"
      :header-row-class-name="'background-table'"
      fit
      border
      :row-class-name="tableRowClassName"
      :row-key="getRowKey"
    >
      <el-table-column label="No" width="100" :resizable="false">
        <template slot-scope="scope">
          <span>{{replaceCode(scope.row.code)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="単体・コース名" :resizable="false">
        <template slot-scope="scope">
          <span v-for="service in scope.row.services" :key="scope.row.services.indexOf(service)">
            {{service.name}}
            <br>
          </span>
          <span v-for="combo in scope.row.combos" :key="scope.row.combos.indexOf(combo)">
            {{combo.name}}
             <br>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="利用場所" :resizable="false">
        <template slot-scope="scope">
          <span>{{scope.row.store}}</span>
        </template>
      </el-table-column>
      <el-table-column label="予約日" :resizable="false">
        <template slot-scope="scope">
          <span>{{ moment(scope.row.created_at).format('YYYY/MM/DD HH:MM') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ステータス" :resizable="false">
        <template slot-scope="scope">
          <span class="status-payment" v-if="scope.row.payment">支払い済み</span>
          <span class="status-booking-pending" v-else-if="scope.row.status === constants.BOOKING_STATUS.Pending">承認待ち</span>
          <span class="status-booking-approvel" v-else-if="scope.row.status === constants.BOOKING_STATUS.Approved">承認済み</span>
          <span class="status-booking-reject" v-else>キャンセル</span>
        </template>
      </el-table-column>
      <el-table-column label="施術時間" :resizable="false">
        <template slot-scope="scope">
          <span>{{scope.row.use_time}}</span>
        </template>
      </el-table-column>
      <el-table-column label="顧客のコメント" :resizable="false">
        <template slot-scope="scope">
          <pre style="text-align: left;">{{scope.row.customer_comment}}</pre>
        </template>
      </el-table-column>
      <el-table-column label="店長のコメント1" :resizable="false">
        <template slot-scope="scope">
          <span>{{scope.row.admin_comment}}</span>
        </template>
      </el-table-column>
      <el-table-column :resizable="false">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status === constants.BOOKING_STATUS.Approved" type="primary" @click="createEditPaymentInfoPopUp(scope.row)">決済設定</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="list_historyBookings.data" style="margin-top: 20px; text-align: right">
      <my-pagination
        :total-record="parseInt(list_historyBookings.count)"
        :current-page="parseInt(querySearch.page)"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
    <el-dialog class="booking-dialog"
      :visible.sync="showModal"
      :before-close="handleCloseDialog"
      title="決済情報付き"
      :close-on-click-modal="false"
    >
      <el-form ref="ruleForm" :model="ruleForm" label-width="170px" label-position="left" class="form-history-booking">
        <el-form-item label="担当者名" prop="responsible_name">
          <el-input v-model="ruleForm.responsible_name" placeholder="入力してください" maxlength="100"></el-input>
        </el-form-item>

        <el-form-item label="利用店舗" prop="store_id">
          <el-select @change="changeShop" v-model="ruleForm.store_id" placeholder="選択してください" style="width: 100%;">
            <el-option
              v-for="(value, index) in listStore" :key="index"
              :label="value.name"
              :value="value.id "
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="日付" prop="date">
          <el-date-picker class="w-100 el-customer-date-picker"
            v-model="ruleForm.date"
            type="datetime"
            placeholder="選択してください"
            style="width: 100%;"
            prefix-icon="el-icon-date"
            format="yyyy/MM/dd HH:mm"
            clear-icon=" "
            :editable="false"
            value-format="yyyy-MM-dd HH:mm">
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="支払方法" prop="type">
          <el-select @change="ruleForm.payment_other = ''" v-model="ruleForm.type" placeholder="選択してください" style="width: 100%;">
            <el-option
              v-for="item in constants.PAYMENT_METHOD_SHOPPING"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="ruleForm.type == constants.PAYMENT_METHOD_SHOPPING[4].value" label="その他" prop="payment_other">
          <el-input v-model="ruleForm.payment_other" placeholder="入力してください"
                    class="history-booking-input-text">
          </el-input>
        </el-form-item>

        <el-form-item label="コース情報" prop="parent">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr class="">
                <th class="text-combo text-center service-combo-name">コース名</th>
                <th class="text-combo text-center service-combo-name">施術時間</th>
                <th class="text-combo text-center service-combo-name">料金</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(value, index) in ruleForm.comboListUse" :key="index" >
                <td>
                  <el-form-item :prop="'comboListUse.'+index+'.combo_id'">
                    <el-select :class="value.comboClass ? value.comboClass : ''"  @change="moveTime(value)" class="w--10 bg-gray-item common-selectted-payment" v-model="ruleForm.comboListUse[index].combo_id" placeholder="入力してください">
                      <el-option :class="item.deleted_at ? 'delete' : ( item.block ==1 ? 'block' : 'active')" v-for="(item, index1) in comboList" :key="index1" :label="item.deleted_at ? item.name + ' (削除済)' : ( item.block ==1 ? item.name+' (ブロック)' : item.name)" :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                </td>
                <td>
                  <div class="d-flex">
                    <el-form-item :prop="'comboListUse.'+index+'.process_id'">
                    <el-select :class="value.processClass ? value.processClass : ''" :prop="'comboListUse.'+index+'.process_id'" @change="showPrice(value)" class="common-selectted-payment bg-gray-item payment-select-minutes" v-model="ruleForm.comboListUse[index].process_id" placeholder="入力してください">
                      <el-option :class="item.deleted_at ? 'delete': 'active'" v-for="(item, index1) in ruleForm.comboListUse[index].comboProcess" :key="index1" :label="item.deleted_at ? item.use_time+' (削除済)':item.use_time" :value="item.id"></el-option>
                    </el-select>
                    </el-form-item>
                    <div class="payment-select-minutes-icon">分</div>
                  </div>
                </td>
                <td>
                  <div class="input-group justify-content-center">
                      <el-input disabled class="history-booking-input-text payment-info-price"
                                v-model="ruleForm.comboListUse[index].price" placeholder="入力してください">
                        <i class="" slot="suffix">円</i>
                      </el-input>
                  </div>
                </td>
                <td class="btn-delete"><label  @click="deleteGroup(index)" class=" btn btn-danger btn-delete-combo"> X </label></td>
              </tr>
              </tbody>
            </table>
            <div>
              <a v-on:click="addGroup(false)" class="float-right btn-add-combo">＋ コースを追加する</a>
            </div>
          </div>
        <div class="text-danger" v-if="error.comboListUse">必須項目に入力してください。</div>
        </el-form-item>
        <el-form-item label="サービス情報" prop="parent">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr class="">
                <th class="text-combo text-center service-combo-name">サービス名</th>
                <th class="text-combo text-center service-combo-name">施術時間</th>
                <th class="text-combo text-center service-combo-name">料金</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(value, index) in ruleForm.serviceListUse" :key="index" >
                <td>
                  <el-form-item :prop="'serviceListUse.'+index+'.service_id'">
                    <el-select :class="value.serviceClass ? value.serviceClass : ''" @change="moveTime(value,true)" class="w--10 bg-gray-item" v-model="ruleForm.serviceListUse[index].service_id" placeholder="入力してください">
                      <el-option :class="item.deleted_at ? 'delete' : ( item.block ==1 ? 'block' : 'active')" v-for="(item, index1) in serviceList" :key="index1" :label="item.deleted_at ? item.name + ' (削除済)' : ( item.block ==1 ? item.name+' (ブロック)' : item.name)" :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                </td>
                <td>
                  <div class="d-flex">
                    <el-form-item :prop="'serviceListUse.'+index+'.process_id'">
                      <el-select :class="value.processClass ? value.processClass : ''" @change="showPrice(value,true)" class="bg-gray-item payment-select-minutes" v-model="ruleForm.serviceListUse[index].process_id" placeholder="入力してください">
                        <el-option :class="item.deleted_at ? 'delete': 'active'" v-for="(item, index1) in ruleForm.serviceListUse[index].serviceProcess" :key="index1" :label="item.deleted_at ? item.use_time+' (削除済)':item.use_time" :value="item.id"></el-option>
                      </el-select>
                    </el-form-item>
                    <div class="payment-select-minutes-icon">分</div>
                  </div>
                </td>
                <td>
                  <div class="input-group justify-content-center">
                    <el-input disabled class="history-booking-input-text payment-info-price"
                              v-model="ruleForm.serviceListUse[index].price" placeholder="入力してください">
                      <i class="" slot="suffix">円</i>
                    </el-input>
                  </div>
                </td>
                <td class="btn-delete"><label  @click="deleteGroup(index,true)" class=" btn btn-danger btn-delete-combo"> X </label></td>
              </tr>
              </tbody>
            </table>
            <div>
              <a v-on:click="addGroup(true)" class="float-right btn-add-combo">＋ サービスを追加する</a>
            </div>
          </div>
          <div class="text-danger" v-if="error.serviceListUse">必須項目に入力してください。</div>
        </el-form-item>
        <el-form-item label="小計" prop="total_money">
          <el-input disabled oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="history-booking-input-text"
            v-model="ruleForm.total_money" placeholder="入力してください">
            <i class="i-text-black" slot="suffix">円</i>
          </el-input>
        </el-form-item>

        <el-form-item label="備考" prop="comment">
          <el-input v-model="ruleForm.comment" class="history-booking-input-text">
          </el-input>
        </el-form-item>

        <el-form-item label="割引価" prop="discount">
          <el-input  v-model="ruleForm.discount" class="history-booking-input-text" maxLength="10" @input.native="(e)=>{lengthInput(e, 10)}"
                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');if(this.value>=100) { this.value = 100 }">
            <i class="i-text-black" slot="suffix">%</i>
          </el-input>
        </el-form-item>

        <el-form-item label="合計（税込）" prop="total_money_subtract_discount">
          <el-input v-model="ruleForm.total_money_subtract_discount" placeholder="入力してください" disabled class="history-booking-input-text">
            <i class="i-text-black" slot="suffix">円</i>
          </el-input>
        </el-form-item>

        <el-form-item label="税金" prop="ubtract_tax">
          <el-input class="history-booking-input-text" v-model="ruleForm.ubtract_tax" placeholder="入力してください"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); if(this.value>=100) { this.value = 100 }">
            <i class="i-text-black" slot="suffix">%</i>
          </el-input>
        </el-form-item>

        <el-form-item label="合計（税抜）" prop="total_money_after">
          <el-input v-model="ruleForm.total_money_after" placeholder="入力してください" disabled
                    class="history-booking-input-text">
            <i class="i-text-black" slot="suffix">円</i>
          </el-input>
        </el-form-item>

        <button-common v-on:cancleCreateOrCancelEdit="handleCloseDialog" v-on:submitCreateOrCancleEdit="confirmSubmit=true" type="create-edit" btnOk="保存" btnCancel="キャンセル">
        </button-common>

      </el-form>
    </el-dialog>
    <my-dialog class="customer-confirm-delete create_paymnet_poopup" v-on:closeDialog="confirmSubmit=false" v-on:submit="createPaymentInfo" v-bind:dialog="confirmSubmit"
               :closeOnClickModal="false" width="28%" btnOk="確認" :showCancelButton="true" btnCancel="キャンセル">
      <template slot="content">
        <h5 class="textwidget text-center title-header-customer">
          本気ですか？
        </h5>
      </template>
    </my-dialog>
  </div>
</template>

<script>
import { HistoryBookingService } from '@/services/history-booking.service'
import { PaymentService } from '@/services/payment.service'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin';
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin';
import * as constants from '@/config/constant';
import moment from 'moment';
import { BookingService } from '../../services/booking.service';
import { StoreService } from '@/services/store.service';

export default {
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  data () {
    return {
      moment: moment,
      listStore: [],
      constants: constants,
      list_historyBookings: [],
      comboServiceList: [],
      comboList: [],
      serviceList: [],
      page_size: 10,
      page: 1,
      showModal: false,
      is_create: true,
      loading: false,
      form: {
        size: 20,
        page: 1
      },
      ruleForm: {
        responsible_name: '',
        date: '',
        type: '',
        payment_other: '',
        total_money: 0,
        use_point: 0,
        booking_id: '',
        payment_id: '',
        store_id: '',
        ubtract_tax: 10,
        total_money_after: 0,
        comment: '',
        discount: 10,
        comboListUse: [],
        serviceListUse: []
      },
      allServiceCombo: [],
      error: [],
      confirmSubmit: false
    }
  },
  watch: {
    ruleForm: {
      handler: function (after, before) {
        let totalPrice = 0;
        if (this.ruleForm.comboListUse && this.ruleForm.comboListUse.length) {
          totalPrice += this.ruleForm.comboListUse.reduce((n, { price }) => n + price, 0)
        }
        if (this.ruleForm.serviceListUse && this.ruleForm.serviceListUse.length) {
          totalPrice += this.ruleForm.serviceListUse.reduce((n, { price }) => n + price, 0)
        }
        this.ruleForm.total_money = this.changeNumber(totalPrice)
        this.ruleForm.discount = this.changeNumber(this.ruleForm.discount)
        const total_money_subtract_discount = this.converPricetoInt(this.ruleForm.total_money) - this.converPricetoInt(this.ruleForm.discount ? (this.ruleForm.discount * totalPrice) / 100 : 0)
        if (total_money_subtract_discount < 0) {
          this.ruleForm.total_money_subtract_discount = 0
        } else {
          this.ruleForm.total_money_subtract_discount = this.changeNumber(total_money_subtract_discount)
        }
        const total_price = this.converPricetoInt(this.ruleForm.total_money_subtract_discount)
        let total_money_after = 0
        if (this.ruleForm.ubtract_tax) {
          total_money_after = this.changeNumber(Math.round(total_price - ((total_price * this.converPricetoInt(this.ruleForm.ubtract_tax)) / 100)))
        } else {
          total_money_after = total_price
        }
        this.ruleForm.total_money_after = this.changeNumber(total_money_after)
      },
      deep: true
    }

  },
  created () {
    this.loading = true;
    this.getDataFromQuery();
    this.fetchData()
    this.getListStore()
    this.loading = false;
  },
  methods: {
    replaceCode (str) {
      return str.replace('-', '');
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.status === 1) {
        return 'read-row'
      }
      return ''
    },
    getRowKey (row) {
      return row.id
    },
    setPage (val) {
      this.page = val
    },
    async fetchData () {
      this.loading = true;
      try {
        const { data } = await HistoryBookingService.getListHistoryBooking(
          this.$route.params.customerId, this.prepareQueryParamsMixin(this.querySearch)
        )
        this.list_historyBookings = data
        // sort list theo thu tu pending, approved, cancel
        this.list_historyBookings.data.sort((a, b) => {
          if (a.status === b.status) {
            return 0
          }
          if (a.status === constants.BOOKING_STATUS.Pending) {
            if (b.status === constants.BOOKING_STATUS.Approved || b.status === constants.BOOKING_STATUS.Cancel) {
              return -1
            }
          }
          if (a.status === constants.BOOKING_STATUS.Approved) {
            if (b.status === constants.BOOKING_STATUS.Pending) {
              return 1
            }
            if (b.status === constants.BOOKING_STATUS.Cancel) {
              return -1
            }
          }
          if (a.status === constants.BOOKING_STATUS.Cancel) {
            return 1
          }
        })
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      } finally {
        this.loading = false;
      }
    },
    handleCloseDialog () {
      this.error = []
      this.$refs.ruleForm.clearValidate(this.ruleForm)
      this.resetForm('ruleForm')
      this.is_create = true
      this.showModal = false
      this.ruleForm = {
        responsible_name: '',
        date: '',
        type: '',
        total_money: 0,
        booking_id: '',
        payment_id: '',
        store_id: '',
        ubtract_tax: 10,
        total_money_after: 0,
        total_money_subtract_discount: 0,
        payment_other: '',
        comment: '',
        discount: 10,
        comboListUse: [],
        serviceListUse: []

      }
    },
    createEditPaymentInfoPopUp (data) {
      this.loading = true;
      this.showModal = true
      this.ruleForm.store_id = data.store_id
      this.showModal = true
      this.getServiceAndServiceBooking(data.id)
      this.ruleForm.booking_id = data.id
    },
    async createPaymentInfo () {
      try {
        this.confirmSubmit = false
        this.loading = true;
        this.$refs.ruleForm.clearValidate(this.ruleForm)
        const valid = await this.$refs.ruleForm.validate();
        if (!valid) {
          return;
        }
        if (this.ruleForm.date) {
          this.ruleForm.date = moment(this.ruleForm.date).format('YYYY-MM-DD HH:MM')
        }
        const { data } = await PaymentService.createOrEditPaymentInfo({ ...this.ruleForm }, this.is_create)
        if (data) {
          this.fetchData()
          this.error = []
          this.showModal = false
          this.is_create = true
          this.resetForm('ruleForm')
          this.$notify.success({
            title: '成功',
            message: '更新しました。',
            showClose: false
          });
        }
      } catch (error) {
        if (error.code === 422) {
          this.showErrorMessage(error.data, 'ruleForm');
          this.error = error.data
        } else {
          this.$notify.error({
            title: 'Errors',
            message: error.message,
            showClose: false
          });
        }
      } finally {
        this.loading = false;
      }
    },
    async showPaymentInfo (booking_id) {
      try {
        const { data } = await PaymentService.showPaymentInfo(booking_id)
        if (data) {
          this.is_create = false
          this.ruleForm.responsible_name = data.responsible_name
          this.ruleForm.date = data.date
          this.ruleForm.type = data.type
          this.ruleForm.use_point = this.changeNumber(data.use_point)
          this.ruleForm.comment = data.comment
          this.ruleForm.discount = this.changeNumber(data.discount)
          this.ruleForm.booking_id = booking_id
          this.ruleForm.payment_id = data.id
          this.ruleForm.ubtract_tax = data.ubtract_tax
          this.ruleForm.store_id = data.store_id
          this.ruleForm.total_money_subtract_discount = data.total_money_subtract_discount
          this.ruleForm.payment_other = data.payment_other
          this.ruleForm.total_money_after = data.total_money_after
          this.serviceList = this.fillterServiceCombo(this.allServiceCombo, true)
          this.comboList = this.fillterServiceCombo(this.allServiceCombo, false)
        }
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      } finally {
        this.loading = false;
      }
    },
    async getServiceAndServiceBooking (id) {
      try {
        const { data } = await BookingService.processComboService(id)
        this.comboServiceList = data.list
        this.serviceList = this.fillterServiceCombo(data, true)
        this.comboList = this.fillterServiceCombo(data, false)
        this.allServiceCombo = data;
        this.ruleForm.comboListUse = data.acombo ? data.acombo : []
        this.ruleForm.serviceListUse = data.aservice ? data.aservice : []
      } catch (error) {
        this.$notify.error({
          title: 'Errors',
          message: error.message,
          showClose: false
        });
      } finally {
        this.showPaymentInfo(id)
      }
    },
    addGroup (flag = false) {
      if (flag) {
        this.ruleForm.serviceListUse.push({
          service_id: '',
          process_id: '',
          price: ''
        });
      } else {
        this.ruleForm.comboListUse.push({
          combo_id: '',
          process_id: '',
          price: ''
        });
      }
    },
    deleteGroup (index, flag = false) {
      if (flag) {
        this.ruleForm.serviceListUse.splice(index, 1)
      } else {
        this.ruleForm.comboListUse.splice(index, 1)
      }
    },
    async getListStore () {
      try {
        const { data } = await StoreService.getListStore()
        this.listStore = data.data;
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      }
    },
    moveTime (data, flag = false) {
      data.process_id = ''
      data.price = ''
      if (flag) {
        data.serviceProcess = this.serviceList.find(x => x.id === data.service_id).process_with_trashed
        const service = this.serviceList.find((el) => el.id === data.service_id)
        if (service && (service.block === 1 || service.deleted_at)) {
          data.serviceClass = 'block_or_delete_ad'
        } else data.serviceClass = ''
      } else {
        data.comboProcess = this.comboList.find(x => x.id === data.combo_id).process_with_trashed
        const combo = this.comboList.find((el) => el.id === data.combo_id)
        if (combo && (combo.block === 1 || combo.deleted_at)) {
          data.comboClass = 'block_or_delete_ad'
        } else data.comboClass = ''
      }
    },
    showPrice (data, flag = false) {
      if (flag) {
        const serviceProcess = data.serviceProcess.find(x => x.id === data.process_id)
        data.price = serviceProcess.price
        if (serviceProcess && serviceProcess.deleted_at) {
          data.processClass = 'block_or_delete_ad'
        } else data.processClass = ''
      } else {
        const comboProcess = data.comboProcess.find(x => x.id === data.process_id)
        data.price = comboProcess.price
        if (comboProcess && comboProcess.deleted_at) {
          data.processClass = 'block_or_delete_ad'
        } else data.processClass = ''
      }
    },
    fillterServiceCombo (data, flag = false) {
      if (flag) {
        return data.service.filter((ele) => {
          if (ele.store_id === this.ruleForm.store_id || ele.store_id === 0) {
            return ele;
          }
        })
      } else {
        return data.combo.filter((ele) => {
          if (ele.store_id === this.ruleForm.store_id || ele.store_id === 0) {
            return ele;
          }
        })
      }
    },
    changeShop () {
      this.ruleForm.comboListUse = []
      this.ruleForm.serviceListUse = []
      this.serviceList = this.fillterServiceCombo(this.allServiceCombo, true)
      this.comboList = this.fillterServiceCombo(this.allServiceCombo, false)
    }
  }
}
</script>

<style lang="scss" scoped>
hr {
  border:none;
  border-top:3px dashed grey;
  color:#fff;
  background-color:#fff;
  height:1px;
  width:100%;
}
.text-primary-i{
  color: blue;
}
.text-combo{
  color: #00b0ff;
}
.btn-add-combo{
  font-weight: bold;
  color: blue !important;
  cursor: pointer;
}
.table-combo > :not(:first-child) {
  border-top: unset !important;
}
.table-combo > thead, tbody, tfoot, tr, td, th {
  border-style: unset;
}
.formControlSelect {
  line-height: 2;
}
.input-group-text {
/*  background-color: white;
  border: none;
  border-left: 1px solid #ced4da ;*/
  padding: 3px;
  font-size: 14px;
}
.input-group > input {
  width: 87%;
}
.table-responsive {
  margin-top: -11px;
}
.service-combo-name {
  width: 30%;
}
.input-group > input {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.table-responsive > table > tbody {
  border: none;
}
.btn-delete {
  text-align: right !important;
}
.w-90 {
  width: 90%;
}
.status-payment {
  color: blue;
}
.status-booking-pending{
  color: rgb(245, 154, 36)
}
.status-booking-approvel{
  color: rgb(123, 188, 23)
}
.status-booking-reject{
  color: rgb(227, 71, 91)
}
.active{
  color: black !important;
  font-weight: 400 !important;
}
.block , .delete{
  color: red !important;
  font-weight: 400 !important;
}
/*.combo-service-price{
left: 52%;
}*/
</style>
