import $store from '../store';
import { AuthService } from '@/services/auth.service';

/**
 * Current user state initialization
 * @WARN Must be always first in middleware chain
 */
export async function initCurrentUserStateMiddleware (to, from, next) {
  window.scrollTo(0, 0);
  const currentUserId = $store.state.user.currentUser?.user_id ?? null;
  if (AuthService.hasAccessToken() && !currentUserId) {
    try {
      await $store.dispatch('user/getCurrent');
      next();
    } catch (e) {
      // track log sentry
      console.log(e);
    }
  } else {
    next();
  }
}

/**
 * Check access permission to auth routes
 */
export function checkAccessMiddleware (to, from, next) {
  const currentUserId = $store.state.user.currentUser?.id ?? null;
  const isAuthRoute = to.matched.some((item) => item.meta.isAuth);
  if (to.name === 'index') {
    next();
  }
  if (!isAuthRoute && currentUserId) return next({ name: 'index' });
  if (isAuthRoute && currentUserId) return next();
  if (isAuthRoute) return next({ name: 'login' });
  next();
}

export function checkAccessRoleMiddleware (to, from, next) {
  const currentUserRole = $store.state.user.currentUser?.role ?? null;
  const isAuthRoute = to.matched.some((item) => item.meta.isAdmin);
  if (isAuthRoute && currentUserRole === 1) {
    return next();
  }
  if (isAuthRoute && currentUserRole !== 1) {
    return next({ name: 'index' })
  }
  next()
}

export function setPageTitleMiddleware (to, from, next) {
  const pageTitle = to.matched.find((item) => item.meta.title);

  if (pageTitle) window.document.title = pageTitle.meta.title;
  next();
}
