import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
import { assert } from '@/core'

export class BookingService extends BaseService {
  static get entity () {
    return ''
  }

  static async getListCustomer (params) {
    try {
      const response = await this.request({ auth: true }).get(`customer/search-name/{name}?${params}`, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getListWeekly (params) {
    try {
      const response = await this.request({ auth: true }).get(`booking/list-weekly?${params}`, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async calculatorBookingWeekly (params) {
    try {
      const response = await this.request({ auth: true }).get(`booking/calculator-booking-weekly?${params}`, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async calculatorBookingWMonthly () {
    try {
      const response = await this.request({ auth: true }).get('booking/calculator-booking-monthly', { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getListDaily (params) {
    try {
      const response = await this.request({ auth: true }).get(`booking/list-daily?${params}`, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async createBooking (data) {
    assert.object(data, { required: true })
    try {
      const response = await this.request({ auth: true }).post('booking/create', data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async getInfoCustomer () {
    try {
      const response = await this.request({ auth: true }).get('customer/list')
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async getInfoBooking (id) {
    try {
      const response = await this.request({ auth: true }).get('booking/show/' + id);
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async editBooking (id, data) {
    assert.object(data, { required: true })
    try {
      const response = await this.request({ auth: true }).post('booking/edit/' + id, data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async deleteBooking (id) {
    try {
      const response = await this.request({ auth: true }).post('booking/delete/' + id)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async cancelBooking (id, data) {
    try {
      const response = await this.request({ auth: true }).post('booking/cancel-loop/' + id, data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async processComboService (id) {
    try {
      const response = await this.request({ auth: true }).get('booking/process-combo-service/' + id);
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }
}
