<template>
  <div class="login">
    <el-card v-loading.fullscreen.lock="loading" class="border-reset-password">
      <h2 class="text-center introduce-text">パスワード再設定</h2>
      <el-form ref="form" :model="model">
        <h4 class="text-gray">メールアドレスを入力してください。ご登録のメールアドレスへ仮パスワードをお送りします。</h4>
        <p class="introduce-text">新しいパスワード<span class="mandatory"></span></p>

        <el-form-item prop="password">
          <el-input v-model="model.password" placeholder="入力してください" show-password></el-input>
          <div v-if="error.length !== 0">
            <div v-if="error['password']">
              <div v-for="(item, index) in error.password" :key="index">
                <p class="error-text">{{ item }}</p>
              </div>
            </div>
          </div>
        <p class = "sub-title">※半角英数字や記号を使用し6文字以上12文字以内を入力してください。</p>
        </el-form-item>

        <p class="introduce-text">新しいパスワードを再入力。<span class="mandatory"></span></p>
        <el-form-item prop="password_confirmation">
          <el-input v-model="model.password_confirmation" placeholder="入力してください" show-password></el-input>
        <p class="sub-title">'※同じパスワードを確認のため、入力してください。</p>
        </el-form-item>

        <div class="reset-buton">
          <el-button class="reset-buton" style="margin: auto" type="primary" @click="resetPassword">更新する</el-button>
        </div>
      </el-form>
    </el-card>

    <el-dialog :before-close="handleClose" :visible.sync="dialogVisible" width="20%">
      <span style="font-weight: 400">パスワード再設定が完了しました。</span>
      <span slot="footer" class="dialog-footer button-footer" @click="handleClose">
        <el-button type="primary">トップページ  へ</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import axios from 'axios';
import { API_URL } from '../../.env';
import * as CONSTANT from '../../config/constant';

export default {
  name: 'resetPassword',
  data () {
    return {
      error: [],
      dialogVisible: false,
      validCredentials: {
        email: 'lightscope',
        password: 'lightscope'
      },
      model: {
        password: '',
        password_confirmation: ''
      },
      loading: false
    };
  },

  methods: {
    async resetPassword () {
      this.$refs.form.clearValidate(this.model)
      this.loading = true;
      await axios.post(API_URL + '/reset-password',
        {
          password: this.model.password,
          password_confirmation: this.model.password_confirmation,
          token: this.$route.query.token
        },
        { withCredentials: false })
        .then(response => {
          this.loading = false;
          this.dialogVisible = true
        })
        .catch(error => {
          if (error.response.data.code === CONSTANT.STATUS_CODE.BAD_REQUEST) {
            console.log(error.response.data);
            this.showErrorMessage(error.response.data.data, 'form')
            this.loading = false;
          }
        });
      this.loading = false;
    },
    handleClose () {
      this.$router.push({ name: 'login' })
    }
  }
};
</script>

<style scoped>
.sub-title{
  margin: 0;
}
.error-text {
  margin: -1% 0;
}
.login {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style lang="scss" scoped>
$teal: rgb(0, 124, 137);
.el-button--primary {
  background: $teal;
  border-color: $teal;

  &:hover,
  &.active,
  &:focus {
    background: lighten($teal, 7);
    border-color: lighten($teal, 7);
  }
}

.login .el-input__inner:hover {
  border-color: $teal;
}

.login .el-input__prefix {
  background: rgb(238, 237, 234);
  left: 0;
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  border-radius: 3px;

  .el-input__icon {
    width: 30px;
  }
}

.login .el-input input {
  padding-left: 35px;
}

.login .el-card {
  padding-top: 0;
  padding-bottom: 30px;

  .logo-login {
    display: flex;
    justify-content: center;
  }
}

h2 {
  font-family: 'Open Sans';
  letter-spacing: 1px;
  font-family: Roboto, sans-serif;
  padding-bottom: 20px;
  text-align: center;
}

a {
  color: $teal;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: lighten($teal, 7);
  }
}

.login .el-card {
  display: flex;
  justify-content: center;
  width: 500px;
}

.error-message {
  color: #F56C6C;
  font-size: 12px;
}

.login {
  width: 100%;
  height: 100vh!important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/img/background-login.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  .main {
    padding: 30px 15px;
    background: #fff;
    width: 400px;
    border-radius: 2px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);

    .header {
      text-align: center;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
    }

    .error {
      background-color: red;
      padding: 10px;
      font-size: 12px;
      opacity: 1;
      transition: all 0.5s;
    }

  }
    .sub-title {
      font-size: 13px;
      line-height: 30px;
    }
}
</style>
