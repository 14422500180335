<template>
  <div v-loading="loading">
    <div>
      <el-card class="box-card">
        <button-common  @submitConfirm="dialogCreate = true" v-on:cancleConfirm="openDialog" type="confirm"
                       btnOk="物販登録" btnCancel="物販削除">
        </button-common>

        <div v-if="showErrDelete === true" class="mandatory" style="width: 100% !important;"> 削除するアカウントを選択してください。</div>
        <div>{{listShopping.total}}件</div>
        <el-table
          ref="multipleTable"
          :data="listShopping.data"
          :fit="true"
          :header-row-class-name="'background-table'"
          border
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column :resizable="false" label="編集" type="selection" align="center" width="85px">
          </el-table-column>
          <el-table-column :resizable="false" label="物販コード" width="100px" align="center">
            <template slot-scope="scope" >
              <span>{{scope.row.code}}</span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="商品名" align="center">
            <template slot-scope="scope">
              <span>{{showProductName(scope.row.productName)}}</span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="合計（税込）" align="center">
            <template slot-scope="scope">
              <span>￥{{ scope.row.total_money_subtract_discount }}</span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="店舗" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.store_id === 1">白井店</span>
              <span v-else>浅草店</span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="購入日" align="center">
            <template slot-scope="scope">
              <span>{{ moment(scope.row.created_at).format('YYYY/MM/DD')}}</span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="支払方法" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.payment_method === constants.PAYMENT_METHOD_SHOPPING[0].value">{{ constants.PAYMENT_METHOD[0].label}}</span>
              <span v-else-if="scope.row.payment_method === constants.PAYMENT_METHOD_SHOPPING[1].value">{{ constants.PAYMENT_METHOD[1].label}}</span>
              <span v-else-if="scope.row.payment_method === constants.PAYMENT_METHOD_SHOPPING[2].value">{{ constants.PAYMENT_METHOD[1].label}}</span>
              <span v-else-if="scope.row.payment_method === constants.PAYMENT_METHOD_SHOPPING[3].value">{{ constants.PAYMENT_METHOD[1].label}}</span>
              <span v-else-if="scope.row.payment_method === constants.PAYMENT_METHOD_SHOPPING[4].value">{{ constants.PAYMENT_METHOD[1].label}}</span>
              <span v-else>{{ scope.row.payment_other}}</span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="担当者" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.admin_name}}</span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" align="center">
            <template slot-scope="scope">
              <el-button @click="editShopping(scope.row.id)" type="primary">編集</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div v-if="listShopping.data" style="margin-top: 20px; text-align: right">
          <my-pagination
            :total-record="parseInt(listShopping.total)"
            :current-page="parseInt(queryParam.page)"
            @handleCurrentChange="handleCurrentChange"
          />
        </div>
      </el-card>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleCloseDialog"
      width="20%"
      :closeOnClickModal="false"
    >
      <h5 class="textwidget text-center title-header-customer">本当に削除しますか?</h5>
      <span slot="footer" class="dialog-footer button-footer">
        <el-button @click="handleCloseDialog">キャンセル</el-button>
        <el-button type="danger" @click="deleteShopping"  id="w-button">確認</el-button>
      </span>
    </el-dialog>
    <CreateOrEditShopping v-on:RegisterOrEditSucess="fetchData"  :idedit="shoppingId" :dialog="dialogCreate" v-on:closeDialog="closePoopUpcreate"></CreateOrEditShopping>
  </div>
</template>
<script>
import { ShoppingService } from '@/services/shopping.service';
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin';
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin';
import CreateOrEditShopping from '@/pages/shopping/Create';
import * as constants from '@/config/constant';
import moment from 'moment';

export default {
  components: {
    CreateOrEditShopping
  },
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  data () {
    return {
      moment: moment,
      listShopping: [],
      constants: constants,
      loading: false,
      checkedDelete: [],
      dialogVisible: false,
      form: {
        size: constants.LIMIT_PAGINATE,
        page: 1
      },
      showErrDelete: false,
      dialogCreate: false,
      shoppingId: 0
    };
  },
  created () {
    this.getDataFromQuery();
    this.fetchData();
  },
  computed: {

    queryParam () {
      return this.$route.query;
    }
  },
  methods: {

    handleCloseDialog () {
      this.dialogVisible = false
    },

    openDialog () {
      if (this.checkedDelete.length === 0) {
        this.showErrDelete = true;
        var self = this;
        setTimeout(function () {
          self.showErrDelete = false;
        }, 2000);
        return true;
      }
      this.dialogVisible = true;
    },

    async deleteShopping () {
      try {
        const ids = [];
        this.showErrDelete = false;
        this.checkedDelete.forEach(function (element) {
          ids.push(element.id)
        })
        const deleteId = { ids: ids }
        const data = await ShoppingService.deleteShopping(deleteId);
        this.dialogVisible = false
        if (data.status === 200) {
          this.$notify.success({
            title: '成功',
            message: '削除できました。',
            showClose: false
          });
          if (this.listShopping.data.length === 1 && this.listShopping.current_page > 1) {
            const page_delete = this.queryParam.page - 1;
            this.$router.push({
              name: 'customer-detail',
              params: { customerId: this.$route.params.customerId },
              query: { ...this.queryParam, ...{ page: page_delete } }
            }).catch(() => {
            });
          } else {
            this.fetchData();
          }
        }
      } catch (e) {
        this.$notify.error({
          title: 'Error',
          message: 'アカウントが存在しないので、再度ご確認お願い致します。',
          showClose: false
        });
        this.dialogVisible = false
      }
    },
    showProductName (name) {
      if (name && name.length > 8) {
        name = name.slice(0, 8) + '...';
      }
      return name
    },
    handleSelectionChange (val) {
      this.checkedDelete = val
    },
    async fetchData () {
      this.loading = true;
      try {
        console.log(this.queryParam)
        const { data } = await ShoppingService.getListShopping(
          this.$route.params.customerId, this.prepareQueryParamsMixin(this.queryParam)
        );
        this.listShopping = data;
        this.listShopping.data.forEach(item => {
          let productName = ''
          let productPrice = 0
          item.shopping_detail.forEach(value => {
            productName = productName + value.product_name + '、'
            productPrice += parseFloat(value.product_price)
          })
          item.productName = productName.slice(0, -1)
          item.productPrice = productPrice
        })
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      } finally {
        this.loading = false;
      }
    },
    handleCurrentChange (val) {
      this.checkedDelete = []
      this.$router.push({
        name: 'customer-detail',
        params: { customerId: this.$route.params.customerId },
        query: { ...this.queryParam, ...{ page: val } }
      }).catch(() => {
      });
    },
    editShopping (id) {
      this.shoppingId = id
      this.dialogCreate = true
    },
    closePoopUpcreate () {
      this.dialogCreate = false
      this.shoppingId = 0
    }
  }
}
</script>
<style scoped>
#w-button{
  width: 120px;
  background-color: red;
}
</style>
