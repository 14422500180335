import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
import * as CONSTANT from '../config/constant'

export class ServicesService extends BaseService {
  static get entity () {
    return ''
  }

  static async getlist (data) {
    try {
      const response = await this.request({ auth: true }).post('/service/get-data', data, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async blockService (blockData, id) {
    try {
      const response = await this.request({ auth: true }).post('/service/block-service/' + id, blockData, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async blockCombo (blockData, id) {
    try {
      const response = await this.request({ auth: true }).post('/combo/block-combo/' + id, blockData, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getCode ($type) {
    try {
      let response = null;
      if ($type === CONSTANT.SERVICE_COMBO_TYPE[0].id) {
        response = await this.request({ auth: true }).get('service/get-code', { withCredentials: false })
      } else {
        response = await this.request({ auth: true }).get('combo/get-code', { withCredentials: false })
      }
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async StoreCategory () {
    try {
      const response = await this.request({ auth: true }).get('service/get-store-category', { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async createServiceBlock (combo_service_type, form, image) {
    try {
      let url = '';
      if (combo_service_type === CONSTANT.SERVICE_COMBO_TYPE[0].id) {
        url = 'service'
      } else {
        url = 'combo'
      }

      const formData = new FormData();
      if (form.category_id !== null && form.category_id !== undefined) {
        formData.append('category_id', form.category_id);
      }
      if (form.store_id !== null && form.store_id !== undefined && form.store_id !== 'null') {
        formData.append('store_id', form.store_id);
      } else {
        formData.append('store_id', 0);
      }
      if (form.block !== null && form.block !== undefined) {
        formData.append('block', form.block);
      }
      if (form.status !== null && form.status !== undefined) {
        formData.append('status', form.status);
      }
      if (form.name !== null && form.name !== undefined) {
        formData.append('name', form.name);
      }
      if (form.detail !== null && form.detail !== undefined) {
        formData.append('detail', form.detail);
      }
      if (image !== null && image !== undefined) {
        formData.append('image', image);
      }
      if (form.content !== null && form.content !== undefined) {
        formData.append('content', form.content);
      }
      if (form.service_id !== null && form.service_id !== undefined) {
        formData.append('service_id', form.service_id);
      }
      if (form.display_number !== null && form.display_number !== undefined) {
        formData.append('display_number', form.display_number);
      }

      for (let i = 0; i < form.process.length; i++) {
        let price = form.process[i].price;
        if (price != null && price !== '') {
          price = price.replaceAll(',', '');
          price = parseFloat(price);
        }

        let use_time = form.process[i].use_time;

        if (use_time != null && use_time !== '') {
          use_time = use_time.replaceAll(',', '');
          use_time = parseFloat(use_time);
        }

        formData.append('process[' + i + '][price]', price);
        formData.append('process[' + i + '][use_time]', use_time);
      }
      const response = await this.request({ auth: true }).post(url, formData, { withCredentials: false })

      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getServiceDetail (id) {
    try {
      const response = await this.request({ auth: true }).get('service/' + id, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async editService (form, image, id) {
    try {
      const url = 'service/' + id

      const formData = new FormData();
      if (form.category_id !== null && form.category_id !== undefined) {
        formData.append('category_id', form.category_id);
      }
      if (form.store_id !== null && form.store_id !== undefined && form.store_id !== 'null') {
        formData.append('store_id', form.store_id);
      } else {
        formData.append('store_id', 0);
      }
      if (form.block !== null && form.block !== undefined) {
        formData.append('block', form.block);
      }
      if (form.status !== null && form.status !== undefined) {
        formData.append('status', form.status);
      }
      if (form.name !== null && form.name !== undefined) {
        formData.append('name', form.name);
      }
      if (form.detail !== null && form.detail !== undefined) {
        formData.append('detail', form.detail);
      }
      if (image !== null && image !== undefined) {
        formData.append('image', image);
      }
      if (form.content !== null && form.content !== undefined) {
        formData.append('content', form.content);
      }
      if (form.display_number !== null && form.display_number !== undefined) {
        formData.append('display_number', form.display_number);
      }
      for (let i = 0; i < form.process.length; i++) {
        let price = form.process[i].price;
        if (price != null && price !== '') {
          price = price.replaceAll(',', '');
          price = parseFloat(price);
        }

        let use_time = form.process[i].use_time;

        if (use_time != null && use_time !== '') {
          use_time = use_time.replaceAll(',', '');
          use_time = parseFloat(use_time);
        }

        formData.append('process[' + i + '][id]', form.process[i].id ? form.process[i].id : '');
        formData.append('process[' + i + '][price]', price);
        formData.append('process[' + i + '][use_time]', use_time);
      }
      const response = await this.request({ auth: true }).post(url, formData, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async deleteService (id) {
    try {
      const response = await this.request({ auth: true }).delete('service/' + id, null, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async deleteServices (dataId) {
    try {
      const response = await this.request({ auth: true }).post('service/delete-service', dataId, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async checkOrder (id) {
    try {
      const response = await this.request({ auth: true }).get('service/check-order/' + id, null, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
