import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class HistoryBookingService extends BaseService {
  static get entity () {
    return ''
  }

  static async getListHistoryBooking (id, params) {
    try {
      const response = await this.request({ auth: true }).get(`history/${id}?${params}`, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
