import state from './sideBarState'
import mutations from './sideBarMutations'
import getters from './sideBarGetters'
import actions from './sideBarActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
