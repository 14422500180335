<template>
  <div>
    <el-card class="box-card ticket">
      <div slot="header" class="clearfix">
        <!-- <h3 class="title-header-customer">顧客の詳細情報</h3> -->
      </div>
      <CalendarDaily :activeFourth="activeFourth"></CalendarDaily>
    </el-card>
  </div>
</template>
<script>
import CalendarDaily from './CalendarDaily';
export default {
  components: {
    CalendarDaily
  },
  data () {
    return {
      activeName: 'third',
      activeFourth: false
    };
  },
  created () {
    if (this.$route.query.active) {
      this.activeName = this.$route.query.active
    }
  },
  methods: {
  }
};
</script>
