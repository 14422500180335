<template>
  <el-card class="box-card ticket">
    <div slot="header" class="clearfix">
      <h3 class="title-header-customer">顧客の詳細情報</h3>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="プロフィール" name="customer">
        <Edit></Edit>
      </el-tab-pane>
      <el-tab-pane label="マイチケット" name="ticket">
        <List></List>
      </el-tab-pane>
      <el-tab-pane label="予約履歴" name="booking">
        <History/>
      </el-tab-pane>
      <el-tab-pane label="物販履歴" name="shopping">
        <Shopping/>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import History from '../history/History.vue'
import List from '../ticket/myTicket/List';
import Edit from './Edit';
import Shopping from '../shopping/List.vue'
export default {
  components: { History, List, Edit, Shopping },
  data () {
    return {
      activeName: 'customer'
    }
  },
  created () {
    if (this.$route.query.tab) {
      this.activeName = this.$route.query.tab
    }
  },
  methods: {
    handleClick (tab, event) {
      this.$router.push({
        name: 'customer-detail',
        params: { customerId: this.$route.params.customerId },
        query: { ...{ tab: tab.name } }
      })
    }
  }
}
</script>

<style>

</style>
