<template>
  <div v-loading.fullscreen.lock="loading">
    <el-dialog
      :visible.sync="onDialog"
      width="40%"
      :title="titleDate"
      class="dialog-hour"
      align="center"
      :before-close="closeDialog"
      :show-close="false"
    >
      <p class="text-rest-time">予約不可の時間</p>
      <el-form ref="form">
        <el-form-item
          label-width="0px"
          v-for="(item, index) in arrayTime"
          :key="index"
          prop="parent"
        >
          <div class="form-hour">
            <el-form-item :prop="'arrayTime.'+index+'.star_hour'">
              <el-time-select
                placeholder="hh:mm"
                v-model="item.star_hour"
                class="time-select"
                :picker-options="{
              start: '10:00',
              step: '00:15',
              end: '21:00'
              }">
              </el-time-select>
            </el-form-item>

              <span class="tilde">~</span>
            <el-form-item :prop="'arrayTime.'+index+'.end_hour'">
              <el-time-select
                placeholder="hh:mm"
                v-model="item.end_hour"
                class="time-select"
                :picker-options="{
                start: '10:00',
                step: '00:15',
                end: '21:00',
                minTime: item.start_time}">
              </el-time-select>
            </el-form-item>
              <span v-if="arrayTime && arrayTime.length > 0" class="pt-2"><i @click="removeItem(index)" class="fas fa-trash-alt icon-delete"></i></span>
          </div>

        </el-form-item>
        <div class="icon-add">
          <i v-if="arrayTime && arrayTime.length > 0 " @click.prevent="addNewItem()" class="fas fa-plus-square"></i>
          <i v-else @click.prevent="addNewItem()" class="fas fa-plus-square i-custom"></i>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
              <div class="button-time text-center">
                 <el-button class="btn-cancel" style="width: 120px;" @click="closeDialog()">閉じる</el-button>
                <el-button style="width: 120px;" type="primary" @click="createBlockHour()">保存</el-button>
              </div>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import { BlockService } from '@/services/block.service';
import * as constants from '@/config/constant';
export default {
  name: 'Create',
  props: {
    onDialog: {
      type: Boolean,
      default: false
    },
    date: {
      type: String,
      default: ''
    },
    titleDate: {
      type: String,
      default: ''
    },
    startHour: {
      type: String,
      default: ''
    },
    flag: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      arrayTime: [{
        star_hour: '',
        end_hour: ''
      }],
      form: {
        arrayTime: [],
        start_day: ''
      },
      loading: false,
      popupCreate: false,
      constants: constants
    };
  },
  watch: {
    startHour: {
      handler: async function () {
        if (this.flag) {
          await this.getInfoBlockByDate(this.date)
        } else {
          await this.getInfoBlockByDate(this.date)
          if (this.arrayTime) {
            this.arrayTime.push(this.dataDefault());
            this.arrayTime[this.arrayTime.length - 1].star_hour = this.startHour
          } else {
            this.arrayTime = [{
              star_hour: this.startHour,
              end_hour: ''
            }]
          }
        }
      },
      deep: true
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close-dialog')
      this.$refs.form.clearValidate(this.form)
      this.arrayTime = [{
        star_hour: '',
        end_hour: ''
      }]
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    async createBlockHour () {
      try {
        this.$refs.form.clearValidate(this.form)
        this.loading = true;
        this.form.arrayTime = [];
        this.form.store_id = this.$route.params.storeId;
        this.form.arrayTime = this.arrayTime.map((arrayTime, index) => {
          arrayTime.index = index;
          return arrayTime;
        });
        this.form.start_day = this.date
        const data = await BlockService.createBlockHour(this.form);
        this.closeDialog()
        if (data.status === 200) {
          this.loading = false;
          this.$emit('close-dialog')
          this.$emit('get-list')
          this.$notify.success({
            title: '成功',
            message: '更新しました。',
            showClose: false
          });
        }
      } catch (e) {
        this.loading = false
        if (e.code === 422) {
          this.showErrorMessage(e.data, 'form');
        } else {
          this.$notify.error({
            title: 'Errors',
            message: e.message,
            showClose: false
          });
        }
      }
    },
    addNewItem () {
      this.errorMessage = {};
      this.arrayTime.push(this.dataDefault());
    },

    removeItem (index) {
      this.errorMessage = {};
      this.arrayTime.splice(index, 1);
    },

    dataDefault () {
      return {
        star_hour: '',
        end_hour: ''
      }
    },
    async  getInfoBlockByDate (date) {
      try {
        const store_id = this.$route.params.storeId
        const data = await BlockService.getInfoBlockByDate(date, store_id);
        if (data.status === 200) {
          this.arrayTime = data.data
        }
      } catch (error) {
        this.$notify.error({
          title: 'Error',
          message: error.message,
          showClose: false
        });
      }
    }
  }
};
</script>

<style scoped>
.tog-rest-time p {
  font-size: 25px;
}

.month table{
  width: 100%;
  height: 100%;
}

.month table tr td {
  width: 14%;
}
.icon-delete{
  color: red;
  font-size: 20px;
  margin-left: 20px;
}
.icon-add i{
  color: #3699ff;
  font-size: 30px;
  padding-top: 20px;
}
.btn-cancel{
  background-color: #C95C55;
  color: white;
  border-color: #C95C55;
}
.btn-cancel:hover{
  background-color: #C95C55;
  color: white;
  border-color: #C95C55;
}
.time-select{
  width: 170px !important;
}
::v-deep .dialog-hour .el-dialog__body{
  display: flex !important;
  justify-content: center !important;
}
::v-deep .dialog-hour .el-dialog__header{
  background-color: #169BD5;
  color: white;
}
.dialog-hour .el-dialog__header span{
  color: white;
}
.error{
  color: red;
  font-size: 11px;
}
.calendar ul {
  list-style: none;
}
.calendar ul li {
  float: left;
  margin-left: 10px;
}
.tilde{
  padding:0px 10px;
}
.i-custom{
  margin-left: -180px !important;
}
.text-rest-time{
  position: absolute;
  left: 50px;
}
.form-hour{
  padding-top: 30px;
  display: flex;
}
::v-deep .el-dialog__header {
  background-color: #169bd5;
  color: #fff;
  margin-bottom: 30px;
}
.icon-add {
  text-align: left;
}
::v-deep .el-dialog__headerbtn .el-dialog__close{
  display: none;
}
</style>
