<template>
  <div class="list-customer" v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <h3 class="title-header-customer">顧客管理</h3>
        <el-button icon="el-icon-download" style="float:right;" type="primary" @click="dialogChooseDateToExport=true">CSV出力</el-button>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" label-position="left">
        <el-row >
          <el-col :md="10">
            <el-form-item label="顧客の名前" prop="name" class="el-label-customer">
              <el-input v-model="ruleForm.name" placeholder="顧客の名前を入力"></el-input>
            </el-form-item>
          </el-col>

          <el-col :md="12">
            <el-form-item label="性別" prop="gender"  class="el-label-customer-checkbox" label-width="120px">
              <div class="el-checkbox-width">
                <el-checkbox-group v-model="ruleForm.gender">
                  <el-checkbox v-for="(value, index) in constants.GENDER" :key="index"
                               :label="value.value">{{ value.label }}
                  </el-checkbox>
              </el-checkbox-group></div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :md="10">
            <el-form-item label="顧客コード" prop="code" class="el-label-customer">
              <el-input v-model="ruleForm.code" placeholder="ユーザーIDを入力"></el-input>
            </el-form-item>
          </el-col>

          <el-col :md="12">
            <el-form-item label="お通いの店舗" prop="store_id"  class="el-label-customer-checkbox">
              <div class="el-checkbox-width">
                <el-checkbox-group v-model="ruleForm.store_id">
                  <el-checkbox v-for="(value, index) in listStore" :key="index"
                               :label="value.id">{{ value.name }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :md="10">
            <el-form-item label="電話番号" prop="phone"  class="el-label-customer">
              <el-input v-model="ruleForm.phone" placeholder="電話番号を入力"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :md="10">
            <el-form-item label="メールアドレス" prop="email"  class="el-label-customer">
            <el-input v-model="ruleForm.email" placeholder="顧客のメールを入力"></el-input>
          </el-form-item>
          </el-col>
        </el-row>
        <button-common  v-on:cancle="resetForm('ruleForm')" v-on:submit="clickSearch()" btnOk="検索" btnCancel="クリア">
        </button-common>
      </el-form>
      <hr>
        <!--begin::Dropdown-->
        <button-common  v-on:cancleConfirm="openDeleteDialog" v-on:submitConfirm="showPopup" type="confirm" btnOk="新規アカウント作成" btnCancel="アカウント削除">
        </button-common>
      <span>{{ listCustomer.count }}件</span>
      <div v-if="showErrDelete" class="mandatory">削除するアカウントを選択してください。</div>
      <div>
        <el-table
            ref="multipleTable"
            :data="listCustomer.data"
            :fit="true"
            :header-row-class-name="'background-table'"
            border
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
          <el-table-column :resizable="false" label="チェックボックス" type="selection" fixed align="center"  width="100px">
          </el-table-column>
          <el-table-column :resizable="false" label="顧客コード" width="100px" align="center">
            <template slot-scope="scope">
              <!--            <router-link :to="{ name: 'user-detail', params: { userid: scope.row.user_id }}">-->
              <span @click="ShowPopupEdit(scope.row.id)" class="customer-name">{{ scope.row.code ? scope.row.code : '' }}</span>
              <!--            </router-link>-->
            </template>
          </el-table-column>
          <el-table-column :resizable="false" prop="name" label="顧客の名前" align="center">
            <template slot-scope="scope">
              <span >{{scope.row.name_kanji ? scope.row.name_kanji : scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" prop="email" label="メールアドレス" align="center"></el-table-column>
          <el-table-column :resizable="false" prop="phone" label="電話番号" width="120px" align="center"></el-table-column>
          <el-table-column :resizable="false" prop="" label="性別" width="80px" align="center">
            <template slot-scope="scope">
              {{ scope.row.gender == constants.GENDER[0].value ? constants.GENDER[0].label  : constants.GENDER[1].label }}
            </template>
          </el-table-column>
          <el-table-column :resizable="false" prop="store" label="お通いの店舗" align="center"></el-table-column>
          <el-table-column :resizable="false" prop="birthday" label="誕生日" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.birthday ? moment(scope.row.birthday).format('YYYY/MM/DD') : '' }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="listCustomer.data" style="margin-top: 20px; text-align: right">
        <my-pagination
          :total-record="parseInt(listCustomer.count)"
          :current-page="parseInt(querySearch.page)"
          @handleCurrentChange="handleCurrentChange"
          align="right"
        />
      </div>
      <create-customer :customerId="customerCurrenId" :editCustomer="checkEdit" @registerSussce="fetchData" @closeDialog="closePopup" :storeList="listStore" :dialog="showPopupCreate"></create-customer>
    </el-card>
    <my-dialog class="customer-confirm-delete" v-on:closeDialog="confirmDelete=false" v-on:submit="deleteCustomer" v-bind:dialog="confirmDelete"
            :closeOnClickModal="false" width="28%" btnOk="確認" :showCancelButton="true" btnCancel="キャンセル">
      <template slot="content">
        <h5 class="textwidget text-center title-header-customer">
          本当に削除しますか？
        </h5>
      </template>
    </my-dialog>
    <my-dialog class="choose-date" @closeDialog="dialogChooseDateToExport=false" @submit="exportCsv" :dialog="dialogChooseDateToExport"
               :closeOnClickModal="false" width="40%" btnOk="確認" :showCancelButton="true" btnCancel="キャンセル">
      <template slot="content">
        <el-form ref="form" class="p-3" v-model="dataSearch" :label-width="'150px'">
          <el-form-item
            label="出力日"
            prop="parent"
          >
            <div class="form-hour">
              <el-form-item
                label=""
                class="el-label-customer text-color start-required start-date width-style"
                prop="start_date"
              >
                <el-date-picker
                  v-model="dataSearch.start_date"
                  type="date"
                  format="yyyy-MM-dd"
                  :clearable="false"
                >
                </el-date-picker>
              </el-form-item>
              <span class="tilde">~</span>
              <el-form-item
                :label-width="'0'"
                prop="end_date"
              >
                <el-date-picker
                  v-model="dataSearch.end_date"
                  type="date"
                  format="yyyy-MM-dd"
                  :clearable="false"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </el-form-item>
        </el-form>
      </template>
    </my-dialog>
  </div>
</template>
<script>
import { CustomerService } from '@/services/customer.service';
import { StoreService } from '@/services/store.service';
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin';
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin';
import * as constants from '@/config/constant';
import moment from 'moment';
import { ShoppingService } from '@/services/shopping.service';
export default {
  components: {
  },
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  data () {
    return {
      form: {
        size: constants.LIMIT_PAGINATE,
        page: 1
      },
      ruleForm: {
        name: '',
        code: '',
        phone: '',
        email: '',
        gender: [],
        store_id: []
      },
      strore_default: [],
      rules: {},
      listCustomer: [],
      listStore: [],
      constants: constants,
      showPopupCreate: false,
      loading: false,
      checkEdit: false,
      customerCurrenId: 0,
      checkedDelete: [],
      confirmDelete: false,
      showErrDelete: false,
      dataSearch: {
        start_date: moment().subtract('1', 'months').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD')
      },
      dialogChooseDateToExport: false,
      moment: moment
    };
  },
  computed: {
    queryParam () {
      return this.$route.query
    }
  },
  watch: {
    // listStore (value) {
    //   if (value) {
    //     this.ruleForm.store_id.push(value[0] ? value[0].id : '');
    //     this.strore_default.push(value[0] ? value[0].id : '');
    //   }
    // }
  },
  created () {
    this.loading = true;
    this.getDataFromQuery();
    this.getDataFormUrl()
    this.fetchData();
    this.getListStore();
    this.loading = false;
  },
  methods: {
    convertParamFormUrl (param) {
      let result = [];
      if (param) {
        if (param instanceof Array) {
          result = param.map(i => Number(i))
        } else {
          result = [Number(param)]
        }
      }
      return result;
    },
    getDataFormUrl () {
      this.ruleForm = {
        name: this.queryParam.name ? this.queryParam.name : '',
        code: this.queryParam.code ? this.queryParam.code : '',
        phone: this.queryParam.phone ? this.queryParam.phone : '',
        email: this.queryParam.email ? this.queryParam.email : '',
        gender: this.convertParamFormUrl(this.queryParam.gender),
        store_id: this.convertParamFormUrl(this.queryParam.store_id)
      }
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
      const clearRuleForm = Object.assign({}, this.ruleForm)
      clearRuleForm.gender = []
      this.ruleForm.store_id = this.strore_default
      this.$router.push({
        name: 'list-customer',
        query: { ...clearRuleForm, ...{ page: 1 } }
      }).catch(() => {
      })
    },
    async fetchData () {
      try {
        const { data } = await CustomerService.getListCustomer(
          this.prepareQueryParamsMixin(this.querySearch)
        );
        this.listCustomer = data;
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      } finally {
      }
    },
    async getListStore () {
      try {
        const { data } = await StoreService.getListStore()
        this.listStore = data.data;
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      } finally {
      }
    },
    showPopup () {
      this.showPopupCreate = true
    },
    clickSearch () {
      this.$router.push({
        name: 'list-customer',
        query: { ...this.queryParam, ...this.ruleForm, ...{ page: 1 } }
      }).catch(() => {
      });
    },
    ShowPopupEdit (id) {
      this.checkEdit = true
      this.$router.push({
        name: 'customer-detail',
        params: { customerId: id }
      })
      this.customerCurrenId = id
    },
    closePopup () {
      this.checkEdit = false
      this.showPopupCreate = false
      this.customerCurrenId = 0
    },
    handleSelectionChange (val) {
      this.showErrDelete = false
      this.checkedDelete = val
    },
    openDeleteDialog () {
      if (this.checkedDelete.length === 0) {
        this.showErrDelete = true;
        var self = this;
        setTimeout(function () {
          self.showErrDelete = false;
        }, 2000);
        return true
      }
      this.confirmDelete = true
    },
    async exportCsv () {
      try {
        this.loading = true
        const dataSearch = JSON.parse(JSON.stringify(this.dataSearch))
        dataSearch.start_date = moment(dataSearch.start_date).format('YYYY-MM-DD')
        dataSearch.end_date = moment(dataSearch.end_date).format('YYYY-MM-DD')
        const data = await ShoppingService.getDataToExport(this.prepareQueryParamsMixin(dataSearch))
        const blob = new Blob([data.data], { type: 'text/csv;charset=Shift_JIS' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = '物販履歴_' + dataSearch.start_date + '_' + dataSearch.end_date + '.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this.loading = false
        this.dialogChooseDateToExport = false
      } catch (e) {
        this.loading = false
        if (e.code === constants.STATUS_CODE.BAD_REQUEST) {
          this.showErrorMessage(e.data, 'form');
          return
        }
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      }
    },
    async deleteCustomer () {
      try {
        this.loading = true;
        this.confirmDelete = false
        const vm = this
        const listId = [];
        Object.keys(this.checkedDelete).forEach(function (key) {
          listId.push(vm.checkedDelete[key].id)
        });
        await CustomerService.removeCustomer(listId)
        this.fetchData()
        this.$notify.success({
          title: '成功',
          message: '削除しました。',
          showClose: false
        });
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.form-hour{
  display: flex;
}
.tilde{
  padding:0px 10px;
}
</style>
