
<template>
  <div v-loading.fullscreen.lock="loading">
    <el-dialog
      :visible.sync="onDialog"
      class="dialog-hour"
      :before-close="closeDialog"
      width="40%"
      align="center"
    >
      <el-form ref="form">
        <el-form-item
          class="el-label-customer text-color"
        >
          <el-radio  v-model="form.status" @click.native="resetTime()" :label="1">営業</el-radio>
          <el-radio  v-model="form.status" @click.native="resetTime()" :label="0">休み</el-radio>
        </el-form-item>
        <el-form-item prop="parent" class="form-set-block-hour" label="営業時間">
          <el-row :gutter="20">
          <el-col :span="9">
              <el-form-item class="form-content set-time" prop="work_start_time" label-width="0px">
                <el-time-select
                  placeholder="選択してください"
                  v-model="form.work_start_time"
                  :disabled="form.status === 0"
                  :picker-options="{
                    start: store_start_time,
                    step: '00:15',
                    end: store_end_time,
            }">
                </el-time-select>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <span class="tilde">~</span>
            </el-col>
            <el-col :span="9">
              <el-form-item class="form-content set-time"  prop="work_end_time" label-width="0px">
                <el-time-select
                  placeholder="選択してください"
                  v-model="form.work_end_time"
                  :disabled="form.status === 0"
                  :picker-options="{
                    start: store_start_time,
                    step: '00:15',
                    end: store_end_time,
                  }">
                </el-time-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="parent" class="form-set-block-hour set-time" label="休憩時間">
          <el-row :gutter="20">
          <el-col :span="9">
              <el-form-item class="form-content" prop="off_start_time" label-width="0px">
                <el-time-select
                  placeholder="選択してください"
                  v-model="form.off_start_time"
                  :disabled="form.status === 0"
                  :picker-options="{
                    start: store_start_time,
                    step: '00:15',
                    end: store_end_time,
                  }">
                </el-time-select>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <span class="tilde">~</span>
            </el-col>
            <el-col :span="9">
              <el-form-item class="form-content set-time" prop="off_end_time" label-width="0px">
                <el-time-select
                  placeholder="選択してください"
                  v-model="form.off_end_time"
                  :disabled="form.status === 0"
                  :picker-options="{
                    start: store_start_time,
                    step: '00:15',
                    end: store_end_time,
                }">
                </el-time-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
              <div class="button-time text-center">
                 <el-button class="btn-cancel" style="width: 120px;" @click="closeDialog()">閉じる</el-button>
                <el-button style="width: 120px;" type="primary" @click="createWorkingTime()">設定</el-button>
              </div>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import * as constants from '@/config/constant';
import { STORE_START_TIME, STORE_END_TIME } from '@/config/constant';

import { workingtimeService } from '../../services/workingtime.service';

export default {
  name: 'Create',
  props: {
    onDialog: {
      type: Boolean,
      default: false
    },
    date: {
      type: String,
      default: ''
    },
    store: {
      type: Object,
      default: () => {}
    },
    flag: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        work_start_time: STORE_START_TIME,
        work_end_time: STORE_END_TIME,
        off_start_time: '',
        off_end_time: '',
        status: 1
      },
      store_start_time: STORE_START_TIME,
      store_end_time: STORE_END_TIME,
      loading: false,
      popupCreate: false,
      constants: constants
    };
  },
  watch: {
    flag () {
      if (this.flag) {
        this.getInfoBlockByDate()
      }
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close-dialog')
      this.$refs.form.clearValidate(this.form)
      this.form.work_start_time = STORE_START_TIME
      this.form.work_end_time = STORE_END_TIME
      this.form.off_start_time = ''
      this.form.off_end_time = ''
      this.form.status = 1
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    async createWorkingTime () {
      try {
        this.$refs.form.clearValidate(this.form)
        this.loading = true;
        this.form.date = this.date
        this.form.store_id = this.store.id
        if (this.flag) {
          await workingtimeService.updateWorkingTime(this.form);
        } else {
          await workingtimeService.createWorkingTime(this.form);
        }
        this.loading = false;
        this.closeDialog();
        this.$emit('get-list')
        this.$notify.success({
          title: '成功',
          message: '更新しました。',
          showClose: false
        });
      } catch (e) {
        this.loading = false
        if (e.code === 422) {
          this.showErrorMessage(e.data, 'form');
        } else {
          this.$notify.error({
            title: 'Errors',
            message: e.message,
            showClose: false
          });
        }
      }
    },
    dataDefault () {
      return {
        star_hour: '',
        end_hour: ''
      }
    },
    async getInfoBlockByDate () {
      try {
        this.form.date = this.date
        this.form.store_id = this.store.id
        const data = await workingtimeService.getInfoBlockByDate(this.form);
        if (data.status === 200) {
          this.form.id = data.data.id
          this.form.status = data.data.status
          this.form.work_start_time = data.data.work_start_time ? data.data.work_start_time.slice(0, -3) : ''
          this.form.work_end_time = data.data.work_end_time ? data.data.work_end_time.slice(0, -3) : ''
          this.form.off_start_time = data.data.off_start_time ? data.data.off_start_time.slice(0, -3) : ''
          this.form.off_end_time = data.data.off_end_time ? data.data.off_end_time.slice(0, -3) : ''
        }
      } catch (error) {
        this.$notify.error({
          title: 'Error',
          message: error.message,
          showClose: false
        });
      }
    },
    resetTime () {
      this.form.work_start_time = ''
      this.form.work_end_time = ''
      this.form.off_start_time = ''
      this.form.off_end_time = ''
    }
  }
};
</script>

<style scoped>
.text-rest-time {
  text-align: left;
}
.btn-cancel{
  background-color: #C95C55;
  color: white;
  border-color: #C95C55;
}
.btn-cancel:hover{
  background-color: #C95C55;
  color: white;
  border-color: #C95C55;
}
.set-time .el-date-editor.el-input{
  width: 100%;
}
</style>
