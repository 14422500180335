import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
import moment from 'moment';

export class ReviewService extends BaseService {
  static get entity () {
    return ''
  }

  static async getList (params) {
    try {
      const response = await this.request({ auth: true }).get(`review/list?${params}`, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async delete (listId) {
    try {
      const response = await this.request({ auth: true }).post('review/delete', listId)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async showDetail (id) {
    try {
      const response = await this.request({ auth: true }).get(`review/show/${id}`, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async EditReview (data, id) {
    try {
      const url = `review/edit/${id}`
      const formData = new FormData();
      formData.append('anony', data.anony);
      formData.append('age', data.age);
      formData.append('gender', data.gender);
      formData.append('date', data.date ? moment(data.date).format('yyyy-MM-DD') : '');
      formData.append('name', data.name);
      formData.append('status', data.status);
      formData.append('content', data.content);
      formData.append('rmImage1', data.rmImage1);
      formData.append('rmImage2', data.rmImage2);
      formData.append('image1', data.image1 ?? '');
      formData.append('image2', data.image2 ?? '');
      const response = await this.request({ auth: true }).post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }
}
