<template>
  <div
    class="sidebar"
    :data-color="sidebarItemColor"
    :data-image="sidebarBackgroundImage"
    :style="sidebarStyle"
  >
    <div class="logo">
      <router-link :to="{name: 'service-list'}" class="simple-text logo-mini">
        <div class="logo-img">
          <img id="logo_sidebar" src="@/assets/img/logo_new.png" alt="ansena logo">
        </div>
      </router-link>
    </div>
    <div class="sidebar-wrapper">
      <slot name="content"></slot>
      <el-col :span="24">
        <el-menu
          :default-active="getIndex"
          class="el-menu-vertical-demo"
          background-color="unset"
          text-color="#fff"
          active-text-color="#fff"
          style="border: 0 !important;"
        >
        <el-menu-item index="6" @click="$router.push({name: 'notification'})">
            <i class="el-icon-message"></i>
            <span style="position: relative; display: inline-block;">
              お知らせ管理
              <i class="el-icon-message-solid" v-show="$store.state.sidebar.currentUnread > 0"></i>
              <span class="badge" style="" v-show="$store.state.sidebar.currentUnread > 0">{{$store.state.sidebar.currentUnread > 99 ? '99+' : $store.state.sidebar.currentUnread}}</span>
            </span>
          </el-menu-item>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-date"></i>
              <span>予約管理</span>
            </template>
<!--            comment shop 2-->
<!--            <el-menu-item index="10" @click="$router.push({name: 'store', params: {storeId: 2}})">-->
<!--              <i class="el-icon-s-shop"></i>-->
<!--              <span>浅草店の予約 {{store['2'] && store['2'].totalBookingNotApprovedInMonth && store['2'].totalBookingNotApprovedInMonth != 0 ? '(' + store['2'].totalBookingNotApprovedInMonth + ')' : ''}}</span>-->
<!--            </el-menu-item>-->
            <el-menu-item index="9" @click="$router.push({name: 'store', params: {storeId: 1}})">
              <i class="el-icon-s-shop"></i>
              <span>{{storeName}} {{store['1'] && store['1'].totalBookingNotApprovedInMonth && store['1'].totalBookingNotApprovedInMonth != 0 ? '(' + store['1'].totalBookingNotApprovedInMonth + ')' : ''}}</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item v-bind:class="{ 'is-active': $route.name == 'customer-detail' }" index="2" @click="$router.push({name: 'list-customer'}).catch(err => {})">
            <i class="el-icon-user"></i>
            <span>顧客一覧</span>
          </el-menu-item>
          <!--<el-menu-item index="4" @click="$router.push({name: 'campaign'})">
            <i class="el-icon-shopping-bag-1"></i>
            <span>キャンペーン一覧</span>
          </el-menu-item> -->
          <el-menu-item v-bind:class="{ 'is-active': $route.name == 'shift-calendar' }" index="7" v-if="$currentUser.role === 1" @click="$router.push({name: 'staff-list'})">
            <i class="el-icon-s-check"></i>
            <span>スタッフ管理</span>
          </el-menu-item>
          <el-menu-item v-bind:class="{ 'is-active': $route.name == 'review' }" index="11" v-if="$currentUser.role === 1" @click="$router.push({name: 'review'})">
            <i class="el-icon-star-off"></i>
            <span>レビュー</span>
          </el-menu-item>
          <el-menu-item index="1" @click="$router.push({name: 'service-list'}).catch(err => {})">
            <i class="el-icon-postcard"></i>
            <span>メニュー管理</span>
          </el-menu-item>
          <el-menu-item index="8" v-if="$currentUser.role === 1" @click="$router.push({name: 'policy'})">
            <i class="el-icon-document-copy"></i>
            <span>プライバシーポリシー</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="24">-
        <md-list class="nav">
          <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
<!--          <slot>-->
<!--            <sidebar-link-->
<!--              v-for="(link, index) in sidebarLinks"-->
<!--              :key="link.name + index"-->
<!--              :to="link.path"-->
<!--              :link="link"-->
<!--            >-->
<!--            </sidebar-link>-->
<!--          </slot>-->
        </md-list>
      </el-col>
    </div>
  </div>
</template>
<script>
import { BookingService } from '@/services/booking.service';
import { StoreService } from '@/services/store.service';
import { NotificationService } from '@/services/notification.service';

export default {
  props: {
    title: {
      type: String,
      default: 'あんせな株式会社'
    },
    sidebarBackgroundImage: {
      type: String,
      default: require('@/assets/img/sidebar-2.jpg')
    },
    imgLogo: {
      type: String,
      default: require('@/assets/img/ansena_logo.png')
    },
    sidebarItemColor: {
      type: String,
      default: 'green',
      validator: (value) => {
        const acceptedValues = ['', 'purple', 'blue', 'green', 'orange', 'red'];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      store: [],
      storeName: ''
    }
  },
  provide () {
    return {
      autoClose: this.autoClose
    };
  },
  created () {
    this.getListNotification()
    this.calculatorBookingWeekly()
    this.getStoreName()
  },
  computed: {
    sidebarStyle () {
      return {
        backgroundImage: `url(${this.sidebarBackgroundImage})`
      };
    },
    getIndex () {
      let index = 1
      switch (this.$route.name) {
        case 'service-list':
          index = 1
          break
        case 'service-create':
          index = 1
          break
        case 'combo-edit':
          index = 1
          break
        case 'service-edit':
          index = 1
          break
        case 'list-customer':
          index = 2
          break
        case 'campaign':
          index = 4
          break
        case 'campaign-create':
          index = 4
          break
        case 'campaign-edit':
          index = 4
          break
        case 'ticket':
          index = 5
          break
        case 'notification':
          index = 6
          break
        case 'staff-list':
          index = 7
          break
        case 'staff-edit':
          index = 7
          break
        case 'policy':
          index = 8
          break
        case 'review':
          index = 11
          break
        case 'store':
          if (this.$route.params.storeId === 1 || this.$route.params.storeId === '1') {
            index = 9
          } else {
            index = 10
          }
          break
        default:
          index = 0
      }
      return index.toString()
    }
  },
  methods: {
    async getStoreName () {
      try {
        const listStore = await StoreService.getListStore()
        this.storeName = listStore.data.data[0].name
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        })
      }
    },

    async calculatorBookingWeekly () {
      try {
        this.calculator = {}
        const store = await BookingService.calculatorBookingWMonthly()
        this.store = store.data
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        })
      }
    },

    async getListNotification () {
      try {
        this.loading = true
        const data = await NotificationService.getCountNotification()
        this.$store.commit('sidebar/SET_CURRENT_NUMBER_UNREAD', data.data)
        this.loading = false
      } catch (e) {
        this.loading = false
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        })
      }
    }
  }
};
</script>
<style scoped>
@media screen and (min-width: 991px) {
  .nav-mobile-menu {
    display: none;
  }
}
.router-link-active {
  background: inherit !important;
}
.badge {
  position: absolute;
  top: 11px;
  height: 17px;
  width: 17px;
  right: -27px;
  border-radius: 50%;
  background-color: red;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}
.el-icon-message-solid {
  font-size: 24px;
  padding-left: 80px;
}
.logo{
  width: 280px;
}
.logo img {
  transform: scale(1.1) translateY(-33px);
}
#logo_sidebar{
  padding: 43px 20px 5px 0px;
}
.logo-img {
  height: unset !important;
  overflow: unset !important;
}
</style>
