<template>
  <div>
    <el-card class="box-card">
      <el-form>
        <div>
          <el-form-item>
            <label class="label-two">プライバシーポリシー</label>
            <ckeditor :config="editorConfig" v-model="form.privacy"></ckeditor>
          </el-form-item>
        </div>

        <div>
          <el-form-item>
            <label class="label-two">利用規約</label>
            <ckeditor :config="editorConfig2" v-model="form.term"></ckeditor>
          </el-form-item>
        </div>
        <button-common  v-on:cancleCreateOrCancelEdit="detailPolicy" v-on:submitCreateOrCancleEdit="updatePolicy" type="create-edit" btnOk="更新" btnCancel="キャンセル">
        </button-common>

      </el-form>
    </el-card>

  </div>

</template>

<script>

import { API_URL } from '../../../src/.env.js';
import { PolicyService } from '@/services/policy.service';

export default {
  name: 'policy',
  props: {},
  data () {
    return {
      editorConfig: {
        extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',
        // Adding drag and drop image upload.
        allowedContent: true,
        extraPlugins: 'image2,embed,autoembed,',
        embed_provider: 'https://ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',

        uploadUrl: API_URL + '/upload-image',
        language: 'ja',
        filebrowserUploadUrl: API_URL + '/upload-image',
        filebrowserImageUploadUrl: API_URL + '/upload-image',
        image2_disableResizer: false,
        height: 300,
        removeDialogTabs: 'image:advanced;link:advanced',
        removeButtons: 'PasteFromWord'

      },
      editorConfig2: {
        extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',
        extraPlugins: 'image2,embed,autoembed,',
        embed_provider: 'https://ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
        allowedContent: true,

        uploadUrl: API_URL + '/upload-image',
        language: 'ja',
        filebrowserUploadUrl: API_URL + '/upload-image',
        filebrowserImageUploadUrl: API_URL + '/upload-image',
        image2_disableResizer: false,
        height: 300,
        removeDialogTabs: 'image:advanced;link:advanced',
        removeButtons: 'PasteFromWord'

      },
      form: {
        privacy: '',
        term: ''
      }
    };
  },
  created () {
    this.detailPolicy();
  },
  methods: {
    async detailPolicy () {
      try {
        this.loading = true;
        const { data } = await PolicyService.detailPolicy();
        this.form = data.data
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      } finally {
        this.loading = false;
      }
    },
    async updatePolicy () {
      try {
        this.loading = true;
        await PolicyService.updatePolicy(this.form);
        this.$notify.success({
          title: '成功',
          message: '更新されました',
          showClose: false
        });
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
  .cancel{
    background-color: white;
    width: 120px;
    border-style: solid;
    border-color: #0026ffe8;
    color: #0026ffe8;
  }
  .update{
    width: 120px;
  }
  .btnBlock{
    float: right;
  }
  .label-two{
    font-weight: bold;
    font-size: 24px;
  }
</style>
