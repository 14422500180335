<template>
  <el-form-item>
    <el-button
      :loading="loadingFetch"
      class="login-button"
      type="primary"
      native-type="submit"
      block
      >{{ titleButton }}</el-button
    >
  </el-form-item>
</template>
<script>
export default {
  props: {
    titleButton: {
      type: String,
      default: ''
    },
    loadingFetch: {
      type: Boolean,
      default: false
    }
  }
};
</script>
