<template>
  <div class="calendar-daily" v-loading.fullscreen.lock="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix row">
        <h3 v-if="listDaily.store" class="col-6" style="font-weight: bold">{{listDaily.store.name}}の予約情報 </h3>
        <el-row :gutter="20" class="ml-4 width-max-content">
          <span>今月の承認待ち数: <b class="color-pending">{{calculator.totalBookingNotApprovedInMonth}}</b>件</span>
          <span class="pl-25">今月の承認済み数: <b class="color-approved">{{calculator.totalBookingApprovedInMonth}}</b>件</span>
        </el-row>
      </div>
      <el-button icon="el-icon-download" style="float:right;" type="primary" @click="exportCsv">CSV出力</el-button>
      <el-form label-width="120px">
        <el-button type="text" icon="el-icon-plus" class="add-booking" @click="openPopupCreate()"><u>新規予定を作成</u></el-button>
        <el-form-item label="店舗名" class="el-label-customer el-ticket-label">
          <el-col :span="6" v-if="listDaily.store">
            {{listDaily.store.name}}
          </el-col>
        </el-form-item>
        <h4 class="lable-text-booking_notes">注釈</h4>
        <div class="el-label-customer el-ticket-label">
          <div class="row">
            <div class="col-sm-4">
              <el-form-item
                label="予約の承認待ち"
                class="el-label-customer el-ticket-label"
              >
                <div class="input-burlywood"></div>
              </el-form-item>
            </div>
            <div class="col-sm-4">
              <el-form-item
                label="予約の承認済み"
                class="el-label-customer el-ticket-label"
              >
                <div class="input-green"></div>
              </el-form-item>
            </div>
            <template v-if="editableTabsValue === '1'">
              <div class="col-sm-4">
                <el-form-item label="新規の方" class=""> <span class="text-danger">( * )</span></el-form-item>
              </div>
            </template>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <el-form-item
                label="店員の勤務時間"
                class="el-label-customer el-ticket-label"
              >
                <div class="input-blue"></div>
              </el-form-item>
            </div>
            <div class="col-sm-4">
              <el-form-item
                label="予約不可時間"
                class="el-label-customer el-ticket-label"
              >
                <div class="input-gray"></div>
              </el-form-item>
            </div>
          </div>
          <div class="row lable-text-booking_notes pl-15">
            ❋カレンダーの白い・グレー箇所をクリックすることで、予約のスケジュールを設定・削除することができます。
          </div>
        </div>
        <div class="calendar-detail mt-3">
          <el-row :gutter="20" class="choose-date">
            <template v-if="editableTabsValue === '2'">
              <i class="el-icon-caret-left" @click="prevWeek()"></i>
              <el-date-picker
                v-model="dateFrom"
                :clearable="false"
                value-format="yyyy-MM-dd"
                :picker-options="{
                  disabledDate(time) {
                    return new Date(time).getDay() !== 1;
                  },
                  firstDayOfWeek: 1
                }"
                type="date"
                placeholder="From">
              </el-date-picker>
              <span style="padding: 0px 20px"> ~ </span>
              <el-date-picker
                v-model="dateTo"
                readonly
                disabled
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="To">
              </el-date-picker>
              <i class="el-icon-caret-right" @click="nextWeek()"></i>
            </template>
            <template v-else>
              <i class="el-icon-caret-left" @click="prevDay()"></i>
              <el-date-picker
                v-model="dateDaily"
                value-format="yyyy-MM-dd"
                :picker-options="{
                 disabledDate() {
                    return false;
                  },
                  firstDayOfWeek: 1
              }"
                placeholder="Choose date"
                type="date">
              </el-date-picker>
              <i class="el-icon-caret-right" @click="nextDay()"></i>
            </template>
          </el-row>
          <el-row :gutter="20">
            <el-tabs type="card" v-model="editableTabsValue">
              <el-tab-pane :label="'日'" :name="'1'">
                <div class="container-calendar">
<!--                  <caption class="text-center width-100vw">{{dateDaily}}</caption>-->
                  <el-row :gutter="20" class="ml-4 width-max-content">
                    承認待ち数: <b class="color-pending">{{totalPendingDaily}}</b>件
                    <span style="margin-left: 68px;">承認済み数: <b class="color-approved">{{totalApprovedDaily}}</b>件</span>
                  </el-row>
                  <table class="calendar daily my-5">
                    <tr class="weekdays">
                      <th scope="col" class="first-column">シフトカレンダー</th>
                      <th scope="col" v-for="(time, index) in listTimeInDay" :key="index">{{time.time}}</th>
                    </tr>
                    <tr v-for="(staff, index) in listDaily.staffSchedules" :key="index" class="tool_tip calendar-normal" @click="toggleClass($event)">
                      <template v-if="staff.status !== constant.STAFF_SCHEDULE_STATUS[0].value">
                        <td v-if="staff.user && staff.user.status !== 0">
                          <el-tooltip :class="staff.user.name_kanji ? staff.user.name_kanji.length >= 7 ? 'tool' : 'item' : (staff.user.name && staff.user.name.length >= 7) ? 'tool' : 'item'" effect="dark"  :disabled="staff.user.name_kanji ? staff.user.name_kanji.length <= 7 : staff.user.name ? staff.user.name.length <= 7 : false" :content="staff.user.name_kanji ? staff.user.name_kanji : staff.user.name" placement="right">
                            <span>{{staff.user.name_kanji ? staff.user.name_kanji : staff.user.name}}</span>
                          </el-tooltip>
                        </td>
                        <td v-else>
                          <el-tooltip class="item" effect="dark" content="" placement="right">
                            <span></span>
                          </el-tooltip>
                        </td>
                        <template v-for="(time, key) in listTimeInDay">
                          <td scope="col" :key="'td_' + index + key" :class="time.staff.indexOf(staff.user_id) >= 0 ? 'staff-work' : 'block-time'"></td>
                        </template>
                      </template>
                    </tr>
                  </table>
                  <div>
                    <table class="calendar daily title-weekdays">
                      <tr class="weekdays">
                        <th scope="col" class="first-column">予約カレンダー</th>
                        <th scope="col" v-for="(time, index) in listTimeInDay" :key="index">{{time.time}}</th>
                      </tr>
                      <tr>
                        <td scope="col">予約不可</td>
                        <td scope="col" v-for="(time, index) in listTimeInDay" :key="index" :class="time.block ? 'block-time' : ''" @click="setHour(time, time.block)"></td>
                      </tr>
                    </table>
                    <table class="calendar daily">
                      <tr v-for="(booking, index) in listBookingShow" :key="index" class="calendar-normal" @click="toggleClass($event)">
                        <td scope="col">
                          <el-tooltip :class="booking.name.length >= 7 ? 'tool' : 'item'" effect="dark"  :disabled="booking.name.length <= 7" :content="booking.name" placement="right">
                            <span>{{booking.name}}</span>
                          </el-tooltip>
                        </td>
                        <template v-for="(time, key) in listTimeInDay">
                          <td scope="col" :key="'td_' + key" class="position-relative tool_tip">
                            <el-tooltip :content="booking.combos.length > 0 ? booking.category + '、コース' : booking.category" :disabled="booking.lengthTd*6 >= booking.category.length">
                              <template v-if="time.booking.length && time.booking.indexOf(booking.index) >= 0">
                                <div v-if="moment(time.time, 'HH:mm').add('15', 'minutes').isBetween(moment(booking.start_time, 'HH:mm'), moment(booking.start_time, 'HH:mm').add('15', 'minutes')) ||
                                  moment(time.time, 'HH:mm').add('15', 'minutes').isSame(moment(booking.start_time, 'HH:mm')) ||
                                  moment(time.time, 'HH:mm').add('15', 'minutes').isSame(moment(booking.start_time, 'HH:mm').add('15', 'minutes'))"
                                     :class="booking.status === constant.BOOKING_STATUS.Pending ? 'booking-pending' : 'booking-approved'"
                                     class="head-booking tool"
                                     :style="moment(time.time, 'HH:mm') !== '23:45' ? 'width:' + ((booking.lengthTd-1)*119 -12) + 'px' : 'width: 98px'"
                                     @click="openPopupEdit(booking.id)"
                                >
                                  <template>{{booking.category}}<span v-if="booking.combos.length > 0  && booking.category"> 、コース</span><span v-if="booking.combos.length > 0  && !booking.category">コース</span><span v-if="booking.type === constant.BOOKING_TYPE.New" class="text-danger">( * )</span></template>
                                </div>
                              </template>
                            </el-tooltip>
                          </td>
                        </template>
                      </tr>
                    </table>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane :label="'週'" :name="'2'">
                <div class="container-calendar">
                  <table class="calendar">
                    <el-row :gutter="20" class="ml-4 width-max-content">
                      未承認数: <b class="color-pending">{{totalPending}}</b>件
                      <span style="margin-left: 68px;">承認済み数: <b class="color-approved">{{totalApproved}}</b>件</span>
                    </el-row>
                    <tr class="weekdays tr-top">
                      <template v-for="(days, index) in listWeekly" >
                        <th scope="col" :key="index" v-if="index < 7">{{days.day}}</th>
                      </template>
                    </tr>
                    <tr>
                      <template v-for="(days, index) in listWeekly" >
                        <td class="day" v-if="index < 7" :key="index" :class="days.working_store === 'day off' ? 'stop-store' : ''" @click="setWorkingHour(days)">
                          <template v-if="days.working_store !== 'day off'">
                            <div class="text-center">
                              <div><b class="color-pending">{{days.pending}}</b>件</div>
                              <div><b class="color-approved">{{days.approved}}</b>件</div>
                            </div>
                            <div class="event text-center">
                              <div class="event-desc">
                                営業時間
                              </div>
                              <div class="event-time">
                                {{days.working_store.work_start_time ? moment(days.working_store.work_start_time, 'HH:mm:ss').format('HH:mm')  : '10:00'}} ~ {{days.working_store.work_end_time ? moment(days.working_store.work_end_time, 'HH:mm:ss').format('HH:mm') : '21:00'}}
                              </div>
                            </div>
                          </template>
                        </td>
                      </template>
                    </tr>
                    <tr class="weekdays">
                      <template v-for="(days, index) in listWeekly" >
                        <th scope="col" :key="index" v-if="index > 6">{{days.day}}</th>
                      </template>
                    </tr>
                    <tr>
                      <template v-for="(days, index) in listWeekly" >
                        <td class="day" v-if="index > 6" :key="index" :class="days.working_store === 'day off' ? 'stop-store' : ''" @click="setWorkingHour(days)">
                          <template v-if="days.working_store !== 'day off'">
                            <div class="text-center">
                              <div><b class="color-pending">{{days.pending}}</b>件</div>
                              <div><b class="color-approved">{{days.approved}}</b>件</div>
                            </div>
                            <div class="event text-center">
                              <div class="event-desc">
                                営業時間
                              </div>
                              <div class="event-time">
                                {{days.working_store.work_start_time ? moment(days.working_store.work_start_time, 'HH:mm:ss').format('HH:mm')  : '10:00'}} ~ {{days.working_store.work_end_time ? moment(days.working_store.work_end_time, 'HH:mm:ss').format('HH:mm') : '21:00'}}
                              </div>
                            </div>
                          </template>
                        </td>
                      </template>
                    </tr>
                  </table>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-row>
        </div>
      </el-form>
    </el-card>
    <popup-create-booking
      @close-dialog="closeDialogCreate"
      @get-list="reloadData()"
      :onDialog="createBooking"
      :store="listDaily.store"
      :id="idBooking"
      :date="this.dateDaily"
    >
    </popup-create-booking>
    <popup-block-hour
      @close-dialog="closeDialogBlockHour"
      :date="this.dateDaily"
      :titleDate="this.titleDate"
      :startHour="startHour"
      :flag="this.flag"
      :onDialog="createBlockHour"
      @get-list="reloadData()"
    >
    </popup-block-hour>
    <popup-set-time-working
      :onDialog="setTimeWorking"
      @close-dialog="closeDialogSetWorkingTime"
      :date="date"
      :store="listDaily.store"
      @get-list="reloadData()"
      :flag="flagEditWookingTime"
    >
    </popup-set-time-working>
  </div>
</template>

<script>
import moment from 'moment';
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin';
import { BookingService } from '@/services/booking.service';
import { PaymentService } from '@/services/payment.service'
import * as CONSTANT from '@/config/constant';
import popupCreateBooking from '@/pages/createBooking/Create';
import popupBlockHour from '@/pages/setBlocked/setBlocked';
import popupSetTimeWorking from '@/pages/setTimeWorking/create';
export default {
  data () {
    return {
      editableTabsValue: '1',
      dateFrom: moment().startOf('isoweek').format('YYYY-MM-DD'),
      dateDaily: moment().format('YYYY-MM-DD'),
      listWeekly: [],
      listDaily: {},
      listTimeInDay: [],
      totalApprovedDaily: 0,
      totalApproved: 0,
      totalPendingDaily: 0,
      totalPending: 0,
      listBookingShow: [],
      moment: moment,
      constant: CONSTANT,
      loading: false,
      createBooking: false,
      calculator: {},
      deleteHour: false,
      createBlockHour: false,
      setTimeWorking: false,
      flag: false,
      flagEditWookingTime: false,
      idBooking: '',
      startHour: '',
      date: '',
      titleDate: moment().format('YYYY年MM月DD日')
    }
  },
  mixins: [prepareQueryParamsMixin],
  components: {
    popupCreateBooking,
    popupBlockHour,
    popupSetTimeWorking
  },
  computed: {
    dateTo () {
      return moment(this.dateFrom).add('days', 13).format('YYYY-MM-DD')
    },
    storeId () {
      return this.$route.params.storeId
    }
  },
  watch: {
    'dateFrom' () {
      this.calculatorBookingWeekly(this.dateFrom)
      this.getListWeekly()
    },
    'dateDaily' () {
      this.calculatorBookingWeekly(this.dateDaily)
      this.getListDaily()
      this.getListTimeInDay()
      this.titleDate = moment(this.dateDaily).format('YYYY年MM月DD日');
    },
    'storeId' () {
      this.getListWeekly()
      this.calculatorBookingWeekly(this.dateDaily)
      this.getListDaily()
    },
    'editableTabsValue' () {
      if (this.editableTabsValue === '1') {
        this.calculatorBookingWeekly(this.dateDaily)
      } else {
        this.calculatorBookingWeekly(this.dateFrom)
      }
    }
  },
  created () {
    this.reloadData()
  },
  methods: {
    reloadData () {
      this.getListTimeInDay()
      this.getListWeekly()
      this.calculatorBookingWeekly(this.dateDaily)
      this.getListDaily()
    },
    getListTimeInDay () {
      this.listTimeInDay = []
      let time = moment().format('YYYY-MM-DD')
      const timeNextDay = time + ' ' + this.constant.STORE_END_TIME
      time = time + ' ' + this.constant.STORE_START_TIME
      time = moment(time)
      while (time.isSameOrBefore(moment(timeNextDay))) {
        this.listTimeInDay.push({ time: time.format('HH:mm'), block: false, booking: [], position: null, staff: [] })
        time = time.add('minutes', 15)
      }
    },
    async getListWeekly () {
      try {
        this.loading = true
        this.listWeekly = []
        const request = { start_date: this.dateFrom, end_date: this.dateTo, store_id: this.storeId }
        const listWeekly = await BookingService.getListWeekly(this.prepareQueryParamsMixin(request))
        const self = this
        self.totalPending = 0
        self.totalApproved = 0
        Object.keys(listWeekly.data).forEach((day) => {
          self.totalApproved += listWeekly.data[day].approved
          self.totalPending += listWeekly.data[day].pending
          const data = listWeekly.data[day]
          data.day = moment(day).format('MM/DD')
          switch (moment(day).isoWeekday()) {
            case 1:
              data.day += ' (月)'
              break
            case 2:
              data.day += ' (火)'
              break
            case 3:
              data.day += ' (水)'
              break
            case 4:
              data.day += ' (木)'
              break
            case 5:
              data.day += ' (金)'
              break
            case 6:
              data.day += ' (土)'
              break
            default:
              data.day += ' (日)'
              break
          }
          self.listWeekly.push(data)
        })
        this.loading = false
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        })
        this.loading = false
      }
    },
    async calculatorBookingWeekly (date) {
      try {
        const request = { start_date: date, end_date: this.dateTo, store_id: this.storeId }
        const calculator = await BookingService.calculatorBookingWeekly(this.prepareQueryParamsMixin(request))
        this.calculator = calculator.data
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        })
      }
    },
    /* eslint max-lines-per-function: ["error", 130] */
    async getListDaily () {
      try {
        this.loading = true
        this.listBookingShow = []
        this.listDaily = {}
        const request = { date: this.dateDaily, store_id: this.storeId }
        const listDaily = await BookingService.getListDaily(this.prepareQueryParamsMixin(request))
        this.listDaily = listDaily.data
        const self = this
        self.totalPendingDaily = 0
        self.totalApprovedDaily = 0
        let stt = 1
        const dateDaily = this.dateDaily
        this.listDaily.listBookings.forEach((booking, index) => {
          if (booking.status === CONSTANT.BOOKING_STATUS.Pending) {
            booking.detail.forEach((bookingDetail, key) => {
              let length = 0
              booking.index = stt
              if (moment(bookingDetail.date, 'YYYY/MM/DD').isSame(moment(self.dateDaily, 'YYYY/MM/DD'))) {
                const endDate = moment(dateDaily + ' ' + bookingDetail.start_time, 'YYYY-MM-DD HH:mm').add('minutes', booking.use_time).format('YYYY-MM-DD')
                let end = moment(bookingDetail.start_time, 'HH:mm').add('minutes', booking.use_time).format('HH:mm')
                const endTime = CONSTANT.STORE_END_TIME
                const startTime = CONSTANT.STORE_START_TIME
                if (moment(endTime, 'HH:mm').isBefore(moment(end, 'HH:mm')) || moment(end, 'HH:mm').isBefore(moment(startTime, 'HH:mm')) || !moment(dateDaily).isSame(endDate)) {
                  end = CONSTANT.STORE_END_TIME
                }
                self.listTimeInDay.map(time => {
                  if (moment(time.time, 'HH:mm').isBetween(moment(bookingDetail.start_time, 'HH:mm').subtract(15, 'minutes'), moment(end, 'HH:mm')) ||
                    moment(time.time, 'HH:mm').isSame(moment(bookingDetail.start_time, 'HH:mm')) ||
                    moment(time.time, 'HH:mm').isSame(moment(end, 'HH:mm'))
                  ) {
                    length++
                    time.booking.push(booking.index)
                  }
                  return time
                })
                const category = booking.services.filter(service => {
                  return service.category
                }).map((service) => {
                  return service.category.name
                }).filter((service, index, self) => {
                  return self.indexOf(service) === index;
                })
                self.listBookingShow.push({ ...booking, ...{ index: stt, start_time: bookingDetail.start_time, lengthTd: length, category: category.join(' 、') + `(ご希望時間 ${key + 1})`, name: booking.user ? (booking.user.name_kanji ? booking.user.name_kanji : booking.user.name) : '' } })
                stt += 1
              }
            })
            self.totalPendingDaily++
          }
          if (booking.status === CONSTANT.BOOKING_STATUS.Approved) {
            booking.index = stt
            let length = 0
            const endDate = moment(dateDaily + ' ' + booking.start_time, 'YYYY-MM-DD HH:mm').add('minutes', booking.use_time).format('YYYY-MM-DD')
            let end = moment(booking.start_time, 'HH:mm').add('minutes', booking.use_time).format('HH:mm')
            const endTime = CONSTANT.STORE_END_TIME
            const startTime = CONSTANT.STORE_START_TIME
            if (moment(endTime, 'HH:mm').isBefore(moment(end, 'HH:mm')) || moment(end, 'HH:mm').isBefore(moment(startTime, 'HH:mm')) || !moment(dateDaily).isSame(endDate)) {
              end = CONSTANT.STORE_END_TIME
            }
            self.listTimeInDay.map(time => {
              if (moment(time.time, 'HH:mm').isBetween(moment(booking.start_time, 'HH:mm').subtract(15, 'minutes'), moment(end, 'HH:mm')) ||
                moment(time.time, 'HH:mm').isSame(moment(booking.start_time, 'HH:mm')) ||
                moment(time.time, 'HH:mm').isSame(moment(end, 'HH:mm'))
              ) {
                length++
                time.booking.push(booking.index)
              }
              return time
            })
            const category = booking.services.filter(service => {
              return service.category
            }).map((service) => {
              return service.category.name
            }).filter((service, index, self) => {
              return self.indexOf(service) === index;
            })
            self.listBookingShow.push({ ...booking, ...{ index: stt, lengthTd: length, category: category.join(' 、'), name: booking.user.name_kanji ? booking.user.name_kanji : booking.user.name } })
            stt += 1
            self.totalApprovedDaily++
          }
        })
        if (this.listDaily.blockHours && this.listDaily.blockHours.length > 0) {
          this.listDaily.blockHours.forEach(block => {
            self.listTimeInDay.map(time => {
              if (moment(time.time, 'HH:mm').isBetween(moment(block.star_hour, 'HH:mm'), moment(block.end_hour, 'HH:mm')) ||
                moment(time.time, 'HH:mm').isSame(moment(block.star_hour, 'HH:mm'))
              ) {
                time.block = true
              }
              return time
            })
          })
        } else {
          self.listTimeInDay.map(time => {
            time.block = false
            return time
          })
        }
        if (this.listDaily.staffSchedules && this.listDaily.staffSchedules.length > 0) {
          this.listDaily.staffSchedules.forEach(staff => {
            staff.shifts.forEach(shift => {
              self.listTimeInDay.map(time => {
                if (moment(time.time, 'HH:mm').isBetween(moment(shift.start_time, 'HH:mm'), moment(shift.end_time, 'HH:mm')) ||
                  moment(time.time, 'HH:mm').isSame(moment(shift.start_time, 'HH:mm')) ||
                  moment(time.time, 'HH:mm').isSame(moment(shift.end_time, 'HH:mm'))
                ) {
                  time.staff.push(staff.user_id)
                }
                return time
              })
            })
          })
        }
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        })
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    openPopupCreate () {
      this.createBooking = true;
    },
    closeDialogCreate () {
      this.createBooking = false;
      this.idBooking = ''
    },
    openPopupEdit (id) {
      this.idBooking = id.toString()
      this.createBooking = true;
    },
    prevWeek () {
      this.dateFrom = moment(this.dateFrom).subtract(7, 'days').format('YYYY-MM-DD')
    },
    nextWeek () {
      this.dateFrom = moment(this.dateFrom).add(7, 'days').format('YYYY-MM-DD')
    },
    prevDay () {
      this.dateDaily = moment(this.dateDaily).subtract(1, 'days').format('YYYY-MM-DD')
      this.titleDate = moment(this.dateDaily).format('YYYY年MM月DD日');
      this.getListTimeInDay()
    },
    nextDay () {
      this.dateDaily = moment(this.dateDaily).add(1, 'days').format('YYYY-MM-DD')
      this.titleDate = moment(this.dateDaily).format('YYYY年MM月DD日');
      this.getListTimeInDay()
    },
    setHour (time, flag) {
      this.createBlockHour = true;
      this.startHour = time.time;
      this.flag = flag;
    },
    closeDialogBlockHour () {
      this.createBlockHour = false;
      this.startHour = ''
      this.flag = false
    },
    setWorkingHour (day) {
      const date = day.day.slice(0, 5)
      const year = moment(this.dateFrom).format('YYYY')
      const time = (date + '/' + year)
      this.date = moment(time, 'MM/DD/YYYY').format('YYYY-MM-DD')
      if ((day.working_store === 'day off') || day.working_store.work_end_time) {
        this.flagEditWookingTime = true
      }
      this.setTimeWorking = true
    },
    closeDialogSetWorkingTime () {
      this.setTimeWorking = false
      this.flagEditWookingTime = false
    },
    toggleClass (e) {
      let index = 1
      let issetClass = true
      let dom = e.target
      while (index <= 5 && issetClass) {
        if (dom.classList.contains('calendar-normal')) {
          issetClass = false
          dom.classList.toggle('calendar-normal-background')
        } else {
          dom = dom.parentElement
        }
        index++
      }
    },
    async exportCsv () {
      try {
        this.loading = true
        const data = await PaymentService.getDataToExport({ date: this.dateDaily, store_id: this.storeId })
        const blob = new Blob([data.data], { type: 'text/csv;charset=Shift_JIS' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = CONSTANT.STORE[this.storeId] + '_予約履歴_' + moment(this.dateDaily).format('yyyyMM') + '.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  font-size: 14px;
}
.add-booking {
  font-size: 18px;
  font-weight: bolder;
}
i {
  font-size: 34px;
  vertical-align: middle;
  color: rgba(21,0,255,255);
  cursor: pointer;
}
td, tr {
  cursor: pointer;
}
.calendar-daily {
  padding: 20px;
}
.lable-text {
  color: Red;
  margin-top: 20px;
}
.lable-text-booking_notes {
  color: Red;
  margin-top: 10px
}
.pl-15 {
  padding-left: 15px;
}
.h-text {
  padding-top: 10px;
}
.input-burlywood {
  height: 40px;
  background-color: #facd91;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.input-green {
  background-color: #95f204;
  height: 40px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.input-blue {
  height: 40px;
  background-color: rgb(23, 170, 228);
}
.input-gray {
  height: 40px;
  background-color: rgb(157, 170, 155);
}
.container-calendar {
  overflow-x: scroll;
}
.calendar-detail {
  //position: relative;
}
.choose-date {
  position: absolute;
  z-index: 1;
  margin-top: 8px;
}
.calendar {
  width: 1300px;
  overflow-y: scroll;
  max-height: 640px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar tr, .calendar tbody {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
}

.daily {
  width: max-content;
  grid-template-columns: repeat(46, 1fr);
}

.daily tr, .daily tbody {
  grid-template-columns: repeat(46, 1fr);
}

caption {
  text-align: center;
  grid-column: 1 / -1;
  font-size: 130%;
  font-weight: bold;
  padding: 10px 0;
}

.calendar a {
  color: rgba(3,125,180,255);
  text-decoration: none;
}

.calendar td, .calendar th {
  padding: 5px;
  box-sizing:border-box;
  border: 1px solid #ccc;
}

.weekdays {
  color: #fff;
}

.calendar .weekdays {
  background: rgba(3,125,180,255);
}
.calendar .weekdays th {
  text-align: center;
  text-transform: uppercase;
  line-height: 20px;
  padding: 10px 6px;
  font-size: 13px;
}

.calendar td {
  min-height: 156px;
  display: flex;
  flex-direction: column;
}

.daily td,.daily th {
  height: 40px;
  min-height: 40px;
  width: 119px;
}

.calendar .days li:hover {
  background: #d3d3d3;
}

.calendar .date {
  text-align: center;
  margin-bottom: 5px;
  padding: 4px;
  background: #333;
  color: #fff;
  width: 30px;
  border-radius: 50%;
  flex: 0 0 auto;
  align-self: flex-end;
}

.calendar .event {
  flex: 0 0 auto;
  font-size: 13px;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 5px;
  line-height: 14px;
  background: #e4f2f2;
  border: 1px solid #b5dbdc;
  color: #009aaf;
  text-decoration: none;
}

.calendar .event-desc {
  color: #666;
  margin: 3px 0 7px 0;
  text-decoration: none;
}

.calendar .other-month {
  background: #f5f5f5;
  color: #666;
}
@media(max-width: 768px) {

  .calendar .weekdays, .calendar .other-month {
    display: none;
  }

  .calendar li {
    height: auto !important;
    border: 1px solid #ededed;
    width: 100%;
    padding: 10px;
    margin-bottom: -1px;
  }

  .calendar, .calendar tr, .calendar tbody {
    grid-template-columns: 1fr;
  }

  .calendar  tr {
    grid-column: 1 / 2;
  }

  .calendar .date {
    align-self: flex-start;
  }
}

tr .stop-store {
  background-color: darkgray;
}
tr .text-center {
  font-weight: bolder;
}
tr .text-center div {
  padding: 5px 5px;
}
.color-pending {
  font-weight: bolder;
  padding: 0px 5px;
  font-size: 16px;
  color: red;
}
.color-approved {
  font-weight: bolder;
  padding: 0px 5px;
  font-size: 16px;
  color: blue;
}
.block-time {
  background-color: rgb(157, 170, 155);
}
.daily .position-relative {
  div {
    position: absolute;
    width: 100px;
    height: 28px;
    z-index: 1;
  }
  .head-booking {
    line-height: 27px;
    color: deepskyblue;
    text-align: center;

    border-radius: 0 15px 15px 0;
  }
  .booking-approved {
    background-color: #95f204;
  }
  .booking-pending {
    background-color: #facd91;
  }
}
.first-column {
  color: black!important;
  background-color: rgba(94,255,255,255);
}
.daily .weekdays {
  background: white;
  color: black!important;
}
.staff-work {
  background-color: #05a7f0;
}
.tool{
  text-overflow: ellipsis;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
}
.tr-top{
  margin-top: 20px;
}
.pl-25{
  padding-left: 25px;
}
.head-booking{
  overflow: hidden;
}
::v-deep .el-icon-circle-close {
  display: none;
}
.title-weekdays {
  height: unset;
  overflow: unset;
}
.calendar-normal:hover {
  background-color: #dfe2e6;
}
.calendar-normal-background {
  background-color: #dfe2e6;
}
</style>
