<template>
  <h3 class="md-title">
    <el-breadcrumb separator="/">
      <template v-for="(breadcrumb, i) in breadcrumbs">
        <el-breadcrumb-item  v-if="breadcrumb.route" :to="breadcrumb.route" :key="`${i}`">{{
          breadcrumb.title
        }}</el-breadcrumb-item>
         <el-breadcrumb-item  v-else :key="`${i}`">{{
          breadcrumb.title
        }}</el-breadcrumb-item>
      </template>
        </el-breadcrumb>

  </h3>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['breadcrumbs'])
  }
};
</script>
