<template>
  <div>
    <el-card class="box-card">
      <el-container style="margin-bottom: 20px">
        <el-row type="flex" justify="start">
          <el-button type="primary" plain @click="editStatusUnread" class="button-status" >未読</el-button>
          <el-button type="info" @click="editStatusRead">既読</el-button>
        </el-row>
      </el-container>

      <el-table
        ref="table"
        :data="pagedListNotification"
        :header-row-class-name="'background-table'"
        style="width: 100%"
        fit
        border
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
        :row-key="getRowKey"
      >
        <el-table-column label="担当名を記入" type="selection" :reserve-selection="true" align="center"  width="100px"></el-table-column>
        <el-table-column label="タイプ" align="center" :resizable="false">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 1" style="color: rgb(103, 193, 57)">
              新規予約
            </span>
            <span v-else-if="scope.row.type === 2" style="color:  rgb(204, 0, 0);">予約キャンセル</span>
            <span v-else style="color:  rgb(204, 0, 0);">予約編集</span>
          </template>
        </el-table-column>
        <el-table-column label="内容" width="500" align="center" :resizable="false">
          <div slot-scope="scope" class="content">
            <span v-html="scope.row.content"></span>
          </div>
        </el-table-column>
        <el-table-column label="顧客名" align="center" :resizable="false">
          <template slot-scope="scope">
            <span>
              {{scope.row.user.name_kanji}}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="担当者名" align="center" :resizable="false">
          <template slot-scope="scope">
            <span>
              {{scope.row.name}}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="150" align="center" :resizable="false">
          <template slot-scope="scope">
            <span>
              <el-button :class="(scope.row.status === notification_status.readed) ? 'disable-button' : ''" type="primary" @click="inputName(scope.row)">担当名を記入</el-button>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination :page-size="page_size" :total="list_notification.length" background layout="prev, pager, next"
                       @current-change="setPage" align="right" :current-page.sync="form.page">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog :visible.sync="showDialog" :before-close="handleCloseDialog" :close-on-click-modal="false"
               :closeOnClickModal="false" :show-close="false" width="30%">
      <el-form ref="form" :model='form' label-width="60px" label-position="left">
        <el-form-item label="担当者" prop="name" >
          <el-input v-model="form.name" maxlength="50"></el-input>
        </el-form-item>
          <button-common  v-on:cancleCreateOrCancelEdit="handleCloseDialog" v-on:submitCreateOrCancleEdit="editResponsibleName" type="create-edit" btnOk="保存" btnCancel="キャンセル">
          </button-common>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { NotificationService } from '@/services/notification.service'
import { LIMIT_PAGINATE, NOTIFICATION_STATUS } from '../../config/constant';
export default {
  data () {
    return {
      showDialog: false,
      list_notification: [],
      form: {
        name: ''
      },
      checked_items: [],
      queryId: '',
      page_size: LIMIT_PAGINATE,
      page: 1,
      unread_notification: 0,
      notification_status: NOTIFICATION_STATUS
    }
  },
  computed: {
    pagedListNotification () {
      return this.list_notification.slice(this.page_size * this.page - this.page_size, this.page_size * this.page)
    },

    checkedIds () {
      const ids = []
      this.checked_items.forEach(notification => {
        ids.push(notification.id)
      })
      return ids
    }
  },
  created () {
    this.getListNotification()
    this.getUserName()
  },
  methods: {
    tableRowClassName ({ row, rowIndex }) {
      if (row.status === 1) {
        return 'read-notify'
      }
      return ''
    },
    getRowKey (row) {
      return row.id
    },
    setPage (val) {
      this.page = val
    },
    handleCloseDialog () {
      this.form.name = ''
      this.showDialog = false
    },
    handleSelectionChange (val) {
      this.checked_items = val
    },
    async getListNotification () {
      try {
        this.loading = true;
        const { data } = await NotificationService.getListNotification()
        this.unread_notification = 0
        data.data.forEach(notification => {
          notification.user_name = ''
          if (notification.status === NOTIFICATION_STATUS.unread) {
            this.unread_notification += 1;
          }
        })
        this.$store.commit('sidebar/SET_CURRENT_NUMBER_UNREAD', this.unread_notification)

        this.list_notification = data.data

        // push readed notification to the end of list
        this.list_notification.forEach(notification => {
          if (notification.status === 1) {
            this.list_notification.push(this.list_notification.splice(this.list_notification.indexOf(notification), 1)[0])
          }
        })
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$notify.error({
          title: 'Error',
          message: error.message,
          showClose: false
        });
      }
    },
    async getUserName () {
      try {
        const { data } = await NotificationService.getUsers()
        this.list_notification.forEach(notification => {
          data.data.data.forEach(user => {
            if (notification.user_id === user.id) {
              notification.user_name = user.name
            }
          })
        });
        this.loading = false;
      } catch (error) {
        this.$notify.error({
          title: 'Error',
          message: error.message,
          showClose: false
        })
        this.loading = false;
      }
    },
    inputName (row) {
      this.queryId = row.id
      this.showDialog = true
      this.form.name = row.name
      if (this.$refs.form) {
        this.$refs.form.clearValidate(this.form)
      }
    },
    async editResponsibleName () {
      try {
        this.loading = true
        const data = await NotificationService.editNotificationName(this.form, this.queryId)
        if (data.status === 200) {
          this.getListNotification()
          this.showDialog = false
        }
        this.loading = false
        this.$notify.success({
          title: '成功',
          message: '作成できました。',
          showClose: false
        });
        this.form.name = ''
      } catch (error) {
        this.loading = false;
        if (error.code === 422) {
          this.showErrorMessage(error.data, 'form')
        } else {
          this.$notify.error({
            title: 'Error',
            message: error.message,
            showClose: false
          })
        }
      }
    },
    async editStatusRead () {
      if (this.checkedIds.length !== 0) {
        try {
          this.loading = true
          const data = await NotificationService.editStatusRead({ ids: this.checkedIds })
          if (data.status === 200) {
            this.getListNotification()
            this.$refs.table.clearSelection()
          }
          this.loading = false
        } catch (error) {
          this.loading = false;
          this.$notify.error({
            title: 'Errors',
            message: error.message,
            showClose: false
          });
        }
      }
    },
    async editStatusUnread () {
      if (this.checkedIds.length !== 0) {
        try {
          this.loading = true;
          const data = await NotificationService.editStatusUnread({ ids: this.checkedIds })
          if (data.status === 200) {
            this.getListNotification()
            this.$refs.table.clearSelection()
          }
          this.loading = false;
        } catch (error) {
          this.loading = false;
          this.$notify.error({
            title: 'Errors',
            message: error.message,
            showClose: false
          });
        }
      }
    }
  }
}
</script>

<style scoped>
::v-deep .el-table .read-row {
  background: #F2F2F2
}
.button-status{
  width:100px;
  background: transparent !important;
  border-color: #409eff !important;
}
.button-status:hover{
  background: #409EFF !important;
}
.button-status:focus{
  background: #409eff !important;
}
::v-deep .el-table .read-notify{
  color: grey;
  background-color: gainsboro !important;

}
::v-deep .disable-button {
  background-color: grey !important;
  border-color: grey !important;
}
::v-deep .el-ticket-label .el-form-item__content{
  margin: 0 100px !important;
}
.content {
  text-align: left !important;
}
</style>
