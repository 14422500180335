<template>
  <DashboardLayout/>
</template>

<script>
import DashboardLayout from '@/layout/master/Layout/DashboardLayout.vue'

export default {
  name: 'AppLayout',
  components: {
    DashboardLayout
  }
}
</script>
