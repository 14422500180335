import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class StoreService extends BaseService {
  static get entity () {
    return ''
  }

  static async getListStore () {
    try {
      const response = await this.request({ auth: true }).get('store/list', { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getListService () {
    try {
      const response = await this.request({ auth: true }).get('service', { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getListCombo (params) {
    try {
      const response = await this.request({ auth: true }).get(`combo?${params}`, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getListCategory (storeId) {
    try {
      const response = await this.request({ auth: true }).get(`category/list/${storeId}`, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
