import Vue from 'vue'
import Router from 'vue-router'

import {
  initCurrentUserStateMiddleware,
  checkAccessMiddleware,
  setPageTitleMiddleware,
  checkAccessRoleMiddleware
} from './middlewares'
import { routes } from './routes'

Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'nav-item active',
  mode: 'history',
  routes
})

router.beforeEach(initCurrentUserStateMiddleware)
router.beforeEach(checkAccessMiddleware)
router.beforeEach(setPageTitleMiddleware)
router.beforeEach(checkAccessRoleMiddleware)

export default router
