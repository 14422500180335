import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class PaymentService extends BaseService {
  static get entity () {
    return ''
  }

  static async createOrEditPaymentInfo (data, check) {
    try {
      let url = '';
      if (check === true) {
        url = 'payment/create'
      } else {
        url = `payment/edit/${data.payment_id}`
      }
      const response = await this.request({ auth: true }).post(url, data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async showPaymentInfo (id) {
    try {
      const response = await this.request({ auth: true }).get(`payment/show/${id}`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getDataToExport (rq) {
    try {
      const response = await this.request({ auth: true }).get(`payment/export-csv/${rq.store_id}/${rq.date}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
