<template>
  <div v-loading.fullscreen.lock="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div style="width: 60%; float: left">
          <h3>
            <div class="row">
              <div class="col-sm-12">
              <span>
                <router-link :to="{ name: 'service-list'}" style="background-color: #ffffff !important; color: #00b0ff !important;">
                  メニュー管理
                </router-link>
              </span> >{{ name }}
              </div>
            </div>

          </h3>
        </div>
      </div>
      <el-form ref="form" :model="form" label-width="160px" label-position="left">
        <el-form-item label="タイプ" prop="" class="el-label-customer start-required">
          <div><h6>単体</h6></div>
        </el-form-item>

        <el-form-item label="利用店舗名" prop="store_id" class="el-label-customer start-required">
          <div class="el-checkbox-width">
            <el-radio-group v-model="form.store_id">
              <el-radio v-for="(value, index) in store" :key="index"
                        :label="value.id" :value="value.id">{{ value.name }}
              </el-radio>
<!--              <el-radio :label="'null'" :value="'null'">すべて-->
<!--              </el-radio>-->
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item label="非表示のステータス" prop="block" class="el-label-customer start-required">
          <div class="el-checkbox-width">
            <el-radio-group v-model="form.block">
              <el-radio v-for="(value, index) in service_block" :key="index" :label="value.id"
                        :class="value.className">{{ value.name }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="メニューのステータス" prop="status" class="el-label-customer start-required">
          <div class="el-checkbox-width">
            <el-radio-group v-model="form.status">
              <el-radio  v-for="(value, index) in status" :key="index" :class="value.className" :disabled="checkStatus === 1"
                        :label="value.id" :value="value.id">{{ value.name }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item label="表示順" prop="name" class="el-label-customer start-required">
          <el-input-number
            v-model="form.display_number"
            :min="1"
          >
          </el-input-number>
        </el-form-item>

        <el-form-item label="カテゴリー" prop="category_id" class="el-label-customer start-required">
          <el-select v-model="form.category_id" placeholder="選択してください" >
            <el-option
              v-for="item in category"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="メニュー名" prop="name" class="el-label-customer start-required">
          <el-input
            v-model="form.name"
            placeholder="入力してください"
            class="input-width"
            maxlength="256"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="画像" prop="image" class="el-label-customer start-required">
          <div class="row">
            <div class="el-col el-col-24 el-col-md-24">
              <div class="el-col el-col-24 el-col-md-6 text-break-all">
              </div>
              <div class="el-col el-col-8 el-col-md-6">
                <div class="">
                  <input :id="'file-upload'" style="display: none" type="file" @change="onImageChange" ref="fileInput">
                  <label class="file-drag">
                    <div v-if="!form.image" class="start">
                      <div @dragover.prevent @drop="onDrop" style="width: 350px; height: 350px; border: 1px solid" class="d-flex align-items-center justify-content-center">
                        <div>
                          <div class="d-flex justify-content-center">
                            <i class="el-icon-upload icon-upload-service-combo"></i>
                          </div>
                          <div class="description-image-service-combo">ドラッグ＆ドロップしてください。</div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="form.image" class="start">
                      <div class="">
                        <img  @dragover.prevent @drop="onDrop"
                              :src="form.image"
                              class="img-responsive"
                              style="width: 350px; height: 350px"
                        />
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="メニュータイプ" prop="parent" class="el-label-customer start-required">
          <div class="row">
            <div class="col-sm-3">施術時間</div>
            <div class="col-sm-3">料金</div>
            <div class="col-sm-6"></div>
          </div>
          <div v-for="(value, index) in form.process" :key="index" class="row pt-3" style="margin-bottom: 3px">
            <div class="col-sm-3">
              <el-form-item :prop="'process.'+index+'.use_time'">
                <el-input v-model="form.process[index].use_time" maxlength="9" @change="changeNumberType(index, 'use_time')"
                          placeholder="入力してください" class="input-width">
                  <template slot="append">分</template>
                </el-input>
              </el-form-item>
            </div>
            <div class="col-sm-3">
              <el-form-item :prop="'process.'+index+'.price'">
                <el-input v-model="form.process[index].price" @change="changeNumberType(index, 'price')"
                          maxlength="11" placeholder="入力してください" class="input-width">
                  <template slot="append">円</template>
                </el-input>
              </el-form-item>
            </div>
            <div class="col-sm-6">
              <label @click="deleteProcess(index)" v-if="index !== 0" class=" btn btn-danger btn-delete-combo"> X </label>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="form.process.length < 20">
          <el-form-item class="m-btn-add-shift-time pt-4" >
            <el-button type="text" @click="addProcess" class="btn-add-shift-time">
              <i class="el-icon-plus icon-add-tab"></i>追加する
            </el-button>
          </el-form-item>
        </el-form-item>

        <el-form-item label="メニュー内容" prop="content" class="el-label-customer">
          <el-input
            maxlength="256"
            type="textarea"
            v-model="form.content"
            placeholder="入力してください"
            class="input-width"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="メニュー詳細" prop="detail" class="el-label-customer start-required">
          <ckeditor v-model="form.detail" :config="editorConfig"></ckeditor>
        </el-form-item>

        <el-form-item label="レビューのリンク" prop="url" class="el-label-customer">
          <el-row type="flex">
            <el-input v-model="form.url" disabled ref="link" style="width: 95%"></el-input>
            <el-tooltip effect="dark" :content="copyStatus" placement="top">
              <el-button type="primary" @click="copyToClipboard" @mouseover.native="changeCopyStatus" style="margin-left: 5px">コピー</el-button>
            </el-tooltip>
          </el-row>
        </el-form-item>

        <el-form-item>
          <button-common  v-on:cancleCreateOrCancelEdit="$router.push({ name: 'service-list' })" v-on:submitCreateOrCancleEdit="editService" type="create-edit" btnOk="保存" btnCancel="キャンセル">
          </button-common>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import * as CONSTANT from '../../config/constant'
import { ServicesService } from '../../services/services.service';
import { API_URL, USER_URL } from '../../../src/.env.js'

export default {
  name: 'Edit',
  props: {},
  data () {
    return {
      editorConfig: {
        extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',
        language: 'ja',
        allowedContent: true,
        // Adding drag and drop image upload.
        extraPlugins: 'image2',
        uploadUrl: API_URL + '/upload-image',
        filebrowserUploadUrl: API_URL + '/upload-image',
        filebrowserImageUploadUrl: API_URL + '/upload-image',
        image2_disableResizer: false,
        height: 300,
        removeDialogTabs: 'image:advanced;link:advanced',
        removeButtons: 'PasteFromWord'
      },
      dialogImageUrl: '',
      dialogVisible: false,
      loading: false,
      store: [],
      is_new_image: false,
      service_block: CONSTANT.BLOCK,
      status: CONSTANT.STATUS,
      category: [],
      name: '',
      copyStatus: '',
      checkStatus: '',
      form: {
        category_id: null,
        store_id: null,
        block: null,
        status: null,
        name: null,
        image: null,
        code: null,
        imageErr: [],
        process: [
          {
            id: '',
            use_time: '',
            price: ''
          }
        ],
        content: null,
        url: null,
        detail: '',
        display_number: ''
      }

    }
  },
  created () {
    this.getData()
  },
  methods: {
    onImageChange (e) {
      this.is_new_image = true;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0]);
    },

    createImage (file) {
      const reader = new FileReader();
      const vm = this;
      reader.onload = (e) => {
        vm.form.image = e.target.result;
      };
      this.form.image = file;

      reader.readAsDataURL(file);
    },

    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    async getData () {
      try {
        this.loading = true
        const id = this.$route.params.serviceId;
        const { data } = await ServicesService.getServiceDetail(id);
        this.checkStatus = data.service.status
        this.category = data.category;
        this.store = data.store;
        this.form.name = data.service.name
        this.name = data.service.name
        this.form.category_id = data.service.category_id
        this.form.store_id = data.service.store_id
        if (data.service.store_id !== 0) {
          this.form.store_id = data.service.store_id
        } else {
          this.form.store_id = 'null'
        }
        this.form.block = data.service.block
        this.form.status = data.service.status
        this.form.image = data.service.image
        this.form.code = data.service.code
        this.form.content = data.service.content
        this.form.detail = data.service.detail
        this.form.display_number = data.service.display_number
        data.service.process.forEach((value, index) => {
          data.service.process[index].use_time = this.changeNumber(data.service.process[index].use_time)
          data.service.process[index].price = this.changeNumber(data.service.process[index].price)
        })
        this.form.process = data.service.process

        this.form.url = USER_URL + '/service/' + id
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.code === 422) {
          this.showErrorMessage(error.data, 'form');
        }
      }
    },

    async editService () {
      try {
        this.loading = true
        this.$refs.form.clearValidate(this.form)
        // eslint-disable-next-line no-unused-vars
        let image = null
        if (this.is_new_image) {
          if (this.form.image !== '' && this.form.image !== null) {
            image = this.dataURLtoFile(this.form.image, '1.png');
          }
        }
        const id = this.$route.params.serviceId;
        await ServicesService.editService(this.form, image, id);
        this.$notify.success({
          title: '成功',
          message: '更新できました。',
          showClose: false
        });
        this.$router.push({ name: 'service-list' })
      } catch (error) {
        this.loading = false;
        if (error.code === 422) {
          this.showErrorMessage(error.data, 'form');
        } else {
          this.$notify.error({
            title: 'Error',
            message: error.message,
            showClose: false
          });
        }
      }
    },

    deleteProcess (id) {
      this.form.process.splice(id, 1)
    },

    addProcess () {
      const data = {
        id: '',
        use_time: '',
        price: ''
      }
      this.form.process.push(data);
    },

    async copyToClipboard () {
      try {
        await navigator.clipboard.writeText(this.form.url)
        this.copyStatus = 'コピーしました。'
      } catch (error) {
        this.copyStatus = 'コピーできません。'
      }
    },

    changeCopyStatus () {
      this.copyStatus = '押したら、リンクがコピーされます'
    },

    formatNumber (number) {
      return new Intl.NumberFormat('ja-JP').format(number)
    },

    changeNumberType (index, type) {
      this.form.process[index][type] = this.changeNumber(this.form.process[index][type])
    },
    onDrop (e) {
      e.stopPropagation();
      e.preventDefault();
      this.is_new_image = true;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0]);
    }

  }
}
</script>

<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

::v-deep .el-dialog__footer {
  text-align: center;
}

::v-deep .el-dialog__body {
  text-align: center;
}
::v-deep .el-select .el-input{
  width: 320px !important;
}
::v-deep .file-drag{
  width: 100%;
}
.add-process-btn{
  border: none;
  background-color: Transparent;
  color: green;
  width: fit-content;
}
.btn-width {
  width: 200px !important;
}
.el-icon-plus {
  border: 1px solid;
}
::v-deep .button_add{
  border: 1px solid rgb(114, 255, 114);
  padding: 0 2px;
  margin-right: 5px;
  font-size: 13px;
  border-radius: 5px;
}
.cancel_button,
.save_button {
  width: 150px;
}
</style>
