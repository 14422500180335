<template>
  <div :class="{ 'nav-open': $sidebar.showSidebar }" class="wrapper">
    <side-bar
      :sidebar-background-image="sidebarBackgroundImage"
      :sidebar-item-color="sidebarBackground"
    >
        <sidebar-link :to="{name: 'list-customer'}">
        <md-icon>person</md-icon>
        <p>List Customer</p>
        </sidebar-link>

        <sidebar-link :to="{name: 'service-list'}">
        <md-icon>abc</md-icon>
        <p>Service</p>
        </sidebar-link>

        <sidebar-link :to="{name: 'staff-list'}">
        <md-icon>abc</md-icon>
        <p>Staff</p>
        </sidebar-link>

        <sidebar-link :to="{name: 'ticket'}">
        <md-icon>Ticket</md-icon>
        <p>Ticket</p>
        </sidebar-link>

        <sidebar-link :to="{name: 'policy'}">
          <md-icon>abc</md-icon>
          <p>policy</p>
        </sidebar-link>
         <sidebar-link :to="{name: 'set-blocked'}">
          <md-icon>abc</md-icon>
          <p>Set blocked</p>
        </sidebar-link>
        <!-- <sidebar-link :to="{name: 'campaign'}">
          <p>キャンペーン一覧</p>
        </sidebar-link> -->
        <sidebar-link :to="{name: 'master-calendar'}">
          <p>Booking calendar</p>
        </sidebar-link>
          <sidebar-link :to="{name: 'create-booking'}">
          <p>Create Booking</p>
          </sidebar-link>
        <sidebar-link :to="{name: 'notification'}">
          <p>お知らせ管理</p>
        </sidebar-link>
    </side-bar>
    <div class="main-panel">
      <top-navbar @clickLogout="clickLogout"></top-navbar>
      <div class="content">
        <div class="md-layout">
          <div class="md-layout-item">
            <dashboard-content></dashboard-content>
          </div>
        </div>
      </div>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
    <el-dialog
      title="警報"
      :visible.sync="dialogLogout"
      width="30%"
      :close-on-click-modal="false"
    >
      <span>ログアウトしてもよろしいですか？</span>
      <span slot="footer" class="dialog-footer">
      <el-button @click="dialogLogout = false">キャンセル</el-button>
      <el-button type="primary" @click="logout">確認</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import { AuthService } from '@/services/auth.service';

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter
  },
  data () {
    return {
      sidebarBackground: 'green',
      sidebarBackgroundImage: require('@/assets/img/sidebar-2.jpg'),
      dialogLogout: false
    };
  },
  methods: {
    clickLogout () {
      this.dialogLogout = true
    },

    logout () {
      AuthService.makeLogout();
    }
  }
};
</script>
<style lang="css" scoped>
.md-card {
  min-height: 100vh;
}
.main-panel > .content {
  margin-top: 70px;
}
</style>
