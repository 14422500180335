export const SOCIAL_TYPE = {
  1: 'Google',
  2: 'Apple',
  3: 'Facebook',
  4: 'Twitter',
  5: 'LINE',
  6: 'Mail'
}

export const STATUS_CODE = {
  AUTHENTICATE: 401,
  ERROR_SERVER: 500,
  BAD_REQUEST: 422,
  SUCCESS: 200,
  NOT_FOUND: 404,
  EXPIRED: 400
}

export const LIMIT_PAGINATE = 20

export const SATUS_CONTRACT = [
  {
    label: '白井店',
    value: 1
  },
  {
    label: ' 浅草店',
    value: 2
  }
]
export const GENDER = [
  {
    label: '男性',
    value: 1
  },
  {
    label: ' 女性',
    value: 2
  }
]
export const STATUS = [
  {
    name: '公開中',
    id: 1,
    className: 'blue-text'
  },
  // public
  {
    name: '下書き',
    id: 0,
    className: 'yellow-text'
  }
  // draft
]

export const BLOCK = [
  {
    name: '表示',
    id: 0,
    className: 'green-text'
  },
  // unblocked
  // ブロック対象
  {
    name: '非表示',
    id: 1,
    className: 'red-text'
  }
  // blocked
  // ブロック対象外
]

export const SERVICE_COMBO_TYPE = [
  {
    name: '単体',
    id: 1
  },
  // service
  // サービス
  {
    name: 'コース',
    id: 2
  },
  // combo
  // コンボ
  {
    name: 'すべて',
    id: 3
  }
  // すべて
  // all
]

export const TICKIT_STATUS = [
  {
    label: '利用中',
    value: 1
  },
  {
    label: '利用済み',
    value: 2
  },
  {
    label: '期限切り',
    value: 3
  }
]

export const SERVICE_COMBO = [
  {
    name: '単体',
    id: 1
  },
  // service
  // サービス
  {
    name: 'コース',
    id: 2
  }
  // combo
  // コンボ
]
export const LOOP_HOUR = [
  {
    label: '日',
    value: 1
  },
  {
    label: '週',
    value: 2
  },
  {
    label: '月',
    value: 3
  }
]
export const LOOP_TYPE = [
  {
    label: '日',
    value: 1
  },
  {
    label: '週',
    value: 2
  },
  {
    label: '月',
    value: 3
  },
  {
    label: '月末',
    value: 4
  }
]
export const LOOP_STATUS = {
  no_loop: 0,
  loop: 1
}
export const CHOSE_SERVICE = [
  {
    label: 'サービス 1',
    id: 1
  },
  {
    label: 'サービス 2',
    id: 2
  },
  {
    label: 'サービス 3',
    id: 3
  },
  {
    label: 'サービス 4',
    id: 4
  },
  {
    label: 'サービス 5',
    id: 5
  }
]

export const BOOKING_STATUS = {
  Pending: 1,
  Approved: 2,
  Cancel: 3
}

export const BOOKING_TYPE = {
  New: 0,
  Old: 1
}

export const SEND_CONTACT = [
  {
    label: 'LINE',
    value: 0
  },
  {
    label: 'メールアドレス',
    value: 1
  }
]
export const PAYMENT_METHOD = [
  {
    value: 1,
    label: 'Pay Pay'
  },
  {
    value: 2,
    label: 'Credit Card'
  },
  {
    value: 3,
    label: '銀行振込'
  }
]

export const STORE_START_TIME = '10:00'
export const STORE_END_TIME = '21:00'

export const STAFF_SCHEDULE_STATUS = [
  {
    value: 0,
    label: 'staff off'
  },
  {
    value: 1,
    label: 'staff working'
  }
]
export const ERRORS_IMAGES = {
  Capacity: 'ファイルサイズ容量を超えております。',
  Mimestype: 'ファイルフォーマットが間違っています。'
}
export const CAMPAIGN_STATUS = {
  PRIVATE: 0,
  PUBLIC: 1
};

export const NOTIFICATION_STATUS = {
  unread: 0,
  readed: 1
};

export const CUSTOMER_TYPE = [
  {
    value: 0,
    label: '新規の方'
  },
  {
    value: 1,
    label: '2回目以降の方'
  }
]
export const SET_LOOP = [
  {
    value: 1,
    label: 'この回のみ'
  },
  {
    value: 0,
    label: '一括'
  }
]

export const STORE = {
  1: '白井店',
  2: '浅草店'
}

export const REVIEW_STATUS = {
  1: '公式 Webへ反映する',
  2: '公式 Webへ反时映しない'
}
export const REVIEW_AGE = [
  {
    value: '10',
    label: '10代'
  },
  {
    value: '20',
    label: '20代'
  },
  {
    value: '30',
    label: '30代'
  },
  {
    value: '40',
    label: '40代'
  },
  {
    value: '50',
    label: '50代'
  },
  {
    value: '60',
    label: '60代'
  },
  {
    value: '70',
    label: '70代'
  },
  {
    value: '80',
    label: '80代'
  },
  {
    value: '90',
    label: '90代'
  },
  {
    value: '100',
    label: '100代'
  }
]

export const MAX_SIZE_FILE = 10240

export const PAYMENT_METHOD_SHOPPING = [
  {
    value: 1,
    label: 'Pay Pay'
  },
  {
    value: 2,
    label: 'Credit Card'
  },
  {
    value: 3,
    label: '銀行振込'
  },
  {
    value: 4,
    label: '現金'
  },
  {
    value: 5,
    label: 'その他'
  }
]
