<template>
  <div>
    <div class="row" v-if="type == 'list'">
      <div class="d-flex justify-content-center">
        <div class="btn-custommer-reset">
          <el-button icon="el-icon-refresh-left" @click="$emit('cancle')">{{ btnCancel }}</el-button>
        </div>
        <div style="width: 50px"></div>
        <div>
          <el-button icon="el-icon-search" type="primary" class="btn-common-submit-form" @click="$emit('submit')">{{ btnOk }}</el-button>
        </div>
      </div>
    </div>
    <div v-if="type == 'confirm'">
      <div class="card-toolbar pt-2" style="text-align: right; margin-bottom: 10px">
      <div class="dropdown dropdown-inline mr-2 ">
        <el-button class="btn-delete-customer" type="danger" @click="$emit('cancleConfirm')" icon="el-icon-delete">{{ btnCancel }}</el-button>
        <el-button type="success" class="btn-common-submit-form" @click="$emit('submitConfirm')" icon="el-icon-plus">{{ btnOk }}</el-button>
      </div>
      </div>
    </div>
    <div v-if="type == 'create-edit'">
      <div class="row mt-4">
        <div class="col-4 offset-4">
          <div class="d-flex ">
            <div class="el-cusomer-reset-register">
              <el-button @click="$emit('cancleCreateOrCancelEdit')">{{ btnCancel }}</el-button>
            </div>
            <div class="el-cusomer-search-register">
              <el-button :disabled="checkMultiClick" class="bg-primary btn-common-submit-form" @click="$emit('submitCreateOrCancleEdit')">{{ btnOk }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    btnOk: {
      type: String,
      default: 'リセット'
    },
    btnCancel: {
      type: String,
      default: '検索'
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
    showOkButton: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'list'
    },
    checkMultiClick: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.btn_submit_dialog {
  font-family: kzk_bold;
  color: #fff;
  background: #FC9220;
}
</style>
