<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <h3>
          <div class="row">
            <div class="col-sm-6">
              <span>
                <router-link :to="{ name: 'staff-list'}" style="background-color: #ffffff !important; color: #00b0ff !important;">
                  スタッフリスト/
                </router-link>
              </span>スタッフ情報編集
            </div>
            <div class="col-sm-6">
              <el-button type="primary" style="float: right" @click="$router.push({name: 'shift-calendar', params: {staffId: form.id}})">シフトカレンダー</el-button>
            </div>
          </div>
        </h3>
      </div>
      <el-form :model="form" ref="form" label-width="150px" class="demo-form mb-5" label-position="left">

        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer" label="店舗" prop="store_id">
              <el-select class="w--100 bg-gray-item" v-model="form.store_id"
                         placeholder="選択してください">
                <el-option
                  v-for="(item) in storeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer start-required" label="氏名" prop="name">
              <el-input maxlength="200" placeholder="氏名を入力してください" class="" v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer" label="性別" prop="gender">
              <div class="el-checkbox-width">
                <el-radio-group v-model="form.gender">
                  <el-radio v-for="gender in constants.GENDER" :key="gender.value" :label="gender.value+''">{{ gender.label }}</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer" label="生年月日" prop="birthday">
              <el-date-picker
                v-model="form.birthday"
                type="date"
                format="yyyy/MM/dd"
                value-format="yyyy-MM-dd"
                placeholder="生年月日を入力してください"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer start-required" label="電話番号" prop="phone">
              <el-input maxlength="20" placeholder="半角数字を入力してください" v-model="form.phone"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer start-required" label="メールアドレス" prop="email">
              <el-input maxlength="100" placeholder="メールアドレスを入力してください" v-model="form.email"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>

        <button-common  v-on:cancleCreateOrCancelEdit="handleClose" v-on:submitCreateOrCancleEdit="editStaff" type="create-edit" btnOk="更新" btnCancel="キャンセル">
        </button-common>

      </el-form>

    </el-card>
  </div>
</template>

<script>
import * as constants from '@/config/constant';
import { StaffService } from '@/services/staff.service';
import { StoreService } from '@/services/store.service';

export default {
  name: 'Edit',
  data () {
    return {
      form: {
        name: '',
        gender: '1',
        birthday: '',
        phone: '',
        email: '',
        store_id: ''
      },
      storeList: [],
      constants: constants
    }
  },
  created () {
    this.getListStore();
    this.fetchData();
  },
  methods: {
    handleClose () {
      this.$router.push({ name: 'staff-list' })
    },
    resetForm (formName) {
      this.form.contract = ''
      this.$refs[formName].resetFields();
    },
    async fetchData () {
      this.loading = true;
      const id = this.$route.params.staffId;
      try {
        const { data } = await StaffService.getStaffDetail(id);
        this.form = data
      } catch (e) {
        this.$notify.error({
          title: 'Error',
          message: e.message,
          showClose: false
        });
      } finally {
        this.loading = false;
      }
    },

    async getListStore () {
      this.loading = true;
      try {
        const { data } = await StoreService.getListStore()
        this.storeList = data.data;
      } catch (e) {
        alert(e.message)
      } finally {
        this.loading = false;
      }
    },

    async editStaff () {
      try {
        this.$refs.form.clearValidate(this.form)
        this.loading = true;
        const staffData = {};
        staffData.phone = this.form.phone;
        staffData.email = this.form.email;
        staffData.store_id = this.form.store_id;
        staffData.name = this.form.name;
        staffData.birthday = this.form.birthday;
        if (this.form.gender != null && this.form.gender !== '') {
          staffData.gender = this.form.gender;
        }
        const id = this.$route.params.staffId;

        await StaffService.editStaff(staffData, id);
        this.$notify.success({
          title: '成功',
          message: '更新できました。',
          showClose: false
        });
        this.loading = false;
        this.$router.push({ name: 'staff-list' })
      } catch (error) {
        this.loading = false;
        if (error.code === 422) {
          this.showErrorMessage(error.data, 'form');
        } else {
          this.$notify.error({
            title: 'Error',
            message: error.message,
            showClose: false
          });
        }
      }
    }
  }
}
</script>

<style scoped>
.w--100{
  width: 100%;
}
.file-upload-btn{
  text-decoration: underline;
  color: darkcyan;
}
</style>
