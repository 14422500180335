<template>
  <div v-loading="loading">
    <div>
      <el-card class="box-card" >
        <div slot="header" class="clearfix">
          <h3 style="font-weight: bold">お客様の声</h3>
        </div>
        <div class="mb-4">
          <div class="d-flex">
            <lable style="padding-right: 50px;font-size: 20px">ステータス</lable>
            <el-checkbox-group v-model="checkedStatus" @change="handleCheckedStatus">
              <el-checkbox v-for="(value, index) in options" :label="index" :key="index">{{value}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div v-if="showErrDelete === true" class="mandatory" style="width: 100% !important;"> 削除するアカウントを選択してください。</div>
        <div>{{listReview.total}}件</div>
        <el-table
          ref="multipleTable"
          :data="listReview.data"
          :fit="true"
          :header-row-class-name="'background-table'"
          border
          style="width: 100%"
        >
          <el-table-column :resizable="false" label="No."  align="center" width="85px">
            <template slot-scope="scope" >
              {{ scope.row.id }}
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="ステータス" width="200px" align="center">
            <template slot-scope="scope" >
              <span class="font-bold active" v-if=" scope.row.status == 1">{{ constants.REVIEW_STATUS[1]   }}</span>
              <span class="font-bold not-active" v-else>{{ constants.REVIEW_STATUS[2]   }}</span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="年齢" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.age + '代'}}</span>
            </template>
          </el-table-column>

          <el-table-column :resizable="false" label="性別" align="center">
            <template slot-scope="scope">
              <span v-if=" scope.row.gender == constants.GENDER[0].value">{{ constants.GENDER[0].label }}</span>
              <span v-else>{{ constants.GENDER[1].label }}</span>
            </template>
          </el-table-column>

          <el-table-column :resizable="false" label="利用日" align="center">
            <template slot-scope="scope">
              <span>{{ moment(scope.row.date).format('YYYY/MM/DD')}}</span>
            </template>
          </el-table-column>
        <el-table-column :resizable="false" label="利用メニュー" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.name}}</span>
            </template>
        </el-table-column>
          <el-table-column :resizable="false" label="内容" align="center">
            <template slot-scope="scope">
              <span style="white-space:pre-line">{{ scope.row.content}}</span>
            </template>
        </el-table-column>
          <el-table-column :resizable="false" align="center">
            <template slot-scope="scope">
              <el-button @click="editReview(scope.row.id)" type="primary">編集</el-button>
            </template>
          </el-table-column>

        </el-table>

        <div v-if="listReview.data" style="margin-top: 20px; text-align: right">
          <my-pagination
            :total-record="parseInt(listReview.total)"
            :current-page="parseInt(queryParam.page)"
            @handleCurrentChange="handleCurrentChange"
          />
        </div>
      </el-card>
    </div>
    <EditReview v-on:RegisterOrEditSucess="fetchData"  :idedit="reviewId" :dialog="dialogEdit" v-on:closeDialog="closePoopEdit"></EditReview>
  </div>
</template>
<script>
import { ReviewService } from '@/services/review.service';
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin';
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin';
import EditReview from '@/pages/review/Edit';
import * as constants from '@/config/constant';
import moment from 'moment';
export default {
  components: {
    EditReview
  },
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  data () {
    return {
      moment: moment,
      listReview: [],
      constants: constants,
      loading: false,
      dialogVisible: false,
      checkedStatus: ['1', '2'],
      options: constants.REVIEW_STATUS,
      form: {
        size: constants.LIMIT_PAGINATE,
        page: 1
      },
      showErrDelete: false,
      //
      reviewId: 0,
      dialogEdit: false
    };
  },
  watch: {
    'queryParam.status' () {
      this.checkSTatusActive()
    }
  },
  created () {
    if (this.queryParam.status) {
      this.checkSTatusActive()
    } else {
      this.checkedStatus = ['1', '2']
    }
    this.getDataFromQuery();
    this.fetchData();
  },
  computed: {
    queryParam () {
      return this.$route.query;
    }
  },
  methods: {

    handleCloseDialog () {
      this.dialogVisible = false
    },
    async fetchData () {
      this.loading = true;
      try {
        const { data } = await ReviewService.getList(this.prepareQueryParamsMixin(this.queryParam));
        this.listReview = data;
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      } finally {
        this.loading = false;
      }
    },
    editReview (id) {
      this.reviewId = id
      this.dialogEdit = true
    },
    closePoopEdit () {
      this.dialogEdit = false
      this.reviewId = 0
    },
    handleCheckedStatus (value) {
      if (value.length === 1) {
        this.$router.push({
          query: { status: value[0] }
        }).catch(() => {
        });
      } else if (value.length === 2) {
        this.$router.push({
          query: { status: 3 }
        }).catch(() => {
        });
      } else {
        this.$router.push({
          query: { status: 4 }
        }).catch(() => {
        });
      }
    },
    checkSTatusActive () {
      const status = this.queryParam.status
      if (['1', '2'].includes(status)) {
        this.checkedStatus = [status]
      } else if (status === '3') {
        this.checkedStatus = ['1', '2']
      } else {
        this.checkedStatus = []
      }
    }
  }
}
</script>
<style scoped>
#w-button{
  width: 120px;
  background-color: red;
}
.font-bold {
  font-weight: bold;
}
.not-active {
  color: #F3DAA7;
}
.active {
  color: #89BCD5;
}
</style>
