/**
 * Jump to some DOM element
 */
export default {
  methods: {
    serialize (obj) {
      const str = [];
      for (const p in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
      }
      return str.join('&');
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    changeNumber (number) {
      if (number != null && number !== '') {
        number = number.toString()
        number = number.replaceAll(',', '')
        const numericalChar = new Set(['.', ',', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);
        const price = number
        number = price.split('').filter(char => numericalChar.has(char)).join('');
        if (number !== '') {
          number = parseFloat(number)
          number = this.formatNumber(number)
        }
      }
      return number;
    },

    formatNumber (number) {
      return new Intl.NumberFormat('ja-JP').format(number)
    },
    converPricetoInt (string) {
      let number = string.toString()
      number = number.replaceAll(',', '');
      return parseInt(number)
    }
  }
}
