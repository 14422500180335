import axios from 'axios';

import { Http } from './http.init';
import { ResponseWrapper, ErrorWrapper } from './util';
import $store from '../store';
import $router from '../router';

import { API_URL } from '../.env';

let BEARER = null;

export class AuthService {
  /**
   ******************************
   * @API
   ******************************
   */

  static async makeLogin ({ email, password }) {
    try {
      const response = await axios.post(
        `${API_URL}/login`,
        { email, password },
        { withCredentials: false }
      );
      _setAuthData({
        accessToken: response.data.data.token,
        exp: _parseTokenData(response.data.data.token).exp
      });
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async makeLogout () {
    try {
      const response = await new Http({ auth: true }).post(
        'logout',
        {},
        { withCredentials: true }
      );
      _resetAuthData();
      $router.push({ name: 'login' }).catch(() => {});
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async refreshTokens () {
    try {
      const response = await axios.post(
        `${API_URL}/auth/refresh-tokens`,
        {
          fingerprint: ''
        },
        { withCredentials: false }
      );
      console.log(_parseTokenData(response.data.data.accessToken).exp);
      _setAuthData({
        accessToken: response.data.data.accessToken,
        exp: _parseTokenData(response.data.data.accessToken).exp
      });
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      _resetAuthData();
      $router.push({ name: 'login' }).catch(() => {});
      throw new ErrorWrapper(error);
    }
  }

  static debounceRefreshTokens = this._debounce(() => {
    return this.refreshTokens();
  }, 100);

  /**
   ******************************
   * @METHODS
   ******************************
   */

  static isAccessTokenExpired () {
    const accessTokenExpDate = $store.state.auth.accessTokenExpDate - 10;
    const nowTime = Math.floor(new Date().getTime() / 1000);

    return accessTokenExpDate <= nowTime;
  }

  static hasRefreshToken () {
    return Boolean(localStorage.getItem('refreshToken'));
  }

  static hasAccessToken () {
    return Boolean(localStorage.getItem('accessToken'));
  }

  static resetToken () {
    _resetAuthData();
  }

  static setRefreshToken (status) {
    if (!['', 'true'].includes(status)) {
      throw new Error(
        `setRefreshToken: invalid value ${status}; Expect one of ['', 'true']`
      );
    }

    localStorage.setItem('refreshToken', status);
  }

  static setAccessToken (token) {
    localStorage.setItem('accessToken', token);
  }

  static getBearer () {
    if (!BEARER && localStorage.getItem('accessToken')) {
      this.setBearer(localStorage.getItem('accessToken'));
    }
    return BEARER;
  }

  static setBearer (accessToken) {
    BEARER = `Bearer ${accessToken}`;
  }

  /**
   * https://stackoverflow.com/questions/35228052/debounce-function-implemented-with-promises
   * @param inner
   * @param ms
   * @returns {function(...[*]): Promise<unknown>}
   * @private
   */
  static _debounce (inner, ms = 0) {
    let timer = null;
    let resolves = [];

    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        const result = inner();
        resolves.forEach((r) => r(result));
        resolves = [];
      }, ms);

      return new Promise((resolve) => resolves.push(resolve));
    };
  }
}

/**
 ******************************
 * @private_methods
 ******************************
 */

function _parseTokenData (accessToken) {
  let payload = '';
  let tokenData = {};

  try {
    payload = accessToken.split('.')[1];
    tokenData = JSON.parse(atob(payload));
  } catch (error) {
    throw new Error(error);
  }

  return tokenData;
}

function _resetAuthData () {
  // reset userData in store
  $store.commit('user/SET_CURRENT_USER', {});
  $store.commit('auth/SET_ATOKEN_EXP_DATE', null);
  // reset tokens
  AuthService.setRefreshToken('');
  AuthService.setBearer('');
  AuthService.setAccessToken('');
}

function _setAuthData ({ accessToken, exp } = {}) {
  AuthService.setRefreshToken('true');
  AuthService.setBearer(accessToken);
  AuthService.setAccessToken(accessToken);
  $store.commit('auth/SET_ATOKEN_EXP_DATE', exp);
}
