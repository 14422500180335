<template>
  <div v-loading="loading">
    <el-form :model="form" ref="form" label-width="150px" class="demo-form mb-5" label-position="left" @keyup.enter.native="updateCustomer">
      <el-form-item prop="parent" label-width="0px" class="  ">
        <el-col :md="12">
          <el-form-item class="el-label-customer" label="顧客コード" prop="code">
            <el-aside class="title-header-customer">{{ form.code }}</el-aside>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item prop="parent" label-width="0px" class="  ">
        <el-col :md="12">
          <el-form-item class="el-label-customer start-required" label="氏名" prop="name_kanji">
            <el-input maxlength="100" placeholder="氏名を入力してください" class="" v-model="form.name_kanji"></el-input>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item prop="parent" label-width="0px" class="  ">
        <el-col :md="12">
          <el-form-item class="el-label-customer start-required" label="フリガナ" prop="name_kana">
            <el-input maxlength="100" placeholder="氏名を入力してください" v-model="form.name_kana"></el-input>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item prop="parent" label-width="0px" class="  ">
        <el-col :md="12">
          <el-form-item class="el-label-customer start-required" label="性別" prop="gender">
            <el-radio-group v-model="form.gender" >
              <el-radio  v-for="(item, index) in constants.GENDER" :label="item.value.toString()" :value="item.value" :key="index">{{item.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item prop="parent" label-width="0px" class="  ">
        <el-col :md="12">
          <el-form-item class="el-label-customer start-required" label="生年月日" prop="birthday">
            <el-date-picker class="w-100 el-customer-date-picker"
                            v-model="form.birthday"
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            clear-icon=" ">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item prop="parent" label-width="0px" class="  ">
        <el-col :md="12">
          <el-form-item class="el-label-customer start-required" label="電話番号" prop="phone" >
            <el-input maxlength="20" placeholder="半角数字を入力してください" v-model="form.phone"></el-input>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item prop="parent" label-width="0px" class="  ">
        <el-col :md="12">
          <el-form-item class="el-label-customer start-required" label="メールアドレス" prop="email">
            <el-input placeholder="" v-model="form.email"></el-input>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item prop="parent" label-width="0px" class="  ">
        <el-col :md="12">
          <el-form-item class="el-label-customer start-required" label="住所" prop="address">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item prop="parent" label-width="0px" class="  ">
        <el-col :md="12">
          <el-form-item class="el-label-customer" label="よく通う店舗" prop="store_id">
            <el-select class="w--100 bg-gray-item" v-model="form.store_id"
                       placeholder="選択してください">
              <el-option
                v-for="(item, index) in storeList"
                :key="index"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item prop="parent" label-width="0px" class="  ">
        <el-col :md="12">
          <el-form-item class="el-label-customer" label="リマインダー送信先" prop="sent_contact">
            <el-checkbox-group v-model="form.sent_contact">
              <el-checkbox v-for="(value, index) in constants.SEND_CONTACT" :key="index"
                           :label="value.value">{{ value.label }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
      </el-form-item>
<!--      <el-form-item prop="parent" label-width="0px" class="  ">-->
<!--        <el-col :md="12">-->
<!--          <el-form-item class="el-label-customer start-required plus_point" label="ポイント残高" prop="points">-->
<!--            <el-input disabled maxlength="100" placeholder="氏名を入力してください" class="" v-model="form.points">-->
<!--              <i class="text-primary-i" slot="suffix">pt</i>-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--      </el-form-item>-->
      <div class="row">
        <div class="el-col el-col-24 el-col-md-18">
          <div class="el-col el-col-24 el-col-md-5 text-break-all">
            <label class="el-form-item__label form-item-ran font-weight-bold" style="">契約書</label>
          </div>
          <div class="el-col el-col-8 el-col-md-6">
            <div class="">
              <input style="display: none" :id="'file-upload'" type="file" v-on:change="onImageChange">
              <label :for="'file-upload'"
                     class="file-drag">
                <div class="start">
                  <div><span style=""
                             class="file-upload-btn">ファイル選択</span>
                  </div>
                  <div style="font-size: 11px">*ファイル形式はPDFのみです。</div>
                </div>
              </label>
            </div>
          </div>
          <div v-if="form.contract" class="el-col el-col-8 el-col-md-5">
            <a :href="form.up_file" target="_blank">ファイル見る</a>
          </div>
          <div v-else-if="form.oldImages" class="el-col el-col-8 el-col-md-5">
            <a :href="form.oldImages" target="_blank">ファイル見る</a>
          </div>
        </div>
        <div v-if="contractError.length" class="row m-3">
          <div class="col-md-6" v-if="contractError.length">
            <p class="error-text mt-3 text-right">{{ contractError[0] }}</p>
          </div>
        </div>
      </div>
      <el-form-item  label-width="0px" class="">
        <el-col :md="12">
      <button-common v-on:cancleCreateOrCancelEdit="$router.push({name: 'list-customer'})" v-on:submitCreateOrCancleEdit="updateCustomer" type="create-edit" btnOk="更新" btnCancel="キャンセル">
      </button-common>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { StoreService } from '@/services/store.service';
import { CustomerService } from '@/services/customer.service';
import * as constants from '@/config/constant';
export default {
  name: 'Edit',
  data () {
    return {
      showPopup: true,
      storeList: [],
      contractError: [],
      constants: constants,
      loading: false,
      form: {
        code: '',
        name: '',
        name_kana: '',
        gender: '0',
        birthday: '',
        phone: '',
        email: '',
        address: '',
        store_id: 1,
        sent_contact: [0],
        contract: '',
        up_file: '',
        image: '',
        oldImages: '',
        points: 0
      }
    }
  },
  watch: {
    'form.sent_contact' () {
      if (this.form.sent_contact.length === 0) {
        this.form.sent_contact.push(constants.SEND_CONTACT[0].value);
      }
    }
  },
  created () {
    this.getListStore()
    this.getCustomerById()
  },
  methods: {
    async getCustomerById () {
      this.loading = true;
      try {
        const { data } = await CustomerService.getCustommerById(this.$route.params.customerId);
        const list_notice = [];
        if (data.notify_line === 1 || (data.notify_line === 0 && data.notify_email === 0)) {
          list_notice.push(0);
        }
        if (data.notify_email === 1) {
          list_notice.push(1);
        }
        this.form = {
          code: data.code,
          name_kanji: data.name_kanji,
          name_kana: data.name_kana,
          gender: data.gender.toString(),
          birthday: data.birthday,
          phone: data.phone,
          email: data.email,
          address: data.address,
          store_id: data.store_id,
          sent_contact: list_notice,
          contract: '',
          image: '',
          points: data.points
        }
        this.form.oldImages = data.contract ? data.contract : ''
        if (this.form.store_id === null || this.form.store_id === '') {
          this.form.store_id = 1
        }
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      } finally {
        this.loading = false;
      }
    },
    async getListStore () {
      try {
        const { data } = await StoreService.getListStore()
        this.storeList = data.data;
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      } finally {
      }
    },
    onImageChange (e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0]);
      this.form.up_file = URL.createObjectURL(files[0])
    },
    createImage (file) {
      this.contractError = [];
      const reader = new FileReader();
      const vm = this;
      reader.onload = (e) => {
        vm.form.contract = e.target.result;
      };
      this.form.image = file;
      reader.readAsDataURL(file);
    },
    async updateCustomer () {
      try {
        this.$refs.form.clearValidate(this.form)
        const valid = await this.$refs.form.validate();
        if (!valid) {
          return;
        }
        this.loading = true;
        const form = this.form
        form.notify_line = form.sent_contact.includes(0) ? 1 : 0
        form.notify_email = form.sent_contact.includes(1) ? 1 : 0
        const { data } = await CustomerService.createCustomer({ ...form }, this.$route.params.customerId, true);
        if (data) {
          this.$notify.success({
            title: '成功',
            message: '成功しました。',
            showClose: false
          });
        }
        this.loading = false;
        this.$router.push({
          name: 'list-customer'
        })
      } catch (error) {
        this.loading = false;
        if (error.code === 422) {
          this.showErrorMessage(error.data, 'form');
        } else {
          this.$notify.error({
            title: 'Error',
            message: error.message,
            showClose: false
          });
        }
        if (error.data.contract) {
          this.contractError = error.data.contract
        }
      }
    }
  }
}
</script>

<style scoped>
.w--100{
  width: 100%;
}
.file-upload-btn{
  text-decoration: underline;
  color: darkcyan;
}
.text-primary-i{
  color: black;
}
.el-cusomer-search-register > button {
  color: white;
}
</style>
