import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class CustomerService extends BaseService {
  static get entity () {
    return ''
  }

  static async getListCustomer (params) {
    try {
      const response = await this.request({ auth: true }).get(`customer/list?${params}`, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getCustomerCode () {
    try {
      const response = await this.request({ auth: true }).get('customer/generate-code', { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async createCustomer (data, id = null, checkUpdate = {}) {
    try {
      let url = 'customer/create'
      if (checkUpdate === true) {
        url = `customer/edit/${id}`
      }
      const formData = new FormData();
      formData.append('contract', data.image ?? '');
      formData.append('name_kana', data.name_kana ?? '');
      formData.append('name_kanji', data.name_kanji ?? '');
      formData.append('birthday', data.birthday ?? '');
      formData.append('phone', data.phone ?? '');
      formData.append('email', data.email ?? '');
      formData.append('address', data.address ?? '');
      formData.append('store_id', data.store_id ?? '');
      formData.append('sent_contact', data.sent_contact ?? '');
      formData.append('gender', data.gender ?? '');
      formData.append('name', data.name ?? '');
      formData.append('code', data.code ?? '');
      formData.append('notify_line', data.notify_line ?? 1);
      formData.append('notify_email', data.notify_email ?? 0);
      const response = await this.request({ auth: true }).post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async getCustommerById (id) {
    try {
      const response = await this.request({ auth: true }).get(`customer/show/${id}`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async removeCustomer (data) {
    try {
      const response = await this.request({ auth: true }).post('customer/delete', { ids: data })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }
}
