<template>
  <el-dialog
    :visible.sync="dialog"
    :width="width"
    class="content_popup"
    :show-close="false"
    :close-on-click-modal="closeOnClickModal"
    center>
    <slot name="header" style="text-align: center"></slot>
    <slot name="content"></slot>
    <span slot="footer" class="dialog-footer">
                <el-button class="btn_canle_dialog" v-if="showCancelButton" @click="$emit('closeDialog')">{{ btnCancel }}</el-button>
                <el-button class="btn_submit_dialog" v-if="showOkButton" @click="$emit('submit')">{{ btnOk }}</el-button>
              </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    btnOk: {
      type: String,
      default: 'はい'
    },
    btnCancel: {
      type: String,
      default: 'キャンセル'
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
    showOkButton: {
      type: Boolean,
      default: true
    },
    closeOnClickModal: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '30%'
    }
  }
};
</script>

<style scoped>
.btn_submit_dialog {
  font-family: kzk_bold;
  color: #fff;
  background: #FC9220;
}
</style>
