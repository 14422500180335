<template>
  <div v-loading.fullscreen.lock="loading">
    <el-dialog
      :title="titleForm.title"
      :visible.sync="onDialog"
      width="70%"
      :showCancelButton="false"
      :closeOnClickModal="false"
      :before-close="closeDialog"
    >
      <el-form
        label-width="200px"
        class="demo-ruleForm form-create-booking"
        :model="ruleForm"
        ref="ruleForm"
        label-position="left"
        @keyup.enter.native="createBooking"
      >
          <el-row>
            <el-col :span="11">
                 <el-form-item
                label="顧客タイプ"
                class="el-label-customer el-ticket-label text-color start-required"
                prop="customer_type"
              >
              <el-radio v-for="(item, index) in constants.CUSTOMER_TYPE" v-model="ruleForm.customer_type" :label="item.value" :key="index">{{item.label}}</el-radio>
              </el-form-item>
            </el-col>
          </el-row>

          <div v-show="ruleForm.customer_type === constants.CUSTOMER_TYPE[0].value">
            <el-form-item prop="parent" label-width="0px">
              <el-col :span="11">
                <el-form-item
                  label="お客様の名前（漢字）"
                  class="el-label-customer text-color start-required"
                  prop="customer.name_kanji"
                >
                  <el-input
                    v-model="ruleForm.customer.name_kanji"
                    maxlength="100"
                    :disabled="this.ruleForm.customer_type !== constants.CUSTOMER_TYPE[0].value"
                    @input.native="(e)=>{lengthInput(e, 100)}"
                  > </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="11" :offset="1">
                <el-form-item
                  label="お客様の名前（フリガナ）"
                  class="el-label-customer text-color start-required"
                  prop="customer.name_kana"
                >
                  <el-input
                    v-model="ruleForm.customer.name_kana"
                    maxlength="100"
                    :disabled="this.ruleForm.customer_type !== constants.CUSTOMER_TYPE[0].value"
                    @input.native="(e)=>{lengthInput(e, 100)}"
                  > </el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item prop="parent" label-width="0px">
              <el-col :span="11">
                <el-form-item
                  label="お客様のメール"
                  class="el-label-customer text-color start-required"
                  prop="customer.email"
                >
                  <el-input
                    v-if="this.ruleForm.customer_type === constants.CUSTOMER_TYPE[0].value"
                    v-model="ruleForm.customer.email"
                    maxlength="100"
                    @input.native="(e)=>{lengthInput(e, 100)}"
                  > </el-input>
                  <el-autocomplete
                    v-else
                    style="width:100%"
                    popper-class="my-autocomplete"
                    v-model="ruleForm.customer.email"
                    :fetch-suggestions="querySearch"
                    @select="handleSelect"
                  >
                    <template slot-scope="{ item }">
                      <div class="name-customer" @click="getDetailByName(item.id)">{{ item.email }}</div>
                    </template>
                  </el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="11" :offset="1">
                <el-form-item
                  label="お客様の電話番号"
                  class="el-label-customer text-color start-required"
                  prop="customer.phone"
                >
                  <el-input
                    v-model="ruleForm.customer.phone"
                    :disabled="this.ruleForm.customer_type !== constants.CUSTOMER_TYPE[0].value"
                    maxlength="100"
                    @input.native="(e)=>{lengthInput(e, 100)}"
                  > </el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item prop="parent" label-width="0px">
            <el-col :span="11">
              <el-form-item
                label="お客様の誕生日"
                class="el-label-customer text-color start-required width-style"
                prop="customer.birthday"
              >
                <el-date-picker
                  v-model="ruleForm.customer.birthday"
                  :disabled="this.ruleForm.customer_type !== constants.CUSTOMER_TYPE[0].value"
                  type="date"
                  format="yyyy/MM/dd"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="1">
              <el-form-item
                label="前回の来店日"
                class="el-label-customer text-color width-style"
                prop="customer.last_time_go_shop"
              >
                <el-date-picker
                  v-model="ruleForm.customer.last_time_go_shop"
                  type="date"
                  format="yyyy/MM/dd"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            </el-form-item>
            <el-form-item prop="parent" label-width="0px">
              <el-col :span="11">
                <el-form-item
                  label="サービス利用場所"
                  class="el-label-customer text-color"
                  prop="store_id"
                >
                  {{store ? store.name : ''}}
                </el-form-item>
              </el-col>
            </el-form-item>
          </div>
        <div v-show="ruleForm.customer_type === constants.CUSTOMER_TYPE[1].value">
          <el-form-item prop="parent" label-width="0px">
            <el-col :span="11">
              <el-form-item
                label="お客様の名前（漢字）"
                class="el-label-customer text-color start-required"
                prop="customer.name_kanji"
              >
                <el-input
                  v-if="this.ruleForm.customer_type === constants.CUSTOMER_TYPE[0].value"
                  v-model="ruleForm.customer_old.name_kanji"
                  maxlength="100"
                  @input.native="(e)=>{lengthInput(e, 100)}"
                > </el-input>
                <el-autocomplete
                  v-else
                  style="width:100%"
                  popper-class="my-autocomplete"
                  v-model="ruleForm.customer_old.name_kanji"
                  :fetch-suggestions="querySearch"
                  @select="handleSelect"
                >
                  <template slot-scope="{ item }">
                    <div class="name-customer" @click="getDetailByName(item.id)">{{ item.name_kanji }}</div>
                  </template>
                </el-autocomplete>
              </el-form-item>
            </el-col>

            <el-col :span="11" :offset="1">
              <el-form-item
                label="お客様の名前（フリガナ）"
                class="el-label-customer text-color start-required"
                prop="customer.name_kana"
              >
                <el-input
                  v-model="ruleForm.customer_old.name_kana"
                  maxlength="100"
                  :disabled="this.ruleForm.customer_type !== constants.CUSTOMER_TYPE[0].value"
                  @input.native="(e)=>{lengthInput(e, 100)}"
                > </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item prop="parent" label-width="0px">
            <el-col :span="11">
              <el-form-item
                label="お客様のメール"
                class="el-label-customer text-color start-required"
                prop="customer.email"
              >
                <el-input
                  v-model="ruleForm.customer_old.email"
                  disabled
                  maxlength="100"
                  @input.native="(e)=>{lengthInput(e, 100)}"
                > </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="11" :offset="1">
              <el-form-item
                label="お客様の電話番号"
                class="el-label-customer text-color start-required"
                prop="customer.phone"
              >
                <el-input
                  v-model="ruleForm.customer_old.phone"
                  :disabled="this.ruleForm.customer_type !== constants.CUSTOMER_TYPE[0].value"
                  maxlength="100"
                  @input.native="(e)=>{lengthInput(e, 100)}"
                > </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item prop="parent" label-width="0px">
            <el-col :span="11">
              <el-form-item
                label="お客様の誕生日"
                class="el-label-customer text-color start-required width-style"
                prop="customer.birthday"
              >
                <el-date-picker
                  v-model="ruleForm.customer_old.birthday"
                  :disabled="this.ruleForm.customer_type !== constants.CUSTOMER_TYPE[0].value"
                  type="date"
                  format="yyyy/MM/dd"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="1">
              <el-form-item
                label="前回の来店日"
                class="el-label-customer text-color width-style"
                prop="customer.last_time_go_shop"
              >
                <el-date-picker
                  v-model="ruleForm.customer_old.last_time_go_shop"
                  type="date"
                  format="yyyy/MM/dd"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item prop="parent" label-width="0px">
            <el-col :span="11">
              <el-form-item
                label="サービス利用場所"
                class="el-label-customer text-color"
                prop="store_id"
              >
                {{store ? store.name : ''}}
              </el-form-item>
            </el-col>
          </el-form-item>
        </div>
        <el-form-item
          label="繰り返し"
          class="el-label-customer text-color"
        >
          <el-radio  v-model="ruleForm.loop_status" @click.native="resetFormLoop()" :label="1">有り</el-radio>
          <el-radio  v-model="ruleForm.loop_status" @click.native="resetFormLoop()" :label="0">無し</el-radio>
        </el-form-item>
        <el-row>
          <el-col :span="11">
            <el-form-item
              label="繰り返し期限"
              class="el-label-customer text-color"
              prop="loop_type"
            >
              <el-select style="width:100%" v-model="ruleForm.loop_type" :disabled="ruleForm.loop_status === 0">
                <el-option
                  v-for="(item, index) in constants.LOOP_TYPE"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
               </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="1">
            <el-form-item
              label="目安施術時間"
              class="el-label-customer text-color"
            >
              <el-input
               :value="useTime + '分'"
               disabled
              > </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <template v-if="this.option" >
          <template v-if="this.optionTime.date.length > 1">
            <el-form-item prop="parent" label-width="0px" class="pb-2">
              <el-row>
                <el-col :span="11">
                  <el-form-item
                    label="ご希望日 1"
                    class="el-label-customer text-color start-required start-date"
                  >
                    <el-date-picker
                      v-model="optionTime.date[0]"
                      type="date"
                      format="yyyy-MM-dd"
                      disabled
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="11" :offset="1">
                  <el-form-item
                    label="ご希望時間 1"
                    class="el-label-customer text-color start-required start-time"
                  >
                    <el-time-select
                      v-model="optionTime.startTime[0]"
                      disabled
                    >
                    </el-time-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="11">
                  <el-form-item
                    label="ご希望日 2"
                    class="el-label-customer text-color start-required start-date pt-2 pb-2"
                  >
                    <el-date-picker
                      v-model="optionTime.date[1]"
                      disabled
                      type="date"
                      format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="11" :offset="1">
                  <el-form-item
                    label="ご希望時間 2"
                    class="el-label-customer text-color start-required start-time pt-2 pb-2"
                  >
                    <el-time-select
                      v-model="optionTime.startTime[1]"
                      disabled
                    >
                    </el-time-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <el-form-item
                    label="ご希望日 3"
                    class="el-label-customer text-color start-required start-date"
                  >
                    <el-date-picker
                      v-model="optionTime.date[2]"
                      disabled
                      type="date"
                      format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="11" :offset="1">
                  <el-form-item
                    label="ご希望時間 3"
                    class="el-label-customer text-color start-required start-time"
                  >
                    <el-time-select
                      v-model="optionTime.startTime[2]"
                      disabled
                    >
                    </el-time-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item prop="parent" label-width="0px" class="pb-2">
              <el-row>
                <el-col :span="11">
                  <el-form-item
                    label="ご希望日"
                    class="el-label-customer text-color start-required start-date"
                  >
                    <el-date-picker
                      v-model="optionTime.date[0]"
                      type="date"
                      format="yyyy-MM-dd"
                      disabled
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="11" :offset="1">
                  <el-form-item
                    label="ご希望時間"
                    class="el-label-customer text-color start-required start-time"
                  >
                    <el-time-select
                      v-model="optionTime.startTime[0]"
                      disabled
                    >
                    </el-time-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </template>
        </template>
        <template v-else>
          <el-form-item prop="parent" label-width="0px" class="pb-2">
            <el-col :span="11">
              <el-form-item
                label="来店日"
                class="el-label-customer text-color start-required start-date width-style"
                prop="start_date"
              >
                <el-date-picker
                  v-model="ruleForm.start_date"
                  type="date"
                  format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="1">
              <el-form-item
                label="来店時間"
                class="el-label-customer text-color start-required start-time"
                prop="start_time"
              >
                <el-select class="select-start-time" v-model="ruleForm.start_time" placeholder="">
                  <el-option
                    v-for="item in listTimeInDay"
                    :key="item.time"
                    :label="item.time"
                    :value="item.time"
                  >
                  </el-option>
                </el-select>
                <span class="el-input__prefix"><i class="el-input__icon el-icon-time"></i><!----></span>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item prop="parent" label-width="0px" class="pb-2">
            <el-col :span="11">
              <el-form-item
                label="開始日"
                class="el-label-customer text-color start-date width-style"
              >
                <el-date-picker
                  v-model="ruleForm.start_date"
                  type="date"
                  format="yyyy-MM-dd"
                  disabled
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="1">
              <el-form-item
                label="開始時間"
                class="el-label-customer text-color start-time"
              >
                <el-select disabled class="select-start-time" v-model="ruleForm.start_time" placeholder="">
                  <el-option
                    v-for="item in listTimeInDay"
                    :key="item.time"
                    :label="item.time"
                    :value="item.time"
                  >
                  </el-option>
                </el-select>
                <span class="el-input__prefix"><i class="el-input__icon el-icon-time"></i><!----></span>
              </el-form-item>
            </el-col>
          </el-form-item>
        </template>
        <el-form-item prop="parent" label-width="0px" v-if="this.option">
          <template>
            <el-row>
              <el-col :span="11">
                <el-form-item
                  label="来店日"
                  class="el-label-customer text-color start-required start-date width-style"
                  prop="start_date"
                >
                  <el-date-picker
                    v-model="ruleForm.start_date"
                    type="date"
                    format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="11" :offset="1">
                <el-form-item
                  label="来店時間"
                  class="el-label-customer text-color start-required start-time"
                  prop="start_time"
                >
                  <el-time-select
                    v-model="ruleForm.start_time"
                    :picker-options="{
                  start: store_start_time,
                  step: '00:15',
                  end: store_end_time
                }"
                  >
                  </el-time-select>
                </el-form-item>
              </el-col>
            </el-row>
        </template>
        </el-form-item>
        <el-form-item prop="parent" label-width="0px">
          <template>
            <el-row>
              <el-col :span="11">
                <el-form-item
                  label="終了期間"
                  class="el-label-customer text-color start-required width-style"
                  prop="end_date"
                >
                  <el-date-picker
                    v-model="ruleForm.end_date"
                    :picker-options="{
                      disabledDate(time) {
                           return time.getTime() < ruleForm.start_date;
                        },
                    }"
                    format="yyyy-MM-dd"
                  :disabled="ruleForm.loop_status === constants.LOOP_STATUS.no_loop"
                  > </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="単体"
              class="el-label-customer text-color start-required"
            >
              <el-tabs type="border-card" :class="error ? 'error-card' : ''">
                <el-tab-pane
                  :label="titleCategory.indexOf(item.id) >= 0 ? item.name + ' (' + titleCategory.filter(category_id => category_id === item.id).length + ')'
                  :item.name"
                  v-for="(item, key) in listCategory"
                  :key="'category_'+key">
                  <template v-for="(data, index) in item.service_with_process">
                    <el-col v-if="(data.block == 0 && data.status == 1 && !data.deleted_at) || listServiceProcess[data.id]" :xs="24" :sm="24" :md="24" :lg="12" :xl="8" :key="'service_'+index">
                      <el-form-item prop="parent" label-width="0px" class="pt-3">
                        <el-row>
                          <el-col :xs="12" :sm="8" :md="9" :lg="9" :xl="8" class="service-col">
                            <el-tooltip :content="data.name" :disabled="data.name.length <= 10" placement="top">
                              <div :class="data.block == 1 ? 'color-text-danger' : listServiceProcess[data.id] ? 'color-text-primary' : ''" class="service-name">{{data.name}}</div>
                            </el-tooltip>
                          </el-col>
                          <el-col :xs="12" :sm="15" :md="15" :lg="15" :xl="16">
                            <el-select class="w--100 bg-gray-item" v-model="listServiceProcess[data.id]">
                              <el-option :label="'なし'" value=""></el-option>
                              <template v-for="(value, number) in data.process_with_trashed">
                                <el-option
                                  v-if="!value.deleted_at || listServiceProcess[data.id]"
                                  :class="value.deleted_at || data.block == 1 ? 'color-text-danger' : ''"
                                  :key="'process_'+ key + index + number"
                                  :label="value.use_time + '分' + (value.deleted_at ? ' (ブロック)' : '')"
                                  :value="value.id">
                                </el-option>
                              </template>
                            </el-select>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                  </template>
                </el-tab-pane>
              </el-tabs>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="error_service" class="error-message" style="margin: 0 0 30px 200px">{{error_service}}</div>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="コース"
              class="el-label-customer text-color start-required"
            >
              <el-card class="box-card" :class="error ? 'error-card' : ''">
                <template v-for="(data, index) in listCombo">
                  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="8" v-if="(data.block == 0 && data.status == 1 && !data.deleted_at) || listComboProcess[data.id]" :key="'combo_'+index">
                    <el-form-item prop="parent" label-width="0px" class="pt-3">
                      <el-row>
                        <el-col :xs="12" :sm="8" :md="9" :lg="9" :xl="8" class="service-col">
                          <el-tooltip :content="data.name" placement="top">
                            <div :class="data.block == 1 ? 'color-text-danger' : listComboProcess[data.id] ? 'color-text-primary' : ''" class="service-name">{{data.name}}</div>
                          </el-tooltip>
                        </el-col>
                        <el-col :xs="12" :sm="15" :md="15" :lg="15" :xl="16">
                          <el-select class="w--100 bg-gray-item" v-model="listComboProcess[data.id]">
                            <el-option :label="'なし'" value=""></el-option>
                            <template v-for="(value, number) in data.process_with_trashed">
                              <el-option
                                v-if="!value.deleted_at || listComboProcess[data.id]"
                                :class="value.deleted_at || data.block == 1 ? 'color-text-danger' : ''"
                                :key="'process_' + index + number"
                                :label="value.use_time + '分' + (value.deleted_at ? ' (ブロック)' : '')"
                                :value="value.id">
                              </el-option>
                            </template>
                          </el-select>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-col>
                </template>
              </el-card>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="error_combo" class="error-message" style="margin: 0 0 30px 200px">{{error_combo}}</div>
        <el-row>
          <el-form-item prop="parent">
            <el-col :span="24">
              <el-form-item
                class="el-label-customer text-color"
              >
                <el-row>
                  <div class="color-choose color-danger"></div>
                  <div class="ml-4 text-choose">: ブロック済</div>
                </el-row>
                <el-row class="mt-2">
                  <div class="color-choose color-primary"></div>
                  <div class="ml-4 text-choose">: 選択済</div>
                </el-row>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item prop="parent" label-width="0px">
            <el-col :span="24">
              <el-form-item
                label="お客様からのコメント"
                class="el-label-customer text-color"
                prop="customer_comment"
              >
                <el-input
                  type="textarea"
                  :rows="8"
                  maxlength="2000"
                  prop="customer_comment"
                  placeholder="お客様からのコメントを入力してください"
                  v-model="ruleForm.customer_comment"
                  @input.native="(e)=>{lengthInput(e, 2000)}"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item prop="parent" label-width="0px">
            <el-col :span="24">
              <el-form-item
                label="店舗内部向けのコメント"
                class="el-label-customer text-color"
                prop="customer_comment"
              >
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="店舗内部向けのコメントを入力してください"
                  maxlength="2000"
                  prop="admin_comment"
                  v-model="ruleForm.admin_comment"
                  @input.native="(e)=>{lengthInput(e, 2000)}"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>
            <div class="row list_btn">
              <div class="col-md-6 text-right" v-if="ruleForm.customer_old.id">
                <el-button
                  v-if="ruleForm.loop_status === constants.LOOP_STATUS.no_loop"
                  @click="openDialogConfirm(2)"
                  type="danger"
                  style="width: 150px"
                  class="btn-cancel"
                >
                  予約をキャンセルする
                </el-button>
                <el-button
                  v-else
                  @click="cancelBookingLoop(id)"
                  type="danger"
                  style="width: 150px"
                  class="btn-cancel"
                >
                  予約をキャンセルする
                </el-button>
              </div>
              <div v-else class="col-md-6 text-right">
                <el-button
                  @click="closeDialog()"
                  type="danger"
                  style="width: 150px"
                >
                  閉じる
                </el-button>
              </div>
              <div class="el-cusomer-search-register col-md-6">
                <el-button v-if="id" @click="openDialogConfirm(1)" type="primary" class="button_submit">
                  {{titleForm.button}}
                </el-button>
                <el-button v-else @click="createBooking()" type="primary" class="button_submit">
                  {{titleForm.button}}
                </el-button>
              </div>
            </div>
      </el-form>
    </el-dialog>
    <el-dialog
      title="警報"
      :visible.sync="showDialogConfirm"
      :before-close="handleCloseDialogConfirm"
      width="25%"
    >
      <h5 class="textwidget text-center title-header-customer">{{title_delete}}</h5>
      <span slot="footer" class="dialog-footer button-footer">
        <el-button @click="handleCloseDialogConfirm">キャンセル</el-button>
        <el-button v-if="type_submit==2" type="danger" @click="deleteBooking(id)"  id="w-button">確認</el-button>
        <el-button v-if="type_submit==1" type="primary" @click="createBooking()"  id="w-button">確認</el-button>
      </span>
    </el-dialog>
    <CancelBookingLoop
      @close-dialog="closeDialogCancel"
      :date="date"
      :onDialog="cancel_booking_loop"
      :startTime="startTime"
      :endTime="endTime"
      :endDate="ruleForm.end_date"
      :loopType="loopType"
      :idBooking="id"
      @get-list="reloadData()"
    >
    </CancelBookingLoop>
  </div>
</template>

<script>

import * as constants from '@/config/constant';
import { CHOSE_SERVICE, STORE_START_TIME, STORE_END_TIME, CUSTOMER_TYPE } from '@/config/constant';
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin';
import { BookingService } from '@/services/booking.service';
import { StoreService } from '@/services/store.service';
import CancelBookingLoop from '@/pages/createBooking/CancelBookingLoop';
import moment from 'moment';
export default {
  mixins: [prepareQueryParamsMixin],
  name: 'Create-booking',
  components: {
    CancelBookingLoop
  },
  props: {
    onDialog: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    store: {
      type: Object,
      default: () => {}
    },
    date: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      ruleForm: {
        store_id: this.storeId,
        loop_status: 0,
        loop_type: 1,
        start_date: '',
        start_time: '',
        end_date: '',
        admin_comment: '',
        customer_comment: '',
        customer_type: 0,
        booking_service_list_id: [],
        booking_commbo_list_id: [],
        customer: {
          name_kanji: '',
          name_kana: '',
          last_time_go_shop: '',
          birthday: '',
          email: '',
          phone: ''
        },
        customer_old: {
          id: '',
          name_kanji: '',
          name_kana: '',
          last_time_go_shop: '',
          birthday: '',
          email: '',
          phone: ''
        },
        is_create: 1
      },
      title_delete: '',
      type_submit: '',
      showDialogConfirm: false,
      cancel_booking_loop: false,
      total_time: '',
      arrUseTimeCombo: [],
      arrUseTimeService: [],
      timeService: '',
      timeCombo: '',
      listCategory: [],
      listService: [],
      listServiceProcess: {},
      listCombo: [],
      listComboChoose: [],
      listComboProcess: {},
      listStore: [],
      info: [],
      startTime: '',
      endTime: '',
      loopType: '',
      error: '',
      error_combo: '',
      error_service: '',
      disableRadio: true,
      checkList: false,
      loading: false,
      constants: constants,
      cities: CHOSE_SERVICE,
      store_start_time: STORE_START_TIME,
      store_end_time: STORE_END_TIME,
      option: false,
      optionTime: {
        date: [],
        startTime: []
      },
      userCreate: false,
      listTimeInDay: []
    };
  },
  mounted () {
    this.getInfoCustomer();
  },
  created () {
    this.getListCombo(this.$route.params.storeId);
    this.getListStore();
    this.getListTimeInDay();
    this.getListCategory(this.$route.params.storeId);
  },
  computed: {
    useTime () {
      let time = 0
      const self = this
      this.listCategory.forEach((category) => {
        category.service_with_process.forEach((service) => {
          if (self.listServiceProcess[service.id]) {
            service.process.forEach((process) => {
              if (process.id === self.listServiceProcess[service.id]) {
                time += process.use_time
              }
            })
          }
        })
      })
      this.listCombo.forEach((combo) => {
        if (self.listComboProcess[combo.id]) {
          combo.process.forEach((process) => {
            if (process.id === self.listComboProcess[combo.id]) {
              time += process.use_time
            }
          })
        }
      })
      return time
    },
    titleCategory () {
      const self = this
      const listCategoryChoose = []
      this.listCategory.forEach((category) => {
        category.service_with_process.forEach((service) => {
          if (self.listServiceProcess[service.id]) {
            service.process.forEach((process) => {
              if (process.id === self.listServiceProcess[service.id]) {
                listCategoryChoose.push(category.id)
              }
            })
          }
        })
      })
      return listCategoryChoose
    },
    titleForm () {
      return {
        title: this.id ? '予約編集' : '新規予約作成',
        button: this.id ? '予約を承認する' : '予約作成'
      }
    }
  },
  watch: {
    id () {
      if (this.id) {
        this.getDetailBooking(this.id)
      } else {
        this.resetForm('ruleForm');
      }
    },
    'ruleForm.start_date' () {
      if (this.ruleForm.start_date && this.ruleForm.end_date && moment(this.ruleForm.end_date, 'YYYY-MM-DD').isBefore(moment(this.ruleForm.start_date, 'YYYY-MM-DD'))) {
        this.ruleForm.end_date = this.ruleForm.start_date
      }
    },
    '$route.params.storeId' () {
      this.getListCategory(this.$route.params.storeId);
      this.getListCombo(this.$route.params.storeId);
    }
  },
  methods: {
    lengthInput (e, maxLength) {
      if (e.target.value.length > maxLength) {
        e.target.value = e.target.value.substr(0, maxLength)
      }
    },
    handleCloseDialogConfirm () {
      this.showDialogConfirm = false
    },
    openDialogConfirm (type) {
      this.showDialogConfirm = true
      this.type_submit = type
      this.title_delete = this.type_submit === 1 ? '予約を承認してもよろしいでしょうか？' : '予約をキャンセルしてもよろしいでしょうか？'
    },
    closeDialog () {
      this.resetForm('ruleForm');
      this.listCategory.forEach((item) => {
        item.checked = false
        item.service_with_process.forEach((service) => {
          service.checked = false
          service.process.forEach((process) => {
            process.checked = false
          })
        })
      })
      this.listCombo.forEach((combo) => {
        combo.checked = false
        combo.process.forEach((process) => {
          process.checked = false
        })
      });

      this.$emit('close-dialog')
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
      this.ruleForm = {
        store_id: '',
        loop_status: 0,
        loop_type: 1,
        start_date: '',
        start_time: '',
        end_date: '',
        admin_comment: '',
        customer_comment: '',
        customer_type: 0,
        booking_service_list_id: [],
        booking_commbo_list_id: [],
        customer: {
          id: '',
          name_kanji: '',
          name_kana: '',
          last_time_go_shop: '',
          birthday: '',
          email: '',
          phone: ''
        },
        customer_old: {
          id: '',
          name_kanji: '',
          name_kana: '',
          last_time_go_shop: '',
          birthday: '',
          email: '',
          phone: ''
        },
        is_create: 1
      }
      this.option = false
      this.error = ''
      this.error_service = ''
      this.error_combo = ''
      this.userCreate = false
      this.listService = []
      this.listServiceProcess = {}
      this.listComboChoose = []
      this.listComboProcess = {}
    },
    async createBooking () {
      this.ruleForm.booking_commbo_list_id = []
      this.ruleForm.booking_service_list_id = []
      this.error = ''
      this.error_service = ''
      this.error_combo = ''
      this.loading = true
      this.$refs.ruleForm.clearValidate(this.ruleForm)
      try {
        const ruleForm = this.ruleForm;
        const startDate = this.ruleForm.start_date
        const endDate = this.ruleForm.end_date
        if (ruleForm.customer_type === CUSTOMER_TYPE[1].value) {
          ruleForm.customer.name_kanji = this.ruleForm.customer_old.name_kanji
          ruleForm.customer.name_kana = this.ruleForm.customer_old.name_kana
          ruleForm.customer.last_time_go_shop = this.ruleForm.customer_old.last_time_go_shop
          ruleForm.customer.birthday = this.ruleForm.customer_old.birthday
          ruleForm.customer.email = this.ruleForm.customer_old.email
          ruleForm.customer.phone = this.ruleForm.customer_old.phone
          ruleForm.customer.id = this.ruleForm.customer_old.id ?? ''
        }
        if (ruleForm.loop_status === 0) {
          ruleForm.end_date = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
        } else {
          ruleForm.end_date = endDate ? moment(endDate).format('YYYY-MM-DD') : '';
        }
        Object.keys(this.listServiceProcess).forEach((service) => {
          if (this.listServiceProcess[service]) {
            const serviceData = {
              service_id: service,
              service_process_id: this.listServiceProcess[service]
            }
            ruleForm.booking_service_list_id.push(serviceData)
          }
        })
        Object.keys(this.listComboProcess).forEach((combo) => {
          if (this.listComboProcess[combo]) {
            const comboData = {
              combo_id: combo,
              combo_process_id: this.listComboProcess[combo]
            }
            ruleForm.booking_commbo_list_id.push(comboData)
          }
        })
        if (this.store) {
          ruleForm.store_id = this.store.id
        }
        ruleForm.start_date = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
        ruleForm.use_time = this.useTime
        if (this.id) {
          await BookingService.editBooking(this.id, ruleForm);
        } else {
          await BookingService.createBooking(ruleForm);
        }
        this.resetForm('ruleForm')
        this.closeDialog()
        this.getInfoCustomer();
        this.$notify.success({
          title: '成功',
          message: '更新しました。',
          showClose: false
        });
        this.$emit('get-list')
        this.loading = false
      } catch (error) {
        this.loading = false
        if (error.code === 422) {
          this.showErrorMessage(error.data, 'ruleForm');
          if (error.data.booking_commbo_list_id) {
            this.error = error.data.booking_commbo_list_id[0];
            this.error_combo = error.data.booking_commbo_list_id[0];
          }
          if (error.data.booking_service_list_id) {
            this.error = error.data.booking_service_list_id[0];
            this.error_service = error.data.booking_service_list_id[0];
          }
        }
        console.log(error)
      }
      this.handleCloseDialogConfirm()
    },
    async getInfoCustomer () {
      try {
        const { data } = await BookingService.getInfoCustomer();
        this.info = data.data;
      } catch (error) {
        this.$notify.error({
          title: 'Errors',
          message: error.message,
          showClose: false
        });
      }
    },
    async getDetailByName (id) {
      const data = this.info.filter(x => x.id === id);
      const customer = {
        id: data[0].id,
        name_kanji: data[0].name_kanji,
        name_kana: data[0].name_kana,
        last_time_go_shop: data[0].last_time_go_shop,
        birthday: data[0].birthday,
        email: data[0].email,
        phone: data[0].phone
      }
      const ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
      ruleForm.customer_old = customer
      this.ruleForm = ruleForm
      this.ruleForm.store_id = data[0].store_id;
    },
    querySearch (queryString, cb) {
      const info = this.info;
      const results = queryString ? info.filter(this.createFilter(queryString)) : info;
      // call callback function to return suggestion objects
      cb(results);
    },
    createFilter (queryString) {
      return (link) => {
        return (link.email.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect (item) {
      this.ruleForm.customer_old.name_kanji = item.name_kanji
      this.ruleForm.customer_old.id = item.id
    },
    async getListStore () {
      try {
        const { data } = await StoreService.getListStore()
        this.listStore = data.data;
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      }
    },
    resetFormLoop () {
      this.ruleForm.start_date = '';
      this.ruleForm.end_date = '';
      this.ruleForm.start_time = '';
    },
    async getListCombo () {
      try {
        const request = { store_id: this.$route.params.storeId }
        const { data } = await StoreService.getListCombo(this.prepareQueryParamsMixin(request))
        this.listCombo = data.data;
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      }
    },
    async getListCategory (id) {
      try {
        const { data } = await StoreService.getListCategory(id)
        this.listCategory = data.data
        console.log(this.listCategory)
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      }
    },
    /* eslint max-lines-per-function: ["error", 250] */
    async getDetailBooking (id) {
      try {
        const { data } = await BookingService.getInfoBooking(id);
        this.ruleForm = {
          store_id: data.store_id,
          loop_status: data.loop_status,
          loop_type: data.loop_type ? data.loop_type : 1,
          start_date: data.start_date,
          start_time: data.start_time ? moment(data.start_time, 'HH:mm:ss').format('HH:mm') : '',
          end_date: data.end_date,
          admin_comment: data.admin_comment,
          customer_comment: data.customer_comment,
          customer_type: 1,
          booking_service_list_id: [],
          booking_commbo_list_id: [],
          customer: {
            name_kanji: '',
            name_kana: '',
            last_time_go_shop: '',
            birthday: '',
            email: '',
            phone: ''
          },
          customer_old: {
            id: data.user_id,
            name_kanji: data.user ? data.user.name_kanji : '',
            name_kana: data.user ? data.user.name_kana : '',
            last_time_go_shop: data.user ? data.user.last_time_go_shop : '',
            birthday: data.user ? data.user.birthday : '',
            email: data.user ? data.user.email : '',
            phone: data.user ? data.user.phone : ''
          },
          is_create: 1
        }

        constants.LOOP_TYPE.forEach((item) => {
          if (item.value === data.loop_type) {
            this.loopType = item.label
          }
        })
        this.startTime = data.start_time
        this.endTime = moment(this.startTime, 'HH:mm').add(this.useTime, 'minutes').format('HH:mm');
        const listServiceProcess = {}
        data.booking_service.forEach((value) => {
          listServiceProcess[value.service_id] = value.service_process_id
        })
        this.listServiceProcess = listServiceProcess
        const listComboProcess = {}
        data.booking_combo.forEach((value) => {
          listComboProcess[value.combo_id] = value.combo_process_id
        })
        this.listComboProcess = listComboProcess
        if (data.booking_detail.length > 0) {
          this.userCreate = true
          this.option = true
          let dateFirst = data.booking_detail[0].date
          if (data.booking_detail.length === 1) {
            this.ruleForm.start_date = data.booking_detail[0].date
          } else {
            for (let i = 1; i < data.booking_detail.length; i++) {
              const dateSecond = data.booking_detail[i].date
              if (dateFirst > dateSecond) {
                dateFirst = dateSecond
              }
              this.ruleForm.start_date = dateFirst;
            }
          }

          const time = data.booking_detail.filter(x => x.date === dateFirst);
          this.ruleForm.start_time = moment(time[0].start_time, 'HH:mm:ss').format('HH:mm')
          data.booking_detail.forEach((time, index) => {
            this.optionTime.date[index] = time.date
            this.optionTime.startTime[index] = moment(time.start_time, 'HH:mm:ss').format('HH:mm')
          })
        }
        this.listCategory = this.listCategory.map(category => {
          category.service_with_process = category.service_with_process.filter(process => {
            return !process.deleted_at || process.id === this.listServiceProcess[category.id]
          })
          return category
        })
        this.listCombo = this.listCombo.map(category => {
          category.process = category.process.filter(process => {
            return !process.deleted_at || process.id === this.listComboProcess[category.id]
          })
          return category
        })
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      }
    },
    async deleteBooking (id) {
      try {
        this.loading = true
        await BookingService.deleteBooking(id)
        this.closeDialog();
        this.$emit('get-list')
        this.$notify.success({
          title: '成功',
          message: 'この予約はキャンセルされました。',
          showClose: false
        });
        this.loading = false
        this.showDialogConfirm = false
      } catch (e) {
        this.loading = false
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      }
      this.handleCloseDialogConfirm()
    },
    async cancelBookingLoop () {
      this.cancel_booking_loop = true
    },
    closeDialogCancel () {
      this.cancel_booking_loop = false
      this.closeDialog()
    },
    reloadData () {
      this.$emit('get-list')
    },
    getListTimeInDay () {
      this.listTimeInDay = []
      let time = moment().format('YYYY-MM-DD')
      const timeNextDay = time + ' ' + STORE_END_TIME
      time = time + ' ' + STORE_START_TIME
      time = moment(time)
      while (time.isSameOrBefore(moment(timeNextDay))) {
        this.listTimeInDay.push({ time: time.format('HH:mm'), block: false, booking: [], position: null, staff: [] })
        time = time.add('minutes', 15)
      }
    }
  }
};
</script>

<style scoped>
.service{
  padding-bottom: 30px;
  margin-left: 10px;
}
.combo-list{
  margin-left: 10px;
  padding-bottom: 30px;
}
.comment{
  margin: 0 10px;
}
.input-comment{
  padding: 0 10px;
}
::v-deep .form-create-booking .el-form-item__label{
  width: unset !important;
  margin-left: 9px;
  text-align: left;
}
::v-deep .service .el-checkbox-group{
  max-height: 100px;
  overflow: auto;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  padding: 20px 35px;
  margin: 0px 10px 0px 1px;
  width: 98%;

}
.combo-list{
  max-height: 100px;
  overflow: auto;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  padding: 20px;
  margin: 0px 13px 40px 10px;
}
.service-children{
  padding-bottom: 15px;
}
.error-message{
  color: red;
  font-size: 12px;
}
.service h5{
  font-weight: bold;
}
.title-service{
  font-weight: bold;
  color: #6adaaf;
  margin-left: 10px
}
.required-text {
  margin-top: 13px;
  color: red;
  font-weight: bold;
}
.start-time .el-date-editor.el-input{
  width: 100%;
  min-width: 100px;
}
.start-date .el-date-editor.el-input{
  width: 100%;
  min-width: 100px;
}
.button_submit{
    color: #FFF !important;
}
.explain{
  margin-top: 5px;
}
.radio-width {
  width: 120px;
}
.service-col {
  line-height: 40px;
  /*width: 120px*/
}
.service-name {
  overflow: hidden;
  width: 100px;
  text-align: right;
  padding-right: 20px;
  height: 30px
}
.el-cusomer-search-register .button_submit {
  border: 1px solid #409EFF;
  padding: 12px 42px;
}
.width-style .el-date-editor.el-input {
  width: 100%;
}
.btn-cancel {
  width: unset !important;
}
.select-start-time{
  width: 100%;
}
.color-choose:first-child {
  float: left;
  width: 15px;
  height: 15px;
  border-radius: 3px;
}
.text-choose {
  color: rgb(96, 98, 102);
  line-height: 16px;
}
</style>
