<template>
  <div>
    <el-dialog class="booking-dialog"
               :visible.sync="dialog"
               title="ロコミー詳細"
               :before-close="handleCloseDialog"
               :close-on-click-modal="false"
    >
      <el-form ref="ruleForm" :model="ruleForm" label-width="170px" label-position="left" class="review_form_edit">
        <el-form-item label="匿名" prop="anony" class="start-required">
          <el-input v-model="ruleForm.anony" placeholder="公式掲載用ペンネーム">
          </el-input>
        </el-form-item>
        <el-form-item label="年齢" prop="age" class="start-required">
          <el-select v-model="ruleForm.age" placeholder="選択してください" style="width: 100%;">
            <el-option
              v-for="item in constants.REVIEW_AGE"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="利用日" prop="date" class="start-required">
          <el-date-picker class="w-100 el-customer-date-picker"
                          v-model="ruleForm.date"
                          type="date"
                          placeholder="選択してください"
                          style="width: 100%;"
                          prefix-icon="el-icon-date"
                          format="yyyy/MM/dd"
                          clear-icon=" "
                          :editable="false"
                          value-format="yyyy-MM-dd">
            ></el-date-picker>
        </el-form-item>

        <el-form-item prop="parent" label-width="0px" class="  ">
            <el-form-item class="el-label-customer start-required" label="性別" prop="gender">
              <el-radio-group v-model="ruleForm.gender">
                <el-radio  v-for="(item, index) in constants.GENDER" :label="item.value" :value="item.value" :key="index">{{item.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
        </el-form-item>

        <el-form-item prop="name" label="利用メニュー" class="start-required text-bold enter-user-radio">
          <el-input
            class="textarea_confirm_booking"
            maxLength="255"
            @input.native="(e)=>{lengthInput(e, 255)}"
            v-model="ruleForm.name"
            type="textarea"
            :autosize="{ minRows: 3}"
            placeholder="サービス名はここでご記入ください。">
          </el-input>
        </el-form-item>
        <el-form-item prop="content" label="テキスト入力" class="start-required text-bold enter-user-radio">
          <el-input
            class="textarea_confirm_booking"
            maxLength="1000"
            @input.native="(e)=>{lengthInput(e, 1000)}"
            v-model="ruleForm.content"
            type="textarea"
            :autosize="{ minRows: 8}"
            placeholder="レビューはここでご記入してください。">
          </el-input>
        </el-form-item>

        <el-form-item label="画像アップロード（2枚まで）" class="text-bold enter-user-radio">
          <el-row class="d-flex justify-content-between">
            <el-col :xs="23" :md="10">
              <form class="uploader">
                <input id="file-upload" type="file" name="fileUpload" accept="image/*" @change="uploadImage($event)" />
                <i v-if="imageUrl" class="el-icon-error clear-icon" @click="rmImage()"></i>
                <label for="file-upload">
                  <a target="_blank" v-if="imageUrl" :href="imageUrl"><img class="file-image" :src="imageUrl" alt="Preview"></a>
                  <div v-else class="start">
                    <i class="el-icon-upload" style="font-size: 50px"></i>
                    <div>アップロード</div>
                  </div>
                </label>
              </form>
            </el-col>
            <el-col :md="10" class="image-second">
              <form class="uploader">
                <input id="file-upload-1" type="file" name="fileUpload" accept="image/*" @change="uploadImageSecond($event)" />
                <i v-if="imageUrl2" class="el-icon-error clear-icon" @click="rmImageSecond()"></i>
                <label for="file-upload-1">
                  <a target="_blank" v-if="imageUrl2 " :href="imageUrl2"><img class="file-image" :src="imageUrl2" alt="Preview"></a>
                  <div v-else class="start">
                    <i class="el-icon-upload" style="font-size: 50px"></i>
                    <div>アップロード</div>
                  </div>
                </label>
              </form>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-form-item class="el-label-customer" label=" " prop="status">
            <el-radio-group v-model="ruleForm.status">
              <el-radio  v-for="(item, index) in constants.REVIEW_STATUS" :label="index" :value="index" :key="index">{{item}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form-item>
          <button-common v-on:cancleCreateOrCancelEdit="handleCloseDialog" v-on:submitCreateOrCancleEdit="confirmSubmit=true" type="create-edit" btnOk="編集" btnCancel="キャンセル">
        </button-common>
      </el-form>
    </el-dialog>
    <my-dialog class="customer-confirm-delete create_paymnet_poopup" v-on:closeDialog="confirmSubmit=false"
               v-on:submit="EditReview" v-bind:dialog="confirmSubmit"
               :closeOnClickModal="false" width="28%" btnOk="確認" :showCancelButton="true" btnCancel="キャンセル">
      <template slot="content">
        <h5 class="textwidget text-center title-header-customer">
          本当に編集よろしいでしょうか？
        </h5>
      </template>
    </my-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import * as constants from '@/config/constant';
import { ReviewService } from '@/services/review.service';
export default {
  name: 'Create',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    idedit: {
      type: Number,
      default: 0
    }

  },
  data () {
    return {
      moment: moment,
      constants: constants,
      ruleForm: {
        anony: '',
        gender: 1,
        date: '',
        name: '',
        content: '',
        image1: '',
        rmImage1: 0,
        rmImage2: 0,
        image2: '',
        status: '1'

      },
      error: [],
      confirmSubmit: false,
      imageUrl: '',
      imageUrl2: ''

    }
  },
  created () {
    this.loading = true;
    this.loading = false;
  },
  watch: {
    'idedit' (newValue) {
      if (newValue) {
        this.showDetailReview(this.idedit)
      }
    }

  },
  methods: {
    handleCloseDialog () {
      this.error = []
      this.imageUrl = ''
      this.imageUrl2 = ''
      this.$refs.ruleForm.clearValidate(this.ruleForm)
      this.$emit('closeDialog')
    },
    async showDetailReview (id) {
      try {
        this.loading = true;
        const { data } = await ReviewService.showDetail(id)
        if (data) {
          this.ruleForm = data
          this.imageUrl = data.imageUrl
          this.imageUrl2 = data.imageUrl2
        }
      } catch (error) {
        this.$notify.error({
          title: 'Errors',
          message: error.message,
          showClose: false
        });
      } finally {
        this.loading = false;
      }
    },
    async EditReview () {
      try {
        this.loading = true;
        this.confirmSubmit = false
        this.$refs.ruleForm.clearValidate(this.ruleForm)
        const { data } = await ReviewService.EditReview(this.ruleForm, this.idedit, this.imageUrl, this.imageUrl2)
        if (data) {
          this.handleCloseDialog();
          this.$emit('RegisterOrEditSucess')
          this.$notify.success({
            title: '成功',
            message: '更新しました。',
            showClose: false
          });
        }
      } catch (error) {
        if (error.code === 422) {
          this.showErrorMessage(error.data, 'ruleForm');
          this.error = error.data
        } else {
          this.$notify.error({
            title: 'Errors',
            message: error.message,
            showClose: false
          });
        }
      } finally {
        this.loading = false;
      }
    },
    rmImage () {
      this.imageUrl = ''
      this.ruleForm.image1 = ''
      this.ruleForm.rmImage1 = 1
    },
    rmImageSecond () {
      this.imageUrl2 = ''
      this.ruleForm.image2 = ''
      this.ruleForm.rmImage2 = 1
    },
    uploadImage (event) {
      const input = event.target;
      if (this.checkImage(input)) {
        this.imageUrl = URL.createObjectURL(event.target.files[0])
        this.ruleForm.image1 = event.target.files[0]
      }
    },
    uploadImageSecond (event) {
      const input = event.target;
      if (this.checkImage(input)) {
        this.imageUrl2 = URL.createObjectURL(event.target.files[0])
        this.ruleForm.image2 = event.target.files[0]
      }
    },
    checkImage (input) {
      if (input.files && input.files[0]) {
        const file = input.files[0];
        const size = Math.round((file.size / 1024));
        const arrayType = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!arrayType.includes(file.type)) {
          this.notifyError('タイプのファイルはjpeg、jpg、pngしかでアップロードできません。')
          return false
        }
        if (size > constants.MAX_SIZE_FILE) {
          this.notifyError('ファイルサイズが大きすぎです。')
          return false
        }
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
hr {
  border: none;
  border-top: 3px dashed grey;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 100%;
}

.text-primary-i {
  color: blue;
}

.text-combo {
  color: #00b0ff;
}

.btn-add-combo {
  font-weight: bold;
  color: blue !important;
  cursor: pointer;
}

.table-combo > :not(:first-child) {
  border-top: unset !important;
}

.table-combo > thead, tbody, tfoot, tr, td, th {
  border-style: unset;
}

.formControlSelect {
  line-height: 2;
}

.input-group-text {
  /*  background-color: white;
    border: none;
    border-left: 1px solid #ced4da ;*/
  padding: 3px;
  font-size: 14px;
}

.input-group > input {
  width: 87%;
}

.table-responsive {
  margin-top: -11px;
}

.service-combo-name {
  width: 30%;
}

.input-group > input {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.table-responsive > table > tbody {
  border: none;
}

.btn-delete {
  text-align: center !important;
}

.w-90 {
  width: 90%;
}

.status-payment {
  color: blue;
}

.status-booking-pending {
  color: rgb(245, 154, 36)
}

.status-booking-approvel {
  color: rgb(123, 188, 23)
}

.status-booking-reject {
  color: rgb(227, 71, 91)
}

.active {
  color: black !important;
  font-weight: 400 !important;
}

.block, .delete {
  color: red !important;
  font-weight: 400 !important;
}
.uploader #response.hidden {
  display: none;
}
.uploader .file-image.hidden {
  display: none;
}
.uploader input[type=file] {
  display: none;
}
.clear-icon {
  font-size: 50px;
  color: red;
  position: absolute;
  right: -25px;
  top: -22px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
}
.uploader {
  position: relative;
}
/*.combo-service-price{
left: 52%;
}*/
</style>
