<template>
 <el-col class="is-justify-end el-row--flex"><el-button type="primary" icon="el-icon-plus" @click="redirectLink"> Add </el-button></el-col>
</template>
<script>
export default {
  props: {
    routerName: {
      type: String,
      default: ''
    }
  },
  methods: {
    redirectLink () {
      this.$router.push({
        name: this.routerName
      });
    }
  }
};
</script>
