import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class StaffService extends BaseService {
  static get entity () {
    return ''
  }

  static async getLisStaff (params) {
    try {
      const response = await this.request({ auth: true }).get(`staff/list?${params}`, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async deleteStaff (listId) {
    try {
      const response = await this.request({ auth: true }).put('staff/delete', listId)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async createStaff (data) {
    try {
      const response = await this.request({ auth: true }).post('staff/create', data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getStaffDetail (id) {
    try {
      const response = await this.request({ auth: true }).get('staff/show/' + id)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async editStaff (data, id) {
    try {
      const response = await this.request({ auth: true }).put('staff/edit/' + id, data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
