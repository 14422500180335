<template>
  <div class="login">
    <img class="logo-asena" src="@/assets/img/logo-new.png" alt="">
    <el-card>
      <div class="logo-login">
        <h3 class = "title">ログイン</h3>
        <!-- <img src="@/assets/img/logo-login.png" alt=""> -->
      </div>
      <el-form
        ref="form"
        :model="model"
        :rules="rules"
        class="login-form"
        @submit.enter.prevent="makeLogin"
      >
        <el-form-item prop="email" label="メールアドレス" class="passwd">
          <el-input v-model="model.email" placeholder="入力してください" maxlength="254"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="パスワード">
          <el-input
            v-model="model.password"
            placeholder="入力してください"
            :type="fieldType"
            show-password
          ></el-input>
          <p class="text-gray" style="font-size: 10px">※半角英数字や記号を使用し６文字以上１２文字以内を入力してください。</p>
        </el-form-item>
        <p class="mandatory">{{ error }}</p>
        <el-form-item>
          <el-checkbox v-model="remember_me"><span class="forgot">パスワードを保存します</span></el-checkbox>
          <div class="parent">
            <div class="child1">
              <router-link class="forgot-password" :to="{ name: 'forget-password'}">お忘れの方はこちら</router-link>
            </div>
            <div class="child2">
              <el-button class="el-btn-login" @click="makeLogin()" :loading="loading">ログイン</el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { AuthService } from '@/services/auth.service';
import { STATUS_CODE } from '@/config/constant';

export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: '',
      error: '',
      model: {
        email: '',
        password: ''
      },
      loading: false,
      remember_me: true,
      rules: {

      },
      fieldType: 'password'
    };
  },
  created () {
    if (this.$cookies.isKey('account') && typeof this.$cookies.get('account') === 'object') {
      this.model = { ...this.$cookies.get('account') };
      this.checked = true;
    }
  },
  methods: {
    async makeLogin () {
      try {
        this.loading = true;
        this.error = '';
        this.$refs.form.clearValidate(this.model)
        await AuthService.makeLogin({ ...this.model });
        if (this.remember_me) {
          this.$cookies.set('account', JSON.stringify({ ...this.model }));
        }
        // await this.$store.dispatch('user/getCurrent');
        await this.$router.push({ name: 'service-list' });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.code === STATUS_CODE.BAD_REQUEST) {
          console.log(error.data)
          this.showErrorMessage(error.data, 'form');
        }
        if (error.code === STATUS_CODE.EXPIRED) {
          this.error = error.data;
        }
      }
    }
  }
};
</script>

<style scoped>
.login {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title{
  font-weight: bold ;
}

.parent{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.forgot{
  color: black;
}

.child1 .forgot-password{
  color: #409eff;
}

.child2 .el-btn-login{
  background: #409eff;
  color:white;
}

.passwd{
  position: relative;
}
.btn-eye{
  border: none;
  background: white;
  height: 30px;
  position: absolute;
  right: 10px;
  bottom: 9px;
}

.login-button {
  width: 100%;
  margin-top: 40px;
}

.login-form {
  width: 400px;
}

.forgot-password {
  margin-top: 10px;
}
</style>

<style lang="scss" scoped>
$teal: rgb(0, 124, 137);
.el-button--primary {
  background: $teal;
  border-color: $teal;

  &:hover,
  &.active,
  &:focus {
    background: lighten($teal, 7);
    border-color: lighten($teal, 7);
  }
}

.login .el-input__inner:hover {
  border-color: $teal;
}

.login .el-input__prefix {
  background: rgb(238, 237, 234);
  left: 0;
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  border-radius: 3px;

  .el-input__icon {
    width: 30px;
  }
}

.login .el-input input {
  padding-left: 35px;
}

.login .el-card {
  padding-top: 0;
  padding-bottom: 30px;

  .logo-login {
    display: flex;
    justify-content: center;
  }
}

h2 {
  font-family: 'Open Sans';
  letter-spacing: 1px;
  font-family: Roboto, sans-serif;
  padding-bottom: 20px;
  text-align: center;
}

a {
  color: $teal;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: lighten($teal, 7);
  }
}

.login .el-card {
  display: flex;
  justify-content: center;
  width: 500px;
}

.error-message {
  color: #F56C6C;
  font-size: 12px;
}

.login {
  width: 100%;
  height: 100vh!important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/img/background_img.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  .main {
    padding: 30px 15px;
    background: #fff;
    width: 400px;
    border-radius: 2px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);

    .header {
      text-align: center;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
    }

    .error {
      background-color: red;
      padding: 10px;
      font-size: 12px;
      opacity: 1;
      transition: all 0.5s;
    }
  }
}
.login {
  display: flex;
  flex-direction: column;
}
.logo-asena {
  transform: scale(5) translateY(-10px);
  height: 42px;
  margin-bottom: 10px;
}
</style>
