import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class PolicyService extends BaseService {
  static get entity () {
    return ''
  }

  static async detailPolicy () {
    try {
      const response = await this.request({ auth: true }).get('policy/detail', { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async updatePolicy (params) {
    try {
      const response = await this.request({ auth: true }).post('policy/update', params, { withCredentials: false });
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
