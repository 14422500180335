<template>
  <div class="calendar" v-loading="loading">
    <el-card class="box-card" body-style="{ border-style: 'none' }">
      <div class="clearfix">
        <div>
          <h3>
            <div class="row">
              <div class="col-sm-12">
              <span>
                <router-link :to="{ name: 'staff-list'}" class="header-router-link">
                  シフトカレンダー
                </router-link>
              </span> /{{ staffName }}
              </div>
            </div>
          </h3>
        </div>
      </div>
      <div slot="body" style="padding: 0px"></div>
    </el-card>

    <el-calendar v-model="current_month">
      <template
        slot="dateCell"
        slot-scope="{date, data}">
        <div style="position: relative;" class="h-100 calendar-weight">
          <div v-if="isStoreWorkingDay(data.day) && isStaffOff(data.day)">
            <el-container  direction="vertical" :class="isStaffSchedule(data.day) ? 'color-day-blue-light' :'no-color-day'">
              <div type="primary" class="date block-date" style="background-color: rgb(2, 125, 180)">{{date.getDate()}} ({{formatDayOfWeek(date.getDay())}})</div>
              <br>
              <div style="height: 60px">
                <template v-for="schedule in shift_schedules">
                  <template v-if="schedule.date === data.day">
                    <el-row type="flex" justify="start" :key="schedule.id" class="check-browser">
                      <ul class="list-shifts pb-1" v-if="schedule.shifts.length" >
                        <div :class="(schedule.shifts.length > 3) ? 'scroll-bar' : ''"  style="height: 50px">
                          <li v-for="shift in schedule.shifts" :key="schedule.shifts.indexOf(shift)">
                            {{moment('1990-01-01 '+ shift.start_time).format('HH:mm')}} - {{moment('1990-01-01 '+ shift.end_time).format('HH:mm')}}
                          </li>
                        </div>
                      </ul>
                    </el-row>
                  </template>
                </template>
              </div>
              <el-row type="flex" justify="center">
                <el-button v-if="data.type === 'current-month'" type= "warning" size="mini" @click="handleOneDayEditBtn(data.day)">編集</el-button>
              </el-row>
            </el-container>
          </div>

          <el-container v-else-if="isStoreWorkingDay(data.day) && !isStaffOff(data.day)" direction="vertical" class="no-color-day" >
            <div type="primary" class="date block-date" style="background-color: rgb(2, 125, 180)">{{date.getDate()}} ({{formatDayOfWeek(date.getDay())}})</div>
            <br>
            <div style="height: 60px">
              <el-row type="flex" justify="center" >
                <div style="color: rgb(217, 0, 27)">欠勤</div>
              </el-row>
            </div>
            <el-row type="flex" justify="center">
              <el-button v-if="data.type === 'current-month'" type= "warning" size="mini" @click="handleEditBtn(data.day)">編集</el-button>
            </el-row>

          </el-container>

          <el-container v-else direction="vertical">
            <div type="primary" class="date block-date color-day-grey" >{{date.getDate()}} ({{formatDayOfWeek(date.getDay())}})</div>
            <br>
            <el-row type="flex" justify="center">
              <div style="color: red">休み</div>
            </el-row>
          </el-container>
        </div>
      </template>
    </el-calendar>

    <el-dialog :visible.sync="showModal" @close="closeForm('form')">
      <el-form ref="form" label-width="100px" label-position="left">
        <el-form-item label="日付">
          <el-date-picker
            type="date"
            placeholder="pick a date"
            v-model="form.date"
            class="date-picker"
            value-format="yyyy-MM-dd"
            disabled
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="出勤状態">
          <el-row class="ml-8">
            <el-radio v-model="form.status" :label="parseInt(staff_status[1].value)" style="color: rgb(3, 131, 191)">出勤</el-radio>
            <el-radio v-model="form.status" :label="parseInt(staff_status[0].value)" style="color: rgb(217, 0, 27)">欠勤</el-radio>
          </el-row>
        </el-form-item>

        <el-form-item label="営業時間" prop="parent" v-if="form.status === 1">
          <div v-for="(value, key) in form.shifts" :key="key">
            <div :class=" key === 0 ? 'ml-4 row set-hour' :'ml-4 pt-4 row set-hour'">
              <div class="col-5">
                <el-form-item :prop="'shifts.shifts.' + key +'.start_time'" >
                  <el-time-select
                    placeholder="HH:MM"
                    style="width: 100%"
                    v-model="value.start_time"
                    :picker-options="{
                    start: store_start_time,
                    step: '00:15',
                    end: store_end_time,
                  }"
                  ></el-time-select>
                </el-form-item>
              </div>
              <div class="line col-1">~</div>
              <div class="col-5">
                <el-form-item :prop="'shifts.shifts.' + key +'.end_time'">
                  <el-time-select
                    placeholder="HH:MM"
                    style="width: 100%"
                    v-model="value.end_time"
                    :picker-options="{
                    start: store_start_time,
                    step: '00:15',
                    end: store_end_time
                  }"
                  >
                  </el-time-select>
                </el-form-item>
              </div>
              <div class="col-1" v-if="key > 0">
                <label @click.prevent="removeShift(value)" class=" btn btn-danger btn-delete-combo"> X </label>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item class="m-btn-add-shift-time pt-4" v-if="form.status === 1">
          <el-button type="text" @click="addShift" class="btn-add-shift-time" v-if="form.shifts.length < 5">
            <i class="el-icon-plus icon-add"></i>追加する
          </el-button>
        </el-form-item>

        <el-form-item class="mtl-10">
          <el-row type="flex" justify="space-around" class="pr-110">
              <el-button plain class="btn-cancel btn-form" @click="closeForm('form')">キャンセル</el-button>
              <el-button class="btn-form" type="primary" @click="createSchedule()">登録</el-button>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="showEditModal" @close="closeEditForm()">
      <el-form ref="editForm" label-width="100px" label-position="left">
        <el-form-item label="日付">
          <el-date-picker
            type="date"
            placeholder="pick a date"
            format ="yyyy-MM-dd"
            v-model ="editForm.date"
            class="date-picker"
            disabled
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="出勤状態">
          <el-row class="ml-8 stt-radio">
            <el-radio v-model="editForm.status" :label="1" style="color: rgb(3, 131, 191)">出勤</el-radio>
            <el-radio v-model="editForm.status" :label="0" style="color: rgb(217, 0, 27)">欠勤</el-radio>
          </el-row>
        </el-form-item>

        <div v-if="editForm.status === 1">
          <el-form-item label="営業時間" prop="parent">
            <div v-for="(value, key) in editForm.shifts" :key="key">
              <div style="" :class=" key === 0 ? 'ml-4 row set-hour' :'ml-4 pt-4 row set-hour'">
                <div class="col-5">
                  <el-form-item :prop="'shifts.shifts.'+key+'.start_time'">
                    <el-time-select
                      placeholder="HH:MM"
                      value-format="HH:mm"
                      style="width: 100%"
                      v-model="value.start_time"
                      :picker-options="{
                      start: store_start_time,
                      step: '00:15',
                      end: store_end_time,
                    }"
                    ></el-time-select>
                  </el-form-item>
                </div>
                <div class="col-1 tilde">~</div>
                <div class="col-5">
                  <el-form-item :prop="'shifts.shifts.'+key+'.end_time'">
                    <el-time-select
                      placeholder="HH:MM"
                      value-format="HH:mm"
                      style="width: 100%"
                      v-model="value.end_time"
                      :picker-options="{
                      start: store_start_time,
                      step: '00:15',
                      end: store_end_time
                    }"
                    >
                    </el-time-select>
                  </el-form-item>
                </div>
                <div class="col-1" v-if="key > 0">
                  <label @click.prevent="removeShift(value)" class=" btn btn-danger btn-delete-combo"> X </label>
                </div>
              </div>
            </div>
          </el-form-item>

          <el-form-item class="m-btn-add-shift-time">
            <el-button type="text" @click="addShift" class="btn-add-shift-time pt-4" v-if="editForm.shifts.length < 5">
              <i class="el-icon-plus icon-add" style="font-size: 14px; margin-right: 5px"></i>追加する</el-button>
          </el-form-item>
        </div>

        <el-form-item class="mtl-10">
          <el-row type="flex" justify="space-around" class="pr-110">
              <el-button plain class="btn-cancel btn-form" @click="closeEditForm"
                >キャンセル</el-button
              >
              <el-button class="btn-form" type="primary" @click="editSchedule()"
                >保存</el-button
              >
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { ShiftService } from '@/services/shift.service'
import { StaffService } from '@/services/staff.service'

import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import IsBetween from 'dayjs/plugin/isBetween'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import moment from 'moment';

import message from '../../message/message'
import { STORE_START_TIME, STORE_END_TIME, STAFF_SCHEDULE_STATUS } from '../../config/constant';

dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.extend(IsBetween)
dayjs.extend(customParseFormat)

export default {
  data () {
    return {
      loading: false,
      staffName: '',
      showModal: false,
      showEditModal: false,
      message: message,
      errors: [],
      moment: moment,
      staff_status: STAFF_SCHEDULE_STATUS,
      form: {
        date: '',
        status: 1,
        shifts: [
          {
            start_time: '',
            end_time: ''
          }
        ]
      },
      shifts: [
        {
          start_time: '',
          end_time: ''
        }
      ],
      editForm: {
        id: '',
        date: '',
        status: 1,
        shifts: [
          {
            start_time: '',
            end_time: ''
          }
        ]
      },
      shift_schedules: [],
      store_schedules: [],
      current_month: dayjs().format('YYYY-MM'),
      store_start_time: STORE_START_TIME,
      store_end_time: STORE_END_TIME,
      off_start_time: '',
      off_end_time: ''
    }
  },
  watch: {
    '$route.params': {
      handler: function () {
        this.getData()
        this.getStoreSchedules()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    isStoreWorkingDay (date) {
      for (let i = 0; i < this.store_schedules.length; i++) {
        if (this.store_schedules[i].date === date) {
          if (this.store_schedules[i].status === 0) {
            return false
          }
        }
      }
      return true
    },
    isStaffOff (date) {
      for (let i = 0; i < this.shift_schedules.length; i++) {
        if (this.shift_schedules[i].date === date) {
          if (this.shift_schedules[i].status === 0) {
            return false
          }
        }
      }
      return true
    },
    closeForm (formName) {
      this.form = {
        date: '',
        shifts: [
          {
            start_time: '',
            end_time: ''
          }
        ]
      }
      this.errors = []
      this.$refs[formName].resetFields();
      this.showModal = false;
    },
    handleEditBtn (cellDate) {
      this.form.date = cellDate
      this.form.status = this.staff_status[0].value
      const schedule = this.store_schedules;
      for (let i = 0; i < schedule.length; i++) {
        if (cellDate === schedule[i].date) {
          this.store_start_time = (schedule.length !== 0 && schedule.work_start_time) ? moment('1990-01-01 ' + schedule[i].work_start_time).format('HH:mm') : STORE_START_TIME
          this.store_end_time = (schedule.length !== 0 && schedule.work_end_time) ? moment('1990-01-01 ' + schedule[i].work_end_time).format('HH:mm') : STORE_END_TIME
          if (schedule[i].off_start_time !== null) {
            this.off_start_time = moment('1990-01-01 ' + schedule[i].off_start_time).format('HH:mm')
          }
          if (schedule[i].off_end_time !== null) {
            this.off_end_time = moment('1990-01-01 ' + schedule[i].off_end_time).format('HH:mm')
          }
        }
      }
      this.form.shifts = JSON.parse(JSON.stringify(this.form.shifts))
      this.showModal = true
      if (this.$refs.form) {
        this.$refs.form.clearValidate(this.form)
      }
    },
    handleOneDayEditBtn (day) {
      let store_schedule = []
      this.editForm.date = day
      if (this.store_schedules.length !== 0) {
        if (this.store_schedules.filter(store_schedule => store_schedule.date === day).length !== 0) {
          store_schedule = this.store_schedules.filter(store_schedule => store_schedule.date === day)[0]
        }
      }
      this.store_start_time = (store_schedule.length !== 0 && store_schedule.work_start_time) ? moment('1990-01-01 ' + store_schedule.work_start_time).format('HH:mm') : STORE_START_TIME
      this.store_end_time = (store_schedule.length !== 0 && store_schedule.work_end_time) ? moment('1990-01-01 ' + store_schedule.work_end_time).format('HH:mm') : STORE_END_TIME
      this.editForm.status = this.staff_status[1].value
      let schedule = null;
      for (let i = 0; i < this.shift_schedules.length; i++) {
        if (this.shift_schedules[i].date === day) {
          schedule = this.shift_schedules[i]
        }
      }
      if (schedule !== null) {
        if (schedule.shifts.length) {
          for (let i = 0; i < schedule.shifts.length; i++) {
            this.editForm.shifts[i] = {
              start_time: moment('1990-01-01 ' + schedule.shifts[i].start_time).format('HH:mm'),
              end_time: moment('1990-01-01 ' + schedule.shifts[i].end_time).format('HH:mm')
            }
          }
        }
        this.editForm.id = schedule.id
      }
      this.editForm.shifts = JSON.parse(JSON.stringify(this.editForm.shifts))
      this.showEditModal = true
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate(this.editForm)
      }
    },
    closeEditForm () {
      this.editForm = {
        id: '',
        date: '',
        status: 1,
        shifts: [
          {
            start_time: '',
            end_time: ''
          }
        ]
      }
      this.errors = []
      this.showEditModal = false
    },
    async getData () {
      try {
        this.loading = true;
        const { data } = await ShiftService.getSchedules(this.$route.params.staffId)
        this.shift_schedules = data
      } catch (e) {
        this.loading = false
        this.$notify.error({
          title: 'Error',
          message: e.message,
          showClose: false
        });
      } finally {
        this.loading = false;
      }
    },
    async getStoreSchedules () {
      try {
        this.loading = true;
        const staff = await StaffService.getStaffDetail(this.$route.params.staffId)
        this.staffName = staff.data.name
        const { data } = await ShiftService.getStoreSchedules(staff.data.store_id)
        this.store_schedules = data
      } catch (e) {
        this.$notify.error({
          title: 'Error',
          message: e.message,
          showClose: false
        });
      } finally {
        this.loading = false;
      }
    },
    addShift () {
      if (this.showModal === true) {
        this.form.shifts.push({
          start_time: '',
          end_time: ''
        });
      }

      if (this.showEditModal === true) {
        this.editForm.shifts.push({
          start_time: '',
          end_time: ''
        })
      }
    },

    removeShift (item) {
      if (this.showModal === true) {
        const index = this.form.shifts.indexOf(item);
        if (index !== -1) {
          this.form.shifts.splice(index, 1);
        }
      }

      if (this.showEditModal === true) {
        const idx = this.editForm.shifts.indexOf(item);
        if (idx !== -1) {
          this.editForm.shifts.splice(idx, 1);
        }
      }
    },

    async editSchedule () {
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate(this.editForm)
      }
      this.loading = true;
      const query = {}
      query.user_id = this.$route.params.staffId
      query.shifts = {}
      query.shifts.date = this.editForm.date
      query.shifts.status = this.editForm.status
      if (this.editForm.status === 1) {
        query.shifts.shifts = this.editForm.shifts
      }
      if (this.checkError(this.editForm)) {
        try {
          const data = await ShiftService.editSchedule(query)
          if (data.status === 200) {
            this.closeEditForm()
            this.$notify.success({
              title: '成功',
              message: '更新できました。',
              type: 'success'
            });
            this.getData()
            this.getStoreSchedules()
          }
          this.loading = false;
        } catch (error) {
          if (error.code === 422) {
            this.showErrorMessage(error.data, 'editForm', false)
          } else {
            this.$notify.error({
              message: error.message,
              showClose: false
            })
          }
          this.loading = false;
        }
      } else {
        this.showErrorMessage(this.errors, 'editForm')
      }
      this.loading = false;
    },
    async createSchedule () {
      if (this.$refs.form) {
        this.$refs.form.clearValidate(this.form)
      }
      if (this.checkError(this.form)) {
        try {
          this.loading = true;
          const query = {}
          query.user_id = this.$route.params.staffId
          query.shifts = {}
          query.shifts.date = this.form.date
          query.shifts.status = this.form.status
          if (this.form.status === 1) {
            query.shifts.shifts = this.form.shifts
          }
          const data = await ShiftService.createSchedule(query)
          if (data.status === 200) {
            this.closeForm('form')
            this.$notify.success({
              title: '成功',
              message: '作成できました。',
              type: 'success'
            });
            this.getData()
            this.getStoreSchedules()
          }
          this.loading = false;
        } catch (error) {
          if (error.code === 422) {
            this.showErrorMessage(error.data, 'form')
          } else {
            this.$notify.error({
              message: error.message,
              showClose: false
            })
          }
          this.loading = false;
        }
      } else {
        this.showErrorMessage(this.errors, 'form')
      }

      this.loading = false;
    },

    isStaffSchedule (date) {
      for (let i = 0; i < this.shift_schedules.length; i++) {
        if (this.shift_schedules[i].date === date) {
          return true
        }
      }
      return false
    },

    checkError (formName) {
      this.errors = []
      let err = true;
      const storeStartTime = dayjs(this.store_start_time, 'hh:mm')
      const storeEndTime = dayjs(this.store_end_time, 'hh:mm')
      if (this.off_start_time && this.off_end_time) {
        const offStartTime = dayjs(this.off_start_time, 'hh:mm')
        const offEndTime = dayjs(this.off_end_time, 'hh:mm')

        // check if shifts are all in store working time
        for (let i = 0; i < formName.shifts.length; i++) {
          if (formName.shifts[i].start_time !== null && formName.shifts[i].end_time) {
            const startTime = dayjs(formName.shifts[i].start_time, 'hh:mm')
            const endTime = dayjs(formName.shifts[i].end_time, 'hh:mm')
            if (!((startTime >= storeStartTime && endTime <= offStartTime) ||
              (startTime >= offEndTime && endTime <= storeEndTime))) {
              this.errors['shifts.shifts.' + (i + 1) + '.start_time'] = [];
              this.errors['shifts.shifts.' + (i + 1) + '.start_time'].push(this.message.M51)
              err = false;
            }
          }
        }
      }
      // check if shifts' time are overlap
      for (let i = 0; i < formName.shifts.length; i++) {
        for (let j = 0; j < i; j++) {
          const endTime = dayjs(formName.shifts[i].end_time, 'hh:mm')
          const startTime = dayjs(formName.shifts[i].start_time, 'hh:mm')
          const startTimeNextShift = dayjs(formName.shifts[j].start_time, 'hh:mm')
          const endTimeNextShift = dayjs(formName.shifts[j].end_time, 'hh:mm')
          if ((startTime < startTimeNextShift && endTime > startTimeNextShift) || (startTime < endTimeNextShift && endTime > endTimeNextShift)) {
            this.errors['shifts.shifts.' + (j + 1) + '.start_time'] = [];
            this.errors['shifts.shifts.' + (j + 1) + '.start_time'].push(this.message.M51)
            err = false;
          }
        }
      }

      return err;
    },

    formatDayOfWeek (date) {
      if (date === 1) {
        return '月'
      } else if (date === 2) {
        return '火'
      } else if (date === 3) {
        return '水'
      } else if (date === 4) {
        return '木'
      } else if (date === 5) {
        return '金'
      } else if (date === 6) {
        return '土'
      } else if (date === 0) {
        return '日'
      }
    }
  }
}
</script>

<style scoped>
.time-selector {
  padding: 0px 0px 0px 20px;
}
.btn-confirm {
  color: #fff;
  background-color: rgb(3, 167, 239);
  border-color: rgb(3, 167, 239);
  padding-right: 42px;
  padding-left: 42px;

  border-width: 2px;
  border-radius: 5px;
}
.btn-cancel {
  color: rgb(80, 142, 175);
  border-color: rgb(80, 142, 175);

  border-width: 2px;
  border-radius: 5px;
}
.btn-form {
  width: 120px;
}
.btn-add-shift-time {
  color: rgb(115, 181, 14);
}
.btn-shift-add {
  float: right;
}
.el-calendar-table .el-calendar-day {
  height: 100px !important;
}
.header-router-link {
  background-color: #ffffff !important;
  color: #00b0ff !important;
}
.date {
  font-weight: 400;
}
.list-shifts {
  margin: -20px 0px 0px 0px;
  padding: 0px 20px;
  min-height: 44px;
  list-style-type: none;
  text-align: center;
  width: 100%;
}
.date-picker {
  width: 90% !important;
  margin: 0px 0px 0px 10px !important;
}
.pl-20 {
  padding: 0px 0px 0px 20px !important;
}
.mt-3 {
  margin: 3px 0px 0px 0px !important;
}
.icon-add {
  font-size: large;
  margin-right: 5px;
  border: 1px solid rgb(115, 181, 14);
  border-radius: 5px;
}
.m-btn-add-shift-time {
  margin: -22px 0px 0px 10px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.mtl-10 {
  margin: 10px 0px 0px 10px !important;
}
.pr-110 {
  padding-right: 110px !important;
}
.ml-8 {
  margin: 0px 0px 0px 8px !important;
}
::v-deep .el-calendar-table thead {
  display: none;
}
::v-deep .el-calendar-table .el-calendar-day {
  height: 115px;
}
::v-deep .el-row--flex {
  min-height: 30px;
}
::v-deep .el-calendar-day  {
  padding: 0 !important;
}
.block-date {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid grey;
  color: white;
}
.color-day-blue-light {
  height: 116px !important;
  background-color: rgb(205, 237, 252)
}
.no-color-day{
  height: 116px !important;
}
.color-day-blue-weight {
  height: 116px !important;
  background-color: rgb(2, 125, 180)
}
.color-day-grey {
  background-color: grey;
}
.calendar-weight {
  font-weight: bold;
}
.scroll-bar:hover {
  -ms-overflow-style: none;
  overflow-y: scroll;
  scrollbar-width: none;
}
.scroll-bar {
  overflow-y: hidden;
}
.scroll-bar::-webkit-scrollbar {
  display: none;
}
::v-deep.el-icon-circle-close {
  font-size: 33px;
}
::v-deep.el-button .el-button--danger .is-circle {
  padding: 0;
}
.set-hour{
  padding-left: 0;
  margin-left: 0;
}
.stt-radio{
  margin-left: 10px !important;
}
.tilde{
  text-align: center;
}
::v-deep .el-form-item__error {
  white-space: nowrap;
}
</style>
