/**
 * Jump to some DOM element
 */
export default {
  methods: {
    $jumpTo (selector, focus) {
      this.$nextTick(() => {
        const el = document.querySelectorAll(selector)[0]
        if (el && focus) {
          el.focus()
        } else {
          throw new Error('Element not supplied.')
        }
      })
    },
    // use with only status 422
    showErrorMessage (errors, formName = undefined, focus = true) {
      this.formShowErrorMessage(errors, formName, focus)
    },
    formShowErrorMessage (errors, formName, focus) {
      const fieldError = Object.keys(errors)
      this.$refs[formName].$children.forEach((children) => {
        if (children.prop === 'parent') {
          if (fieldError.includes(children.prop)) {
            children.validateState = 'error'
            children.validateMessage = errors[children.prop][0]
          }
          children.$children.forEach((subChildren) => {
            subChildren.$children.forEach((tinyChildren) => {
              tinyChildren.$children.forEach((tinyTinyChild) => {
                if (!fieldError.includes(tinyTinyChild.prop)) {
                  return
                }
                tinyTinyChild.validateState = 'error'
                tinyTinyChild.validateMessage = errors[tinyTinyChild.prop][0]
              })
              if (!fieldError.includes(tinyChildren.prop)) {
                return
              }
              tinyChildren.validateState = 'error'
              tinyChildren.validateMessage = errors[tinyChildren.prop][0]
            })
            if (!fieldError.includes(subChildren.prop)) {
              return
            }
            subChildren.validateState = 'error'
            subChildren.validateMessage = errors[subChildren.prop][0]
          })
        }
        if (!fieldError.includes(children.prop)) {
          if (fieldError.includes('data')) {
            this.notifyError(errors.data)
          }
          return
        }
        children.validateState = 'error'
        children.validateMessage = errors[children.prop][0] ?? ''
      })
      this.$jumpTo(':scope .is-error .el-input__inner', focus)
    },
    resetForm (formName) {
      if (this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields();
      }
    },
    turnOffErrorMessage (formName) {
      this.$refs[formName].$children.forEach((children) => {
        children.$children.forEach((subChildren) => {
          if (subChildren.validateState) {
            subChildren.validateState = ''
            subChildren.validateMessage = ''
          }
          subChildren.$children.forEach((tinyChildren) => {
            if (tinyChildren.validateState) {
              tinyChildren.validateState = ''
              tinyChildren.validateMessage = ''
            }
          })
        })
        if (children.validateState) {
          children.validateState = ''
          children.validateMessage = ''
        }
      })
    }
  }

}
