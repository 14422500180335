<template>
  <div v-loading.fullscreen.lock="loading" class="login">
    <img class="logo-asena" src="@/assets/img/logo-new.png" alt="">
    <el-card class="border-reset-password mt-3">
      <h3 class="text-center introduce-text body-forgot-pass">パスワード再設定依頼</h3>
      <el-form
        ref="form"
        :model="model"
        :rules="rules"
      >
        <p>本アプリに登録したメールアドレスを入力してください。</p>
        <p class="introduce-text">メールアドレス<span class="mandatory">*</span></p>
        <el-form-item prop="email">
          <el-input
            v-model="model.email"
            placeholder="入力してください"
            maxLength="200"
            @input.native="(e)=>{lengthInput(e, 200)}"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin:30px 0 0 0">
          <div class="reset-buton">
            <el-button class="reset-buton" style="margin: auto" type="primary" @click="sendMail">送信</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-card>

    <el-dialog
      :before-close="handleClose"
      :visible.sync="dialogVisible"
      width="20%"
    >
      <span style="font-weight: bold">パスワード再設定のメールを送信します。
        <br>
        ２４時間以内にメールに記載されたりリンクをクリックし手続きを行ってください。<br>
        </span>
      <br>
      <span style="font-weight: 400">メールが届かない場合、迷惑メールフォルダーなどもご確認ください。</span>
      <span slot="footer" class="dialog-footer button-footer">
        <el-button type="primary" @click="handleClose"
        >ログイン画面へ</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { API_URL } from '../.env';
import * as CONSTANT from '../config/constant'

export default {
  name: 'forgetPassword',
  data () {
    return {
      error: '',
      email: 'user@user.com',
      model: {
        email: ''
      },
      dialogVisible: false,
      loading: false,
      rules: {}
    };
  },

  methods: {
    lengthInput (e, maxLength) {
      if (e.target.value.length > maxLength) {
        e.target.value = e.target.value.substr(0, maxLength)
      }
    },
    async sendMail () {
      try {
        this.loading = true;
        this.$refs.form.clearValidate(this.model)
        const valid = await this.$refs.form.validate();
        if (!valid) {
          return;
        }
        const email = this.model.email
        await axios.post(API_URL + '/forgot-password',
          { email: email },
          { withCredentials: false })
          .then(response => {
            if (response.data.code === CONSTANT.STATUS_CODE.SUCCESS) {
              this.dialogVisible = true
            }
          })
          .catch(error => {
            console.log(error.response.data.code)
            if (error.response.data.code === CONSTANT.STATUS_CODE.BAD_REQUEST) {
              this.showErrorMessage(error.response.data.data, 'form');
            }
          });

        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.code === CONSTANT.STATUS_CODE.BAD_REQUEST) {
          this.showErrorMessage(error.data, 'form');
        }
      }
    },
    handleClose () {
      this.dialogVisible = false
      this.$router.push({ name: 'login' })
    }

  }
};
</script>

<style scoped>

.login {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-button {
  width: 100%;
  margin-top: 40px;
}

.login-form {
  width: 290px;
}

.forgot-password {
  margin-top: 10px;
}
</style>
<style lang="scss" scoped>
$teal: rgb(0, 124, 137);

.login .el-input__inner:hover {
  border-color: $teal;
}

.login .el-input__prefix {
  background: rgb(238, 237, 234);
  left: 0;
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  border-radius: 3px;

  .el-input__icon {
    width: 30px;
  }
}

.login .el-input input {
  padding-left: 35px;
}

.login .el-card {
  padding-top: 0;
  padding-bottom: 30px;

  .logo-login {
    display: flex;
    justify-content: center;
  }
}

h2 {
  font-family: 'Open Sans';
  letter-spacing: 1px;
  font-family: Roboto, sans-serif;
  padding-bottom: 20px;
  text-align: center;
}

a {
  color: $teal;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: lighten($teal, 7);
  }
}

.login .el-card {
  display: flex;
  justify-content: center;
  width: 500px;
}

.login {
  width: 100%;
  height: 100vh!important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/img/background_img.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  .main {
    padding: 30px 15px;
    background: #fff;
    width: 400px;
    border-radius: 2px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);

    .header {
      text-align: center;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
    }

    .error {
      background-color: red;
      padding: 10px;
      font-size: 12px;
      opacity: 1;
      transition: all 0.5s;
    }
  }
}
.login {
  display: flex;
  flex-direction: column;
}
.logo-asena {
  transform: scale(5) translateY(-10px);
  height: 42px;
}
</style>
