import { UsersService } from '@/services/users.service'
import { AuthService } from '@/services/auth.service'
// import { STATUS_CODE } from '@/config/constant'

export default {
  getCurrent ({ commit }) {
    return UsersService.getCurrent()
      .then(user => {
        console.log(user)
        commit('SET_CURRENT_USER', user.data.user)
      })
      .catch(error => {
        if (error.message === 'offline') {
          location.reload();
        } else {
          AuthService.resetToken();
        }
      })
  }
}
