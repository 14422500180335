<template>
  <div v-loading="loading">
    <el-card class="box-card" >
      <div slot="header" class="clearfix">
        <h3 style="font-weight: bold">メニュー管理</h3>
      </div>
      <el-form ref="form" :model="form" label-width="160px" label-position="left">
        <el-row>
          <el-col :span="12">
            <el-form-item label="キーワード" class="el-label-customer">
              <el-input
                v-model="form.keyword"
                placeholder="キーワード">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <div class="el-checkbox-width">
                <el-radio-group v-model="form.keyword_type">
                  <el-radio :label="1" :value="1">メニューコード</el-radio>
                  <el-radio :label="2" :value="2">メニュー名</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="タイプ" class="el-label-customer">
          <div class="el-checkbox-width">
            <el-radio-group v-model="form.service_combo_type">
              <el-radio v-for="(value, index) in service_combo_type" :key="index"
                           :label="value.id" :value="value.id" :class="value.className">{{ value.name }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item label="カテゴリー" class="el-label-customer">
          <div class="el-checkbox-width">
            <el-checkbox-group v-model="form.category">
              <el-checkbox v-for="(value, index) in category" :key="index"
                           :label="value.id" :value="value.id">{{ value.name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>

        <el-form-item label="利用店舗名" class="el-label-customer">
          <div class="el-checkbox-width">
            <el-radio-group v-model="form.store">
              <el-radio v-for="(value, index) in store" :key="index"
                           :label="value.id" :value="value.id">{{ value.name }}
              </el-radio>
<!--              <el-radio :label="0" :value="0">すべて-->
<!--              </el-radio>-->
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item label="非表示のステータス" class="el-label-customer">
          <div class="el-checkbox-width">

            <el-checkbox-group v-model="form.service_block">
              <el-checkbox v-for="(value, index) in service_block" :key="index" :style="{'color': value.color}"
                           :label="value.id" :class="value.className">{{ value.name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>

        <el-form-item label="メニューのステータス" class="el-label-customer">
          <div class="el-checkbox-width">
            <el-checkbox-group v-model="form.service_status">
              <el-checkbox v-for="(value, index) in service_status" :key="index" :style="{'color': value.color}"
                           :label="value.id" :value="value.id" :class="value.className">{{ value.name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>

        <button-common class="customer-confirm-delete" v-on:cancle="resetSearch('reset_click')" v-on:submit="applySearch" btnOk="検索" btnCancel="リセット">
        </button-common>

      </el-form>
      <el-divider></el-divider>
      <el-row>
        <button-common v-on:cancleConfirm="openDialog" v-on:submitConfirm="createComboService" type="confirm"
                       btnOk="メニュー追加" btnCancel="メニュー削除">
        </button-common>
      </el-row>
      <div>{{data_list.length}}件</div>
      <div v-if="showErrDelete" class="mandatory">削除するアカウントを選択してください。</div>
      <el-table
        ref="multipleTable"
        :data="current_data_page"
        :fit="true"
        :header-row-class-name="'background-table'"
        border
        @selection-change="handleSelectionChange"

      >
        <el-table-column :resizable="false" label="すべて" type="selection" align="center" width="100px">
        </el-table-column>
        <el-table-column :resizable="false" label="メニューコード" prop="code" width="120px" align="center"></el-table-column>
        <el-table-column :resizable="false" label="タイプ" width="100px" align="center">
          <template slot-scope="scope"> {{ service_combo_type[scope.row.type - 1].name }}</template>
        </el-table-column>
        <el-table-column :resizable="false" label="非表示のステータス" width="170px" align="center">
          <template slot-scope="scope">
            <p :class="service_block[scope.row.block].className">{{ service_block[scope.row.block].name }}</p>
          </template>
        </el-table-column>
        <el-table-column :resizable="false" label="メニューのステータス" width="200px" align="center">
          <template slot-scope="scope" >
            <p :class="service_status[1].className" v-if="scope.row.status === service_status[1].id">{{ service_status[1].name }}</p>
            <p :class="service_status[0].className" v-else>{{ service_status[0].name }}</p>
          </template>
        </el-table-column>
        <el-table-column :resizable="false" label="メニュー名" align="center" width="200px">
          <template slot-scope="scope"> {{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column :resizable="false" label="カテゴリー" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.category_id">{{ scope.row.category.name }}</div>
            <div v-else> -</div>
          </template>
        </el-table-column>
        <el-table-column :resizable="false" label="利用店舗名" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.store_id !== 0">{{ scope.row.store ? scope.row.store.name : '' }}</div>
            <div v-else>すべて</div>
          </template>
        </el-table-column>
        <el-table-column :resizable="false" label="料金" align="center">
          <template slot-scope="scope">
            <div v-for="(value, index) in scope.row.process" :key="index">{{ formatNumber(value.price) }}円
            </div>
          </template>
        </el-table-column>
        <el-table-column :resizable="false" label="施術時間" align="center">
          <template slot-scope="scope">
            <div v-for="(value, index) in scope.row.process" :key="index">{{ formatNumber(value.use_time) }}分
            </div>
          </template>
        </el-table-column>
        <el-table-column :resizable="false" align="center">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center;">
              <el-button v-if="scope.row.type === 1" type="primary" @click="$router.push({name: 'service-edit', params: {serviceId: scope.row.id}})">編集</el-button>
              <el-button v-else type="primary" @click="$router.push({name: 'combo-edit', params: {comboId: scope.row.id}})">編集</el-button>

            </div>
          </template>
        </el-table-column>
        <el-table-column :resizable="false" align="center" width="170px">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center;">
              <el-button v-if="scope.row.block === service_block[0].id" style="margin: 10px" type="danger" @click="blockService(service_combo_type[scope.row.type - 1].id, scope.row.id, scope.row.block)"> 非表示
              </el-button>
              <el-button v-else type="success" style="margin: 10px" @click="blockService(service_combo_type[scope.row.type - 1].id, scope.row.id, scope.row.block)">
                非表示対象
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <div v-if="data_list.length > 0">
          <el-pagination
            :page-size="page_size"
            :total="data_list.length"
            :current-page.sync="form.page"
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            align="right"
          >
          </el-pagination>
        </div>
      </div>

    </el-card>
    <el-dialog
      :before-close="handleCloseDialog"
      :visible.sync="dialogVisible"
      width="20%"
      :closeOnClickModal="false"
      :class="{ 'can-not_delete' : !can_delete}"
    >
      <h5 class="textwidget text-center title-header-customer">{{msg}}
        </h5>
      <span slot="footer" class="dialog-footer button-footer">
        <el-button @click="handleCloseDialog">{{ can_delete ? 'キャンセル' : 'OK' }}</el-button>
        <el-button v-if="can_delete === true" type="danger" @click="deleteServiceCombo" id="w-button">確認</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import * as CONSTANT from '../../config/constant'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin';
import { LIMIT_PAGINATE } from '../../config/constant';
import { ServicesService } from '../../services/services.service';

export default {
  mixins: [prepareQueryParamsMixin],

  async created () {
    this.getDataFromQuery();
    await this.getData()
  },

  computed: {
    queryParam () {
      return this.$route.query;
    }
  },

  watch: {
    queryParam () {
      if (this.queryParam && Object.keys(this.queryParam).length === 0) {
        this.resetSearch('reset_load');
      } else {
        this.getDataFromQuery();
      }
      this.getData()
      if (this.queryParam.page) {
        this.form.page = this.queryParam.page
      }
    }
  },

  data () {
    return {
      dialogVisible: false,
      loading: false,
      service_list: [],
      combo_list: [],
      data_list: [],
      category: [],
      store: [],
      service_block: CONSTANT.BLOCK,
      service_status: CONSTANT.STATUS,
      service_combo_type: CONSTANT.SERVICE_COMBO_TYPE,
      page_size: LIMIT_PAGINATE,
      total_page: 1,
      current_data_page: [],
      checked: [],
      can_delete: true,
      msg: '本当に削除しますか？',
      form: {
        page: 1,
        keyword: '',
        keyword_type: 1,
        category: [],
        store: '',
        service_block: [],
        service_combo_type: '',
        service_status: []
      },
      showErrDelete: false
    }
  },
  methods: {
    handleCloseDialog () {
      this.dialogVisible = false
    },

    handleCurrentChange (val) {
      this.form.page = val
      this.$router.push({
        name: 'service-list',
        query: { ...this.queryParam, ...this.form }
      }).catch(() => {
      });
    },

    handleSelectionChange (val) {
      this.showErrDelete = false
      this.checked = [...val]
    },

    getDataFromQuery () {
      Object.keys(this.queryParam).map((value) => {
        if (value === 'category' || value === 'service_block' || value === 'service_status') {
          if (typeof this.queryParam[value] === 'object') {
            const data = this.queryParam[value]
            Object.keys(data).map((ele, index) => {
              data[index] = parseInt(data[index])
            })
            this.form[value] = [...data];
          } else {
            const data = this.queryParam[value]
            this.form[value].push(parseInt(data))
          }
        } else {
          if (value !== 'keyword') {
            this.form[value] = parseInt(this.queryParam[value]);
          } else {
            this.form[value] = this.queryParam[value];
          }
        }
      });
    },

    async getData () {
      try {
        this.loading = true
        const data = await ServicesService.getlist(this.form)
        this.data_list = []
        this.store = data.data.store
        this.category = data.data.category
        this.service_list = data.data.services
        this.service_list.forEach(element => {
          element.type = this.service_combo_type[0].id;
          if (element.name.length >= 20) {
            element.name = element.name.substr(0, 20)
            element.name = element.name + '...'
          }
          this.data_list.push(element)
        });
        this.combo_list = data.data.combos
        this.combo_list.forEach(element => {
          element.type = this.service_combo_type[1].id;
          if (element.name.length >= 20) {
            element.name = element.name.substr(0, 20)
            element.name = element.name + '...'
          }
          this.data_list.push(element)
        });
        this.data_list = this.data_list.slice().sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        this.data_list = this.data_list.slice().sort(function (a, b) {
          return parseInt(b.status) - parseInt(a.status);
        });
        this.total_page = Math.ceil(this.data_list.length / this.page_size)
        this.paginate()
        this.loading = false
      } catch (e) {
        this.loading = false
        this.$notify.error({
          title: 'Error',
          message: e.message,
          showClose: false
        });
      }
    },

    paginate () {
      const offset = (this.form.page - 1) * this.page_size
      const max_position = parseInt(this.form.page) * parseInt(this.page_size)
      this.current_data_page = [];
      for (let i = offset; i < max_position; i++) {
        if (this.data_list[i]) {
          this.current_data_page.push(this.data_list[i])
        }
      }
    },

    resetSearch (type) {
      if (type === 'reset_load') {
        this.form.page = 1
      }
      this.form.keyword = ''
      this.form.keyword_type = 1
      this.form.category = []
      this.form.store = ''
      this.form.service_block = []
      this.form.service_combo_type = ''
      this.form.service_status = []
    },

    applySearch () {
      this.form.page = 1;
      this.$router.push({
        name: 'service-list',
        query: { ...this.queryParam, ...this.form }
      }).catch(() => {
      });
    },

    async blockService (type, id, block) {
      const blockData = { block: block }
      this.loading = true;
      try {
        if (type === this.service_combo_type[0].id) {
          await ServicesService.blockService(blockData, id)
        } else {
          await ServicesService.blockCombo(blockData, id)
        }
        await this.getData()
        this.loading = false
      } catch (e) {
        this.loading = false
        this.$notify.error({
          title: 'Error',
          message: e.message,
          showClose: false
        });
      }
    },

    async deleteServiceCombo () {
      try {
        this.dialogVisible = false;
        this.loading = true
        await ServicesService.deleteServices(this.checked)
        this.$notify.success({
          title: '成功',
          message: '削除できました。',
          showClose: false
        });
        this.getData()
      } catch (e) {
        this.loading = false
        this.dialogVisible = true;
        this.can_delete = false;
        this.msg = 'このサービスは予約されているので削除できません。';
      }
      this.loading = false;
    },

    openDialog () {
      if (this.checked.length === 0) {
        this.showErrDelete = true
        var self = this;
        setTimeout(function () {
          self.showErrDelete = false;
        }, 2000);
        return true
      }
      this.can_delete = true;
      this.msg = '本当に削除しますか？';
      this.dialogVisible = true;
    },

    createComboService () {
      this.$router.push({ name: 'service-create' })
    },

    formatNumber (number) {
      return new Intl.NumberFormat('ja-JP', { maximumSignificantDigits: 9 }).format(number)
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog__footer{
  text-align: center;
}
::v-deep .el-dialog__body{
  text-align: center;
}
#w-button{
  width: 120px;
  background-color: red;
}
.btn-cobmo-service {
  margin: 10px;
}
.float-r {
  float: right;
}

::v-deep .can-not_delete .el-dialog__body {
  padding: 20px 20px 0px 20px;
}

::v-deep .can-not_delete .el-dialog__footer {
  padding-top: 0px;
}
</style>
