import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class ShoppingService extends BaseService {
  static get entity () {
    return ''
  }

  static async getListShopping (id, params) {
    try {
      const response = await this.request({ auth: true }).get(`shopping/list/${id}?${params}`, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async deleteShopping (listId) {
    try {
      const response = await this.request({ auth: true }).post('shopping/delete', listId)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async createorEditShopping (data, id) {
    try {
      let url = 'shopping/create';
      if (id) {
        url = `shopping/edit/${id}`
      }
      const response = await this.request({ auth: true }).post(url, data, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async showDetailShopping (id) {
    try {
      const response = await this.request({ auth: true }).get(`shopping/show/${id}`, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getDataToExport (params) {
    try {
      const response = await this.request({ auth: true }).get(`shopping/export-csv?${params}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
