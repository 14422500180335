export default {
  M01: '必須項目に入力してください。',
  M02: '空白以外を入力してください。',
  M03: '削除するアカウントを選択してください。',
  M04: '過去の時間は選択できません。',
  M05: '必須項目となります。',
  M06: '終了時間は開始時間より早い。',
  M07: 'ファイルサイズ容量を超えております。',
  M08: '半角の数字、スペース、ダッシュを入力します。',
  M09: '必須項目に入力してくさい',
  M10: '日付が間違っています。',
  M11: 'ログインまたは会員登録してください。',
  M12: '番号を入力してください。',
  M17: 'メール形式を正しく入力してください。',
  M51: '間違っているデータを選択しました。 ご確認してください。',
  M52: '営業時間中に選択してください。'
};
