import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
import { assert } from '@/core'

export class TicketService extends BaseService {
  static get entity () {
    return ''
  }

  static async getListMyTicket (params, customerId) {
    try {
      console.log(params)
      const response = await this.request({ auth: true }).get(`ticket/list/${customerId}?${params}`, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async createTicket (data) {
    assert.object(data, { required: true })
    try {
      const response = await this.request({ auth: true }).post('ticket/create', data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async updateTicket (id, data = {}) {
    assert.id(id, { required: true })
    assert.object(data, { required: true })
    try {
      const response = await this.request({ auth: true }).post(`ticket/edit/${id}`, data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async getTicketById (id) {
    try {
      const response = await this.request({ auth: true }).get(`ticket/show/${id}`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async removeTicket (data) {
    try {
      const response = await this.request({ auth: true }).post('ticket/delete', { ids: data })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }
}
