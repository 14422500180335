import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class ComboService extends BaseService {
  static get entity () {
    return ''
  }

  static async editCombo (form, image, id) {
    try {
      const url = 'combo/' + id

      const formData = new FormData();
      if (form.store_id !== null && form.store_id !== undefined && form.store_id !== 'null') {
        formData.append('store_id', form.store_id);
      } else {
        formData.append('store_id', 0);
      }
      for (let i = 0; i < form.service_id.length; i++) {
        formData.append('service_id[' + i + ']', form.service_id[i]);
      }
      if (form.block !== null && form.block !== undefined) {
        formData.append('block', form.block);
      }
      if (form.status !== null && form.status !== undefined) {
        formData.append('status', form.status);
      }
      if (form.name !== null && form.name !== undefined) {
        formData.append('name', form.name);
      }
      if (form.detail !== null && form.detail !== undefined) {
        formData.append('detail', form.detail);
      }
      if (image !== null && image !== undefined) {
        formData.append('image', image);
      }
      if (form.content !== null && form.content !== undefined) {
        formData.append('content', form.content);
      }
      if (form.display_number !== null && form.display_number !== undefined) {
        formData.append('display_number', form.display_number);
      }
      for (let i = 0; i < form.process.length; i++) {
        let price = form.process[i].price;
        if (price != null && price !== '') {
          price = price.replaceAll(',', '');
          price = parseFloat(price);
        }
        let use_time = form.process[i].use_time;
        if (use_time != null && use_time !== '') {
          use_time = use_time.replaceAll(',', '');
          use_time = parseFloat(use_time);
        }
        formData.append('process[' + i + '][id]', form.process[i].id ? form.process[i].id : '');
        formData.append('process[' + i + '][price]', price);
        formData.append('process[' + i + '][use_time]', use_time);
      }
      const response = await this.request({ auth: true }).post(url, formData, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async deleteCombo (id) {
    try {
      const response = await this.request({ auth: true }).delete('combo/' + id, null, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getComboDetail (id) {
    try {
      const response = await this.request({ auth: true }).get('combo/' + id, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async checkOrder (id) {
    try {
      const response = await this.request({ auth: true }).get('combo/check-order/' + id, null, { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
