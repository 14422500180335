import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class NotificationService extends BaseService {
  static get entity () {
    return ''
  }

  static async getListNotification () {
    try {
      const response = await this.request({ auth: true }).get('notification/list', { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getCountNotification () {
    try {
      const response = await this.request({ auth: true }).get('notification/get-unread-notify', { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getUsers () {
    try {
      const response = await this.request({ auth: true }).get('user', { withCredentials: false })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async editNotificationName (data, id) {
    try {
      const response = await this.request({ auth: true }).patch('notification/edit-name/' + id, data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async editStatusRead (data) {
    try {
      const response = await this.request({ auth: true }).patch('notification/edit-status-readed', data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async editStatusUnread (data) {
    try {
      const response = await this.request({ auth: true }).patch('notification/edit-status-unread', data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
