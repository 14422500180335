<template>
  <md-toolbar md-elevation="0" class="md-blue">
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <Breadcrumb/>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <md-list>
            <li class="md-list-item">
              <div class="md-list-item-content">
                <el-button icon="el-icon-user" type="text">{{$currentUser.name}}</el-button>
                <el-button type="text" @click="$emit('clickLogout')">ログアウト</el-button>
              </div>
            </li>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import Breadcrumb from './Breadcrumb.vue';

export default {
  components: {
    Breadcrumb
  },
  methods: {
    toggleSidebar () {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    }
  }
};
</script>

<style lang="css" scoped>
.md-toolbar.md-blue {
  background-color: white !important;
  border-radius: 0 !important;
  position: fixed;
  right: 0;
  width: inherit;
  z-index: 3;
}
.el-button.el-button--text {
    font-size: 20px !important;
}
</style>
