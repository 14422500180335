import auth from './auth'
import user from './user'
import sidebar from './sideBar'
import dom from './dom'
import toast from './toast'
import breadcrumbs from './breadcrumbs'

export default {
  auth,
  user,
  dom,
  toast,
  breadcrumbs,
  sidebar
}
