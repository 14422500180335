// profile
import profilePage from '../pages/profile/ProfilePage.vue'

// single pages
import loginPage from '../pages/Login.vue';
import notFoundPage from '../pages/NotFound.vue';
import forgetPassword from '../pages/ForgetPassword';
import { DOMAIN_TITLE, PREFIX_URL } from '../.env';
import AppLayout from '@/layout/master/index.vue';
import resetPassword from '../pages/resetPassword/resetPassword';
import listCustomer from '../pages/customer/List'
import listComboService from '../pages/comboService/List';
import Master from '../pages/ticket/myTicket/Master';
import MasterCalendar from '../pages/bookingCalendar/Master';
import CreateComboService from '../pages/comboService/Create';
import EditService from '../pages/comboService/EditService';
import EditCombo from '../pages/comboService/EditCombo';
import ListStaff from '../pages/staff/List';
import policyPage from '../pages/policy/Update.vue';
import EditStaff from '../pages/staff/Edit';
import setBlocked from '../pages/setBlocked/setBlocked';
// import listCampaign from '../pages/campaign/List'
// import createCampaign from '../pages/campaign/Create'
// import bookingCalendar from '../pages/bookingCalendar/List'
import createBooking from '../pages/createBooking/Create'
// import editCampaign from '../pages/campaign/Edit'
import shiftCalendar from '../pages/shift/ShiftCalendar'
import Notification from '../pages/notification/List'
import customerDetail from '../pages/customer/CustomerDetail';
import Review from '../pages/review/List';

export const routes = [
  {
    path: `${PREFIX_URL}/not-found`,
    name: 'index',
    component: notFoundPage,
    meta: {
      meta: { title: `${DOMAIN_TITLE} | エラー` }
    }
  },
  {
    name: 'profile',
    path: `${PREFIX_URL}/profile`,
    component: profilePage,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | プロファイル`,
      role: 'admin'
    }
  },

  {
    path: `${PREFIX_URL}/login`,
    name: 'login',
    component: loginPage,
    meta: { title: `${DOMAIN_TITLE} | ログイン` }
  },
  {
    path: `${PREFIX_URL}/forget-password`,
    name: 'forget-password',
    component: forgetPassword,
    meta: { title: `${DOMAIN_TITLE} | パスワード再設定` }
  },
  {
    path: `${PREFIX_URL}/reset-password`,
    name: 'reset-password',
    component: resetPassword,
    meta: { title: `${DOMAIN_TITLE} | パスワードリセット` }
  },
  {
    path: PREFIX_URL,
    name: 'service-list',
    component: listComboService,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | サービス一覧`
    }
  },
  {
    path: `${PREFIX_URL}/service/create`,
    name: 'service-create',
    component: CreateComboService,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | サービス作成`
    }
  },
  {
    path: `${PREFIX_URL}/service/edit/:serviceId`,
    name: 'service-edit',
    component: EditService,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | サービス編集`
    }
  },
  {
    path: `${PREFIX_URL}/combo/edit/:comboId`,
    name: 'combo-edit',
    component: EditCombo,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | コース編集`
    }
  },
  {
    path: `${PREFIX_URL}/list-customer`,
    name: 'list-customer',
    component: listCustomer,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | 顧客一覧`
    }
  },
  {
    path: `${PREFIX_URL}/staff`,
    name: 'staff-list',
    component: ListStaff,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | スタッフ一覧`,
      isAdmin: true
    }
  },
  {
    path: `${PREFIX_URL}/staff/edit/:staffId`,
    name: 'staff-edit',
    component: EditStaff,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | スタッフ編集`,
      isAdmin: true
    }
  },
  {
    path: `${PREFIX_URL}/ticket`,
    name: 'ticket',
    component: Master,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | チケット`
    }
  },
  {
    path: `${PREFIX_URL}/policy`,
    name: 'policy',
    component: policyPage,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | ポリシー`,
      isAdmin: true
    }
  },
  {
    path: `${PREFIX_URL}/set-blocked`,
    name: 'set-blocked',
    component: setBlocked,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | ブロック設定`
    }
  },
  // {
  //   path: `${PREFIX_URL}/campaign`,
  //   name: 'campaign',
  //   component: listCampaign,
  //   meta: {
  //     isAuth: true,
  //     layout: AppLayout,
  //     title: `${DOMAIN_TITLE} | キャンペーン`
  //   }
  // },

  // {
  //   path: `${PREFIX_URL}/campaign/create`,
  //   name: 'campaign-create',
  //   component: createCampaign,
  //   meta: {
  //     isAuth: true,
  //     layout: AppLayout,
  //     title: `${DOMAIN_TITLE} | キャンペーン作成`
  //   }
  // },
  {
    path: `${PREFIX_URL}/store/:storeId`,
    name: 'store',
    component: MasterCalendar,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | ストアー`
    }
  },
  {
    path: `${PREFIX_URL}/create-booking`,
    name: 'create-booking',
    component: createBooking,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | 予約作成`
    }
  },
  // {
  //   path: `${PREFIX_URL}/campaign/edit/:campaignId`,
  //   name: 'campaign-edit',
  //   component: editCampaign,
  //   meta: {
  //     isAuth: true,
  //     layout: AppLayout,
  //     title: `${DOMAIN_TITLE} | キャンペーン編集`
  //   }
  // },
  {
    path: `${PREFIX_URL}/staff/schedule/:staffId`,
    name: 'shift-calendar',
    component: shiftCalendar,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | シフトカレンダー`,
      isAdmin: true
    }
  },

  {
    path: `${PREFIX_URL}/notification`,
    name: 'notification',
    component: Notification,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | お知らせ`
    }
  },
  {
    path: `${PREFIX_URL}/edit-booking/:id`,
    name: 'edit-booking',
    component: createBooking,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | 予約編集`
    }
  },
  {
    path: `${PREFIX_URL}/customer/:customerId`,
    name: 'customer-detail',
    component: customerDetail,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | 顧客`
    }
  },
  {
    path: `${PREFIX_URL}/review`,
    name: 'review',
    component: Review,
    meta: {
      isAuth: true,
      layout: AppLayout,
      title: `${DOMAIN_TITLE} | レビュー`
    }
  },
  {
    path: '*',
    redirect: `${PREFIX_URL}/not-found`
  }
];
