import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
import { assert } from '@/core'

export class workingtimeService extends BaseService {
  static get entity () {
    return ''
  }

  static async createWorkingTime (data) {
    try {
      assert.object(data, { required: true })
      const response = await this.request({ auth: true }).post('work-time/create', data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async getInfoBlockByDate (data) {
    try {
      assert.object(data, { required: true })
      const response = await this.request({ auth: true }).post('work-time/get-by-date', data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async updateWorkingTime (data) {
    try {
      assert.object(data, { required: true })
      const response = await this.request({ auth: true }).post('work-time/update', data)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }
}
